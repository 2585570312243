import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getCampaigns: ["application_uuid"],
  getCampaignsSuccess: ["items"],
  getCampaignsFailure: ["error"],

  updateCampaign: ["campaign_uuid", "data"],
  updateCampaignSuccess: ["updated"],
  updateCampaignFailure: ["error"],

  createCampaign: ["application_uuid", "data", "redirect"],
  createCampaignSuccess: ["created"],
  createCampaignFailure: ["error"],

  getCampaign: ["campaign_uuid"],
  getCampaignSuccess: ["fetched"],
  getCampaignFailure: ["error"],

  deleteCampaign: ["application_uuid", "campaign_uuid"],
  deleteCampaignSuccess: null,
  deleteCampaignFailure: ["error"],
});

export const CampaignTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  items: [],
  updated: null,
  created: null,
  fetched: null,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CAMPAIGNS]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      items: [],
    };
  },
  [Types.GET_CAMPAIGNS_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      items: action.items,
      error: null,
    };
  },
  [Types.GET_CAMPAIGNS_FAILURE]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      items: [],
    };
  },

  // Update ad mix
  [Types.UPDATE_CAMPAIGN]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      updated: null,
    };
  },
  [Types.UPDATE_CAMPAIGN_SUCCESS]: (state, { updated }) => {
    return {
      ...state,
      loading: false,
      updated,
      error: null,
    };
  },
  [Types.UPDATE_CAMPAIGN_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      updated: null,
    };
  },

  // Create an ad mix
  [Types.CREATE_CAMPAIGN]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      created: null,
    };
  },
  [Types.CREATE_CAMPAIGN_SUCCESS]: (state, { created }) => {
    return {
      ...state,
      loading: false,
      created,
      error: null,
    };
  },
  [Types.CREATE_CAMPAIGN_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      created: null,
    };
  },

  // Fetch ad mix
  [Types.GET_CAMPAIGN]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      fetched: null,
    };
  },
  [Types.GET_CAMPAIGN_SUCCESS]: (state, { fetched }) => {
    return {
      ...state,
      loading: false,
      fetched,
      error: null,
    };
  },
  [Types.GET_CAMPAIGN_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      fetched: null,
    };
  },

  // Delete ad mix
  [Types.DELETE_CAMPAIGN]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [Types.DELETE_CAMPAIGN_SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  },
  [Types.DELETE_CAMPAIGN_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  },
});
