import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SettingsActions from "../../redux/modules/settings";
import GlobalSettings from "components/global-settings/GlobalSettings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GlobalSettingsContainer = ({ actions, settings }) => {
  const [displayStatus, setDisplayStatus] = useState(false);

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    if (displayStatus) {
      if (settings.globalUpdated) {
        toast.success("Successfuly updated.");
        setDisplayStatus(false);
      } else if (settings.error) {
        toast.error("Error occured while update");
        setDisplayStatus(false);
      }
    }
  }, [settings]);

  const onSubmit = (data) => {
    actions.settings.updateGlobalSettings(data);
    setDisplayStatus(true);
  };

  const refreshData = () => {
    actions.settings.fetchGlobalSettings();
  };

  return (
    <>
      <ToastContainer draggable={false} limit={1} />
      {settings.globalFetched ? (
        <GlobalSettings
          formData={settings.globalSettings}
          onSubmit={onSubmit}
          onCancel={refreshData}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = ({ settings }) => ({
  settings,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    settings: bindActionCreators(SettingsActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalSettingsContainer);
