import React, { useCallback, useEffect, useMemo, useState } from "react";

import api from "../../services/api";
import GridContainer from "components/core/Grid/GridContainer";
import GridItem from "components/core/Grid/GridItem";
import Card from "components/core/Card/Card";
import CustomDropdown from "components/core/CustomDropdown/CustomDropdown";
import CustomInput from "components/core/CustomInput/CustomInput";
import CardBody from "components/core/Card/CardBody";
import Pagination from "components/core/Pagination/Pagination";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import { chunkArray } from "helpers/utils";
import { Add, Edit } from "@material-ui/icons";

import Button from "components/core/CustomButtons/Button.js";
import CardHeader from "components/core/Card/CardHeader";
import CustomAccordion from "components/core/Accordion/Accordion";
import AuditLogsTable from "components/auditlogs/AuditLogsTable";

import { Grid, Switch, withStyles } from "@material-ui/core";

import SnackbarContent from "components/core/Snackbar/SnackbarContent";

import { NetworkSettingsModal } from "./NetworkSettingsModal";

import Session from "./../../services/Session";

const NetworkSettingsListContainer = ({ classes }) => {
  const appId = Session.get(Session.SELECTED_APPLICATION_KEY).uuid;
  const refreshItems = () => {
    api.getNetworkSettings(appId).then(setItems).catch(console.error);
  };

  const [items, setItems] = useState([]);
  const [rawSdkNetworks, setRawSdkNetworks] = useState(null);

  useEffect(() => {
    refreshItems();
    api.getSdkNetworks().then(setRawSdkNetworks);
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [chunkSize, setChunkSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusMessage, setStatusMessage] = useState("");

  const sdkNetworks = useMemo(() => {
    return rawSdkNetworks?.reduce((acc, item) => {
      acc[item.uuid] = item;
      return acc;
    }, {});
  }, [rawSdkNetworks]);

  let itemsToRender = useMemo(() => {
    if (!sdkNetworks) return [];
    return [...items].sort((a, b) => {
      const aNetworkName = sdkNetworks[a.networkUuid].name;
      const bNetworkName = sdkNetworks[b.networkUuid].name;
      return aNetworkName > bNetworkName
        ? 1
        : bNetworkName > aNetworkName
        ? -1
        : 0;
    });
  }, [items, sdkNetworks]);

  if (searchValue) {
    itemsToRender = itemsToRender.filter((item) =>
      sdkNetworks[item.networkUuid].name.includes(searchValue)
    );
  }

  const pages = useMemo(() => chunkArray(itemsToRender, chunkSize), [
    itemsToRender,
    chunkSize,
  ]);

  if (pages.length && currentPage > pages.length) {
    setCurrentPage(pages.length);
    return null;
  }

  itemsToRender = pages.length ? pages[currentPage - 1] : [];

  const handleModalClose = () => {
    setIsModalOpen(false);
    setEditItem(null);
  };

  const onEdit = (item) => {
    setEditItem(item);
    setIsModalOpen(true);
  };

  const RenderPlacements = useCallback(() => {
    return itemsToRender.map((item, index) => {
      const network = sdkNetworks[item.networkUuid];
      return (
        <Grid
          container
          direction="row"
          alignItems="center"
          key={`NETWORKSETTING:${index}`}
        >
          <Grid item xs={12} md={10}>
            <span>{network?.name ?? ""}</span>
          </Grid>
          <Grid item xs={12} md={2}>
            <Grid container direction="row" alignItems="flex-end">
              <Grid item xs={12} md={6}>
                <Switch
                  defaultChecked={item.enabled}
                  onChange={(e) => {
                    const { checked } = e.target;
                    api
                      .updateNetworkSettings(appId, item._id, {
                        ...item,
                        enabled: checked,
                      })
                      .then(refreshItems)
                      .catch(console.error);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Button
                  justIcon
                  round
                  color="success"
                  onMouseDown={() => {
                    onEdit(item);
                  }}
                >
                  <Edit />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  }, [itemsToRender, sdkNetworks]);

  const renderStatusMessage = () => {
    if (statusMessage) {
      document
        .querySelector("#dashboardMainPanel")
        .scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => {
        setStatusMessage("");
      }, 5000);
      return (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={6}>
            <SnackbarContent message={statusMessage} color="danger" />
          </Grid>
        </Grid>
      );
    }
  };
  if (!sdkNetworks || !rawSdkNetworks) return <div>Loading...</div>;

  return (
    <GridContainer>
      {renderStatusMessage()}
      <NetworkSettingsModal
        classes={classes}
        item={editItem}
        modalOpen={isModalOpen}
        sdkNetworks={rawSdkNetworks}
        onSubmit={(item) => {
          const payload = {
            ...item,
            applicationId: Session.get(Session.SELECTED_APPLICATION_KEY).uuid,
            params: item.params || {},
          };

          let promise = item._id
            ? api.updateNetworkSettings(appId, item._id, payload)
            : api.createNetworkSettings(appId, payload);

          promise
            .then(() => {
              setStatusMessage(
                item._id
                  ? "Network Setting Updated Successfully"
                  : "Network Setting Successfully created"
              );
              refreshItems();
            })
            .catch((e) => {
              setStatusMessage(e?.response?.data?.error || e.toString());
            });
          handleModalClose();
        }}
        handleModalClose={handleModalClose}
      />
      <GridItem xs={11}>
        <Card>
          <GridContainer>
            <GridItem xs={9}>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>Network Settings</h4>

                <CustomDropdown
                  hoverColor="primary"
                  buttonText={`Show ${chunkSize} entries`}
                  onClick={(selected) => {
                    setChunkSize(+selected);
                  }}
                  buttonProps={{
                    round: true,
                    style: { marginBottom: "0" },
                    color: "primary",
                  }}
                  dropdownList={[10, 25, 50]}
                />
              </CardHeader>
            </GridItem>
            <GridItem xs={3}>
              <CustomInput
                labelText="Search"
                inputProps={{ onChange: (e) => setSearchValue(e.target.value) }}
              />
            </GridItem>
          </GridContainer>
          <CardBody>
            <RenderPlacements />
            <Pagination
              pages={[
                {
                  active: false,
                  disabled: currentPage === 1,
                  text: "PREV",
                  onClick: () => {
                    if (currentPage === 1) return;
                    setCurrentPage((curr) => curr - 1);
                  },
                },
                ...pages.map((_, i) => {
                  const page = i + 1;
                  return {
                    active: page == currentPage,
                    disabled: false,
                    text: page,
                    onClick: () => {
                      setCurrentPage(page);
                    },
                  };
                }),
                {
                  active: false,
                  disabled: currentPage === pages.length,
                  text: "NEXT",
                  onClick: () => {
                    if (currentPage === pages.length) return;
                    setCurrentPage((curr) => curr + 1);
                  },
                },
              ]}
            ></Pagination>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={11}>
        <Grid container direction="column" alignItems="flex-end">
          <Grid item>
            <Button
              onClick={() => {
                onEdit(null);
              }}
              variant="contained"
              startIcon={<Add />}
              color="success"
            >
              Add Network Setting
            </Button>
          </Grid>
        </Grid>
      </GridItem>
      <GridItem xs={11}>
        <Card>
          <CardHeader color="rose" icon>
            <h4 className={classes.cardIconTitle}>Audit Logs</h4>
          </CardHeader>
          <CardBody>
            <CustomAccordion
              collapses={[
                {
                  title: "Show/Hide",
                  content: (
                    <AuditLogsTable
                      filters={{
                        domain: "Admixes",
                        scope: "NetworkSettings",
                      }}
                    />
                  ),
                },
              ]}
            ></CustomAccordion>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(extendedTablesStyle)(NetworkSettingsListContainer);
