import React from "react";
import { NavLink } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import avatar from "assets/img/faces/card-profile1-square.jpg";

const SidebarUser = ({
  userWrapperClass,
  photo,
  classes,
  setOpenAvatar,
  openAvatar,
  identity,
  caret,
  itemText,
  applications,
  selectApplication,
  collapseItemMini,
  collapseItemText,
}) => {
  return (
    <div className={userWrapperClass}>
      <div className={photo}>
        <img src={avatar} className={classes.avatarImg} alt="..." />
      </div>
      <List className={classes.list}>
        <ListItem className={classes.item + " " + classes.userItem}>
          <NavLink
            to={"#"}
            className={classes.itemLink + " " + classes.userCollapseButton}
            onClick={() => setOpenAvatar(!openAvatar)}
          >
            <ListItemText
              primary={
                identity ? identity.first_name + " " + identity.last_name : ""
              }
              secondary={
                <b
                  className={
                    caret +
                    " " +
                    classes.userCaret +
                    " " +
                    (openAvatar ? classes.caretActive : "")
                  }
                />
              }
              disableTypography={true}
              className={itemText + " " + classes.userItemText}
            />
          </NavLink>
          <Collapse in={openAvatar} unmountOnExit>
            <List className={classes.list + " " + classes.collapseList}>
              {applications &&
                applications.map((application) => (
                  <ListItem
                    key={application.uuid}
                    className={classes.collapseItem}
                  >
                    <NavLink
                      to="#"
                      onClick={() =>
                        selectApplication(application.uuid, application.slug)
                      }
                      className={
                        classes.itemLink + " " + classes.userCollapseLinks
                      }
                    >
                      <span className={collapseItemMini}>
                        {application.name.substring(0, 3)}
                      </span>
                      <ListItemText
                        primary={application.name}
                        disableTypography={true}
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                ))}
            </List>
          </Collapse>
        </ListItem>
      </List>
    </div>
  );
};

export default SidebarUser;
