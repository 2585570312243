import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getNetworks: null,
  getNetworksSuccess: ["items"],
  getNetworksFailure: ["error"],

  getNetworkParameters: ["name"],
  getNetworkParametersSuccess: ["params"],
  getNetworkParametersFailure: ["error"],
});

export const NetworkTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  items: [],
  selected: null,
  params: [],
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_NETWORKS]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      items: [],
    };
  },
  [Types.GET_NETWORKS_SUCCESS]: (state, { items }) => {
    return {
      ...state,
      loading: false,
      items: items,
      error: null,
    };
  },
  [Types.GET_NETWORKS_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error: error,
      items: [],
    };
  },

  [Types.GET_NETWORK_PARAMETERS]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      params: [],
    };
  },
  [Types.GET_NETWORK_PARAMETERS_SUCCESS]: (state, { params }) => {
    return {
      ...state,
      loading: false,
      params: params,
      error: null,
    };
  },
  [Types.GET_NETWORK_PARAMETERS_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error: error,
      params: [],
    };
  },
});
