import React, { Component } from "react";

class DashboardNavigationComponent extends Component {
  constructor() {
    super();
  }

  render() {
    const { grouping, changeGrouping } = this.props;
    return (
      <ul className="nav nav-tabs grouping-tabs">
        <li className="nav-item">
          <a
            className={`nav-link ${grouping === "placement" ? "active" : ""}`}
            onClick={() => changeGrouping("placement")}
          >
            By Placement
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${grouping === "admix" ? "active" : ""}`}
            onClick={() => changeGrouping("admix")}
          >
            By Admix
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${grouping === "network" ? "active" : ""}`}
            onClick={() => changeGrouping("network")}
          >
            By Networks
          </a>
        </li>
      </ul>
    );
  }
}

export default DashboardNavigationComponent;
