/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";

// import AuthActions from "redux/modules/auth";
import ApplicationActions from "redux/modules/application";
import AuthActions from "redux/modules/auth";

// core components
import SidebarUser from "./SidebarUser";
import SidebarLinks from "./SidebarLinks";
import SidebarWrapper from "./SidebarWrapper";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";

const useStyles = makeStyles(sidebarStyle);

const Sidebar = ({
  routes,
  identity,
  applications,
  color,
  rtlActive,
  logo,
  image,
  logoText,
  bgColor,
  slug,
  handleDrawerToggle,
  open,
  ...props
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the user collapse
  const [openAvatar, setOpenAvatar] = React.useState(false);
  // this is for the rest of the collapses
  const [state, setState] = React.useState({});
  React.useEffect(() => {
    setState(getCollapseStates(routes));
  }, []);
  const mainPanel = React.useRef();
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes

  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname === routes[i].layout + routes[i].path) {
        return true;
      }
    }
    return false;
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  const selectApplication = (uuid, selectedSlug) => {
    setState({ openAvatar: false });
    const selectApplicationAction = ApplicationActions.selectApplication(uuid);
    dispatch(selectApplicationAction);

    const currentPath = history.location.pathname;
    const newPath = currentPath.replace(slug, selectedSlug);

    if (window.location.href.includes("application/ad-mix")) {
      window.location.href = "/ad-mix-group";
    } else {
      window.location.href = newPath;
    }
  };

  const sortApplications = (applications) => {
    if(applications){
      applications.sort((a, b) => { if (a.name < b.name) { return -1; } })
    }
    return applications;
  };

  const itemText =
    classes.itemText +
    " " +
    cx({
      [classes.itemTextMini]: props.miniActive,
      [classes.itemTextMiniRTL]: rtlActive && props.miniActive,
      [classes.itemTextRTL]: rtlActive,
    });
  const collapseItemText =
    classes.collapseItemText +
    " " +
    cx({
      [classes.collapseItemTextMini]: props.miniActive,
      [classes.collapseItemTextMiniRTL]: rtlActive && props.miniActive,
      [classes.collapseItemTextRTL]: rtlActive,
    });
  const userWrapperClass =
    classes.user +
    " " +
    cx({
      [classes.whiteAfter]: bgColor === "white",
    });
  const caret =
    classes.caret +
    " " +
    cx({
      [classes.caretRTL]: rtlActive,
    });
  const collapseItemMini =
    classes.collapseItemMini +
    " " +
    cx({
      [classes.collapseItemMiniRTL]: rtlActive,
    });
  const photo =
    classes.photo +
    " " +
    cx({
      [classes.photoRTL]: rtlActive,
    });

  var user = (
    <SidebarUser
      userWrapperClass={userWrapperClass}
      photo={photo}
      classes={classes}
      setOpenAvatar={setOpenAvatar}
      openAvatar={openAvatar}
      identity={identity}
      caret={caret}
      itemText={itemText}
      applications={sortApplications(applications)}
      selectApplication={selectApplication}
      collapseItemMini={collapseItemMini}
      collapseItemText={collapseItemText}
    />
  );

  var links = (
    <List className={classes.list}>
      <SidebarLinks
        routes={routes}
        classes={classes}
        color={color}
        rtlActive={rtlActive}
        slug={slug}
        state={state}
        miniActive={props.miniActive}
        getCollapseInitialState={getCollapseInitialState}
        setState={setState}
        activeRoute={activeRoute}
      />
    </List>
  );

  const logoNormal =
    classes.logoNormal +
    " " +
    cx({
      [classes.logoNormalSidebarMini]: props.miniActive,
      [classes.logoNormalSidebarMiniRTL]: rtlActive && props.miniActive,
      [classes.logoNormalRTL]: rtlActive,
    });
  const logoMini =
    classes.logoMini +
    " " +
    cx({
      [classes.logoMiniRTL]: rtlActive,
    });
  const logoClasses =
    classes.logo +
    " " +
    cx({
      [classes.whiteAfter]: bgColor === "white",
    });
  var brand = (
    <div className={logoClasses}>
      <a href="#" className={logoMini}>
        <img src={logo} alt="logo" className={classes.img} />
      </a>
      <a href="#" className={logoNormal}>
        {logoText}
      </a>
    </div>
  );
  const drawerPaper =
    classes.drawerPaper +
    " " +
    cx({
      [classes.drawerPaperMini]: props.miniActive,
      [classes.drawerPaperRTL]: rtlActive,
    });
  const sidebarWrapper =
    classes.sidebarWrapper +
    " " +
    cx({
      [classes.drawerPaperMini]: props.miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });

  return (
    <div ref={mainPanel}>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={rtlActive ? "left" : "right"}
          open={open}
          classes={{
            paper: drawerPaper + " " + classes[bgColor + "Background"],
          }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <SidebarWrapper
            className={sidebarWrapper}
            user={user}
            links={links}
          />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: drawerPaper + " " + classes[bgColor + "Background"],
          }}
        >
          {brand}
          <SidebarWrapper
            className={sidebarWrapper}
            user={user}
            links={links}
          />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.defaultProps = {
  bgColor: "blue",
};

Sidebar.propTypes = {
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
};

export default Sidebar;
