import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdMixActions from "../../redux/modules/admix";
import AdMixNetworkActions from "../../redux/modules/admix/network";
import NetworkActions from "../../redux/modules/network";
import AdMixUpdateGeneralInfo from "../../components/admix/AdMixUpdateGeneralInfo";
import PlacementActions from "../../redux/modules/placementgroups";
const PlacementAdMixUpdateContainer = ({
  match: {
    params: { placement, group, uuid },
  },
  actions,
  admix,
  admix_network,
  network,
  placementgroup,
}) => {
  useEffect(() => {
    actions.admix.getAdMix(uuid);
    actions.network.getNetworks();
    actions.placement.getPlacementGroup(placement);
  }, []);

  const onRefreshItem = () => {
    actions.admix.getAdMix(uuid);
  };

  return admix.fetched ? (
    <AdMixUpdateGeneralInfo
      error={admix.error}
      admix_network_error={admix_network.error}
      ad_mix={admix.fetched}
      ad_mix_updated={admix.updated}
      networks={network.items}
      placement={placement}
      group={group}
      groups={placementgroup.items}
      onRefreshItem={onRefreshItem}
      onSave={(data) => {
        data.uuid = uuid;
        actions.admix.updateAdMix(uuid, data);
      }}
      onAddNetwork={({ name, description, redirect }) => {
        actions.admix_network.addNetwork(uuid, description, name, redirect);
      }}
      onDeleteNetwork={(network_uuid) => {
        actions.admix_network.deleteNetwork(uuid, network_uuid);
      }}
    />
  ) : null;
};

const mapStateToProps = ({
  admix,
  network,
  admix_network,
  placementgroup,
}) => ({
  admix,
  network,
  admix_network,
  placementgroup,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    admix: bindActionCreators(AdMixActions, dispatch),
    admix_network: bindActionCreators(AdMixNetworkActions, dispatch),
    network: bindActionCreators(NetworkActions, dispatch),
    placement: bindActionCreators(PlacementActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlacementAdMixUpdateContainer);
