import React, { useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Assignment from "@material-ui/icons/Assignment";

import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import SelectInput from "components/core/SelectInput/SelectInput";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardHeader from "components/core/Card/CardHeader.js";
import SnackbarContent from "components/core/Snackbar/SnackbarContent.js";

import history from "../../services/history";
import StringControl from "../settings/StringControl";
import IntegerControl from "../settings/IntegerControl";

import { Form, Field } from "react-final-form";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const PlacementGroupCreate = ({
  onSubmit,
  error,
  classes,
  placement,
  groupData,
}) => {
  const onSubmitData = (values) => {
    if (Object.keys(values).length == 0 && groupData != undefined) {
      goBack();
      return;
    }
    if (!values.min) return;
    if (groupData != undefined) {
      values._id = groupData._id;
    }
    setDisplayStatus(true);
    onSubmit(placement, values, {
      url: `/admin/application/placement/${placement}`,
    });
  };

  const [displayStatus, setDisplayStatus] = useState(false);
  const [isFirstTime, setFirstTime] = useState({});
  const extractVersion = (obj) => {
    if (obj == undefined) return "";
    return `${obj.major}.${obj.minor}.${obj.patch}`;
  };

  const goBack = () => {
    history.push(`/admin/application/placement/${placement}`);
  };

  const renderStatusMessage = () => {
    if (displayStatus) {
      if (error) {
        document
          .querySelector("#dashboardMainPanel")
          .scrollTo({ top: 0, behavior: "smooth" });
      }
      if (error) {
        setTimeout(() => {
          setDisplayStatus(false);
        }, 3000);
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={6}>
              <SnackbarContent message={error.getMessage()} color="danger" />
            </Grid>
          </Grid>
        );
      }
    }
  };

  const initialValueMin =
    groupData != undefined && isFirstTime.min == undefined
      ? extractVersion(groupData.min)
      : "0.0.0";
  const initialValueMax =
    groupData != undefined && isFirstTime.max == undefined
      ? extractVersion(groupData.max)
      : undefined;

  return (
    <GridContainer>
      {renderStatusMessage()}
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>New Placement Group</h4>
          </CardHeader>
          <CardBody>
            <Form onSubmit={onSubmitData}>
              {({ handleSubmit }) => (
                <Grid
                  style={{ marginLeft: 20 }}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={2}>
                    <FormLabel>Min version (Inclusive)</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10}>
                    <Field name="min" initialValue={initialValueMin}>
                      {({ input: Input }) => {
                        Input.value =
                          groupData != undefined && isFirstTime.min == undefined
                            ? extractVersion(groupData.min)
                            : Input.value;
                        return (
                          <StringControl
                            value={Input.value}
                            required={true}
                            onChange={(change) => {
                              isFirstTime.min = false;
                              Input.onChange(change);
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <FormLabel>Max version (Exclusive)</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10}>
                    <Field name="max" initialValue={initialValueMax}>
                      {({ input: Input }) => {
                        Input.value =
                          groupData != undefined && isFirstTime.max == undefined
                            ? extractVersion(groupData.max)
                            : Input.value;
                        return (
                          <StringControl
                            value={Input.value}
                            onChange={(change) => {
                              isFirstTime.max = false;
                              Input.onChange(change);
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <FormLabel>Allocate Traffic By:</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10}>
                    <Field
                      id="allocateBy"
                      name="allocateBy"
                      component={SelectInput}
                      defaultValue={
                        (groupData && groupData.allocateBy) || "impressions"
                      }
                    >
                      <MenuItem value="impressions">Impressions</MenuItem>
                      <MenuItem value="requests">Requests</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <FormLabel>Priority</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10}>
                    <Field name="priority">
                      {({ input: Input }) => {
                        Input.value =
                          groupData != undefined &&
                          isFirstTime.priority == undefined
                            ? groupData.priority
                            : Input.value;
                        return (
                          <IntegerControl
                            value={Input.value}
                            onChange={(change) => {
                              isFirstTime.priority = false;
                              Input.onChange(change);
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      onClick={() => goBack()}
                      color="rose"
                      className={classes.marginRight}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      color="primary"
                      className={classes.marginRight}
                    >
                      Save Placement group
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(extendedTablesStyle)(PlacementGroupCreate);
