import history from "../services/history";
import Session from "../services/Session";

const validateUrl = (urlAppSlug, handle) => {
  const filtered = Session.get("applications").filter((app) => {
    return app.slug === urlAppSlug;
  });

  if (filtered.length === 0) {
    history.push("/404");
  } else {
    handle(filtered[0].uuid);
  }
};

export default validateUrl;
