import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";

import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import Modal from "@material-ui/core/Modal";
import { LinearProgress } from "@material-ui/core";

// core components
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardHeader from "components/core/Card/CardHeader.js";
import CustomDropdown from "components/core/CustomDropdown/CustomDropdown.js";
import Dropzone from "react-dropzone";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import StringControl from "../settings/StringControl";

const ALLOWED_FILES = ["png", "jpg", "jpge", "jpeg", "png"];

class SystemMessageForm extends React.Component {
  state = {
    viewTitle: "New Message",
    title: "",
    url: null,
    content: "",
    description: "",
    type: "Image",
    imageName: "",
    imageUrl: null,
    created: null,
    assetKey: null,
    imageLoading: false,
  };
  componentDidUpdate = (prevProps) => {
    if (
      (!prevProps.created && this.props.created) ||
      (!prevProps.updated && this.props.updated)
    ) {
      this.onClose();
    }
    if (!prevProps.fetched && this.props.fetched) {
      this.setState({
        viewTitle: "Update Message",
        title: this.props.fetched.title,
        url: this.props.fetched.url,
        content: this.props.fetched.content,
        description: this.props.fetched.description,
        type: this.props.fetched.type,
        imageName:
          this.props.fetched.type === "image"
            ? this.props.fetched.assets[0].name
            : "",
        imageUrl:
          this.props.fetched.type === "image"
            ? this.props.fetched.assets[0].url
            : "",
        assetKey: null,
      });
      this.props.onFetched(this.props.fetched.uuid);
    }
    if (!prevProps.created && this.props.created) {
      this.props.onFetched(this.props.created.uuid);
    }
    if (prevProps.assetKey != this.props.assetKey && this.state.imageLoading) {
      this.setState({
        imageLoading: false,
        disabledSubmit: false,
      });
    }
  };
  change(event, field) {
    this.setState({ [field]: event.target.value });
  }
  changeValue(value, field) {
    this.setState({ [field]: value });
  }
  cleanForm = (next) => {
    this.setState({}, next);
  };
  onDrop = (files) => {
    this.setState({
      imageLoading: true,
      disabledSubmit: true,
    });
    var file = files[0];
    const type = file.type.split("/");
    const reader = new FileReader();
    if (ALLOWED_FILES.indexOf(type[1]) < 0) {
      this.setState({ isInvalidFile: true });
      return;
    } else {
      reader.onload = (upload) => {
        this.setState(
          {
            imageUrl: upload.target.result,
          },
          () => {
            this.props.onUploadFile(file);
          }
        );
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.readAsDataURL(file);
    }
  };
  onSubmit = () => {
    let msg = {
      content: this.state.content,
      description: this.state.description,
      type: this.state.type.toLowerCase(),
      title: this.state.title,
    };

    if (this.state.url) {
      msg.url = this.state.url;
    }

    if (msg.type === "image") {
      if (this.props.assetKey) {
        msg.assets = [{ key: this.props.assetKey, name: this.state.imageName }];
      } else if (
        this.props.fetched.assets != null &&
        this.props.fetched.assets[0] != null
      ) {
        msg.imageUrl = this.props.fetched.assets[0].url;
      }
    }

    if (!this.props.fetched) {
      this.props.onCreateMessage(msg);
    } else {
      if (this.props.fetched) {
        msg.uuid = this.props.fetched.uuid;
      }
      this.props.onUpdateMessage(msg);
    }
  };
  handleSelectedAdType = (type) => {
    this.setState({
      type,
      disabledSubmit: type.toLowerCase() === "image",
    });
  };
  getErrorFieldMessage = (field) => {
    let errorMessage = null;
    if (this.props.error && this.props.error.hasError(field)) {
      errorMessage = this.props.error.getErrorField(field).message;
    }
    return errorMessage;
  };
  onClose = () => {
    this.cleanForm(this.props.onClose);
  };

  render() {
    const { classes } = this.props;
    // const uploadImage = {
    //   width: 100,
    //   height: 100,
    // };
    const saveText = !this.props.fetched
      ? "SAVE NEW MESSAGE"
      : "UPDATE MESSAGE";
    return (
      <Modal
        className={classes.modalScrolled}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.visible}
        onClose={this.props.onClose}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={8}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {this.state.viewTitle}
                </h4>
              </CardHeader>
              <CardBody>
                <Grid
                  style={{ marginLeft: 20 }}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={3}>
                    <FormLabel>Message type</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <br />
                    <CustomDropdown
                      hoverColor="primary"
                      buttonText={this.state.type}
                      buttonProps={{
                        round: true,
                        style: { marginBottom: "0" },
                        color: "primary",
                      }}
                      dropdownList={["Image", "Html"]}
                      onClick={this.handleSelectedAdType}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <FormLabel>Title</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <StringControl
                      value={this.state.title}
                      onChange={(value) => this.changeValue(value, "title")}
                      errorMessage={this.getErrorFieldMessage("title")}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <FormLabel>Description</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <StringControl
                      value={this.state.description}
                      onChange={(value) =>
                        this.changeValue(value, "description")
                      }
                      errorMessage={this.getErrorFieldMessage("description")}
                    />
                  </Grid>
                  {this.state.type === "Image" ||
                  this.state.type === "image" ? (
                    <React.Fragment>
                      <Grid item xs={12} sm={12} md={3}>
                        <FormLabel>Image</FormLabel>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8}>
                        <div style={{ float: "left" }}>
                          {this.getErrorFieldMessage("asset") ? (
                            <FormLabel
                              style={{ color: "red" }}
                              className={classes.labelHorizontal}
                            >
                              {this.getErrorFieldMessage("asset")}
                            </FormLabel>
                          ) : null}
                        </div>
                        <br />
                        <br />
                        <br />
                        <div className="dropzone">
                          {!this.state.imageUrl ? (
                            <Dropzone
                              style={{
                                width: "100%",
                                border: "1px solid #d5d9e0",
                              }}
                              onDrop={this.onDrop}
                              onFileDialogCancel={() =>
                                this.setState({ files: [] })
                              }
                            >
                              <div style={{ padding: 20, textAlign: "center" }}>
                                <small>Drag file to here</small>
                                <br />
                                <small>or</small>
                                <br />
                                <Button
                                  color="primary"
                                  round
                                  className={classes.marginRight}
                                >
                                  Upload
                                </Button>
                              </div>
                            </Dropzone>
                          ) : (
                            <React.Fragment>
                              <img width={200} src={this.state.imageUrl} />
                              <Button
                                onClick={() => {
                                  this.setState({ imageUrl: null });
                                }}
                                justIcon
                                round
                                color="danger"
                                className={classes.marginRight}
                              >
                                <Close />
                              </Button>
                            </React.Fragment>
                          )}
                          {this.state.imageLoading ? (
                            <LinearProgress
                              variant="indeterminate"
                              color="primary"
                            />
                          ) : null}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <FormLabel>Click through URL</FormLabel>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8}>
                        <StringControl
                          value={this.state.url ? this.state.url : ""}
                          onChange={(value) => this.changeValue(value, "url")}
                          errorMessage={this.getErrorFieldMessage("url")}
                          required={true}
                        />
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid item xs={12} sm={12} md={3}>
                        <FormLabel>Creative content</FormLabel>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8}>
                        <StringControl
                          value={this.state.content ? this.state.content : ""}
                          onChange={(value) =>
                            this.changeValue(value, "content")
                          }
                          errorMessage={this.getErrorFieldMessage("content")}
                          multiline={true}
                          rows={8}
                          required={true}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                  <Grid container direction="column" alignItems="flex-end">
                    <Grid item xs={12} sm={12} md={12}>
                      <Button
                        onClick={this.onClose}
                        color="rose"
                        className={classes.marginRight}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.onSubmit}
                        color="primary"
                        className={classes.marginRight}
                      >
                        {saveText}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

export default withStyles(extendedTablesStyle)(SystemMessageForm);
