import React from "react";
import Datetime from "react-datetime";

export default class DateControl extends React.Component {
  state = {
    value: this.props.value ? new Date(this.props.value) : null,
  };
  getErrorState = () => {
    if (!this.props.errorMessage) {
      return (
        this.props.required === true &&
        (this.props.value === null || this.props.value === "")
      );
    } else {
      return !!this.props.errorMessage;
    }
  };
  getErrorString = () => {
    if (!this.props.errorMessage) {
      return this.props.required === true && this.getErrorState()
        ? "Required *"
        : "";
    } else if (this.props.errorMessage) {
      return this.props.errorMessage;
    }
  };
  handleDateChange = (value) => {
    const valueDate = new Date(value);
    this.setState({ value: valueDate }, () => {
      this.props.onChange(valueDate);
    });
  };
  render() {
    return (
      <React.Fragment>
        {this.getErrorState() ? (
          <small style={{ color: "red" }}>{this.getErrorString()}</small>
        ) : null}
        <Datetime
          timeFormat={this.props.timeFormat ? this.props.timeFormat : null}
          onChange={this.handleDateChange}
          inputProps={{ placeholder: "Date" }}
          value={this.state.value}
        />
      </React.Fragment>
    );
  }
}
