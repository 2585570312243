export default class HttpError extends Error {
  constructor(response) {
    super();
    this.response = response;
    this.message =
      response.data && response.data.message
        ? response.data.message
        : response.message;
    this.stack = new Error(this.message).stack;
    this.name = this.constructor.name;
  }
  getMessage() {
    // TODO - map api errors to custom ones
    return this.message;
  }
  getResponse() {
    return this.response;
  }
  getFields() {
    let fields = {};
    if (this.response.data && this.response.data.details) {
      fields = this.response.data.details;
    }
    return fields;
  }
  getErrorField(field) {
    let message = null;
    if (this.response.data && this.response.data.details) {
      for (let i in this.response.data.details) {
        if (this.response.data.details[i][field]) {
          message = this.response.data.details[i][field];
          break;
        }
      }
    }

    return !message
      ? null
      : {
          field,
          message: message.charAt(0).toUpperCase() + message.slice(1),
        };
  }
  hasError(field) {
    return this.getErrorField(field) !== null;
  }
  hasFields() {
    return this.response.data && this.response.data.details.length > 0;
  }
}
