import { createActions, createReducer } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  appError: ["error"],
  setErrorDisplayed: null,
});

export const ActionTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE = {
  error: null,
  errorDisplayed: false,
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.APP_ERROR]: (state, action) => ({
    ...state,
    error: action.error,
    errorDisplayed: false,
  }),
  [Types.SET_ERROR_DISPLAYED]: (state) => ({
    ...state,
    errorDisplayed: true,
  }),
});
