import React from "react";
import CustomInput from "../core/CustomInput/CustomInput.js";

export default class IntegerControl extends React.Component {
  getErrorState = () => {
    if (!this.props.errorMessage) {
      return (
        this.props.required === true &&
        (this.props.value === null || this.props.value === "")
      );
    } else {
      return !!this.props.errorMessage;
    }
  };
  getErrorString = () => {
    if (!this.props.errorMessage) {
      return this.props.required === true && this.getErrorState()
        ? "Required *"
        : "";
    } else if (this.props.errorMessage) {
      return this.props.errorMessage;
    }
  };
  handleChange = (event) => {
    let castedValue = parseInt(event.target.value);
    castedValue = isNaN(castedValue) ? null : castedValue;
    this.props.onChange(castedValue);
  };
  render() {
    return (
      <CustomInput
        error={this.getErrorState()}
        labelText={this.getErrorString()}
        formControlProps={{ fullWidth: !this.props.disableFullWidth }}
        inputProps={{
          onChange: this.handleChange,
          value: !isNaN(parseInt(this.props.value)) ? this.props.value : null,
          type: "number",
        }}
      />
    );
  }
}
