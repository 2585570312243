import WidgetsIcon from "@material-ui/icons/Widgets";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
import SettingsRemoteSharp from "@material-ui/icons/SettingsRemoteSharp";
import HomeIcon from "@material-ui/icons/Home";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import ArchiveIcon from "@material-ui/icons/Archive";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import TuneIcon from "@material-ui/icons/Tune";
import CampaignCreateContainer from "views/campaign/CampaignCreateContainer";
import CampaignUpdateContainer from "views/campaign/CampaignUpdateContainer";
import CampaignCardListContainer from "views/campaign/CampaignCardListContainer";
import ApplicationSettingsContainer from "views/application/ApplicationSettingsContainer";
import AdMixListContainer from "views/admix/AdMixListContainer";
import AdMixGroupListContainer from "views/admixgroup/AdMixGroupListContainer";
import AdMixCreateContainer from "views/admix/AdMixCreateContainer";
import AdMixNetworkContainer from "views/admix/AdMixNetworkContainer";
import AdMixUpdateGeneralInfoContainer from "views/admix/AdMixUpdateGeneralInfoContainer";
import SettingsContainer from "views/setting/SettingsContainer";
import ResourceNotFoundContainer from "views/error/ResourceNotFoundContainer";
import DashboardReportsListContainer from "views/dashboard/DashboardReportsListContainer";
import PlacementAdMixListContainer from "views/placement/PlacementAdMixListContainer";
import PlacementGroupCreateContainer from "views/placement/PlacementGroupCreateContainer";
import PlacementGroupUpdateContainer from "views/placement/PlacementGroupUpdateContainer";
import PlacementAdMixCreateContainer from "views/placement/PlacementAdMixCreateContainer";
import PlacementAdMixUpdateContainer from "views/placement/PlacementAdMixUpdateContainer";
import LoginContainer from "views/auth/LoginContainer";
import SdkListContainer from "views/sdk/SdkListContainer";
import NetworkSettingsListContainer from "views/network-settings/NetworkSettingsListContainer";
import AppSettingsContainer from "views/application-settings/AppSettingsContainer";
import GlobalSettingsContainer from "views/global-settings/GlobalSettingsContainer";

const dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: HomeIcon,
    mini: "AM",
    component: DashboardReportsListContainer,
    layout: "/admin",
  },
  {
    path: "/a/:slug/campaign/:uuid/details",
    name: "Campaign details",
    icon: SettingsApplications,
    hidden: true,
    mini: "CA",
    component: CampaignUpdateContainer,
    layout: "/admin",
  },
  {
    path: "/a/:slug/campaign/create",
    name: "Create Campaign",
    icon: WidgetsIcon,
    mini: "CC",
    hidden: true,
    component: CampaignCreateContainer,
    layout: "/admin",
  },
  {
    path: "/a/:slug/campaign",
    name: "Campaign List",
    icon: WidgetsIcon,
    mini: "CA",
    component: CampaignCardListContainer,
    hidden: true,
    layout: "/admin",
  },
  {
    path: "/a/:slug/settings",
    name: "Application Settings",
    icon: WidgetsIcon,
    mini: "AL",
    component: ApplicationSettingsContainer,
    hidden: true,
    layout: "/admin",
  },
  {
    path: "/application/ad-mix",
    name: "Ad Mixes",
    icon: SettingsApplications,
    mini: "AM",
    component: AdMixListContainer,
    hidden: true,
    layout: "/admin",
  },
  {
    path: "/application/placement/:placement",
    name: "Ad Mixes",
    icon: SettingsApplications,
    mini: "AM",
    component: PlacementAdMixListContainer,
    hidden: true,
    layout: "/admin",
  },
  {
    path: "/placement/:placement/create",
    name: "New Placement Group",
    hidden: true,
    icon: SettingsApplications,
    mini: "CA",
    component: PlacementGroupCreateContainer,
    hideen: true,
    layout: "/admin",
  },
  {
    path: "/placement/:placement/update/:id",
    name: "New Placement Group",
    hidden: true,
    icon: SettingsApplications,
    mini: "CA",
    component: PlacementGroupUpdateContainer,
    hideen: true,
    layout: "/admin",
  },
  {
    path: "/placement/:placement/:group/ad-mix/create",
    name: "New Ad Mixes",
    hidden: true,
    icon: SettingsApplications,
    mini: "CA",
    component: PlacementAdMixCreateContainer,
    layout: "/admin",
  },
  {
    path: "/placement/:placement/:group/ad-mix/:uuid/details",
    name: "Ad Mix",
    icon: SettingsApplications,
    hidden: true,
    mini: "CA",
    component: PlacementAdMixUpdateContainer,
    layout: "/admin",
  },
  {
    path: "/ad-mix/create",
    name: "New Ad Mixes",
    hidden: true,
    icon: SettingsApplications,
    mini: "CA",
    component: AdMixCreateContainer,
    layout: "/admin",
  },
  {
    path: "/ad-mix/:uuid/details",
    name: "Ad Mix",
    icon: SettingsApplications,
    hidden: true,
    mini: "CA",
    component: AdMixUpdateGeneralInfoContainer,
    layout: "/admin",
  },
  {
    path: "/setting-profile/:uuid/details",
    name: "Profile Settings",
    icon: SettingsApplications,
    hidden: true,
    mini: "CA",
    component: SettingsContainer,
    layout: "/admin",
  },
  {
    path: "/ad-mix-network/:uuid/details",
    name: "Ad Mix Network Settings",
    icon: SettingsApplications,
    hidden: true,
    mini: "CA",
    component: AdMixNetworkContainer,
    layout: "/admin",
  },
  {
    path: "/404",
    name: "Page not found",
    icon: SettingsApplications,
    hidden: true,
    mini: "CA",
    component: ResourceNotFoundContainer,
    layout: "/auth",
  },
  {
    path: "/ad-mix-group",
    name: "Ad Mix Groups",
    icon: SettingsApplications,
    mini: "AM",
    component: AdMixGroupListContainer,
    layout: "/admin",
  },
  {
    path: "/app-settings",
    name: "Application Settings",
    icon: SettingsInputComponentIcon,
    mini: "AS",
    component: AppSettingsContainer,
    layout: "/admin",
  },
  {
    path: "/network-settings",
    name: "Network Config",
    icon: SettingsRemoteSharp,
    mini: "AS",
    component: NetworkSettingsListContainer,
    layout: "/admin",
  },
  {
    path: "/global-settings",
    name: "Global Settings",
    icon: TuneIcon,
    mini: "GS",
    component: GlobalSettingsContainer,
    layout: "/admin",
    borderTop: true,
  },
  {
    path: "/sdk",
    name: "SDK",
    icon: ArchiveIcon,
    component: SdkListContainer,
    layout: "/admin",
  },
  {
    path: "/logout",
    name: "Logout",
    icon: LogoutIcon,
    mini: "AM",
    component: LoginContainer,
    layout: "/auth",
  },
];

export default dashRoutes;
