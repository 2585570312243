import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdMixPlacementsActions from "../../redux/modules/admixplacements";
import AdMixGroupList from "../../components/admixgroup/AdMixGroupList";
import Session from "../../services/Session";

const AdMixGroupListContainer = ({ actions, admixplacements }) => {
  const application = Session.getApplication();

  useEffect(() => {
    actions.placements.getAdMixPlacements();
  }, []);

  return (
    <AdMixGroupList
      items={admixplacements.items}
      application={application}
      onDeletePlacement={actions.placements.deleteAdMixPlacement}
      onSubmitPlacement={actions.placements.changeAdMixPlacement}
      onClonePlacement={actions.placements.cloneAdMixPlacement}
      refreshItems={actions.placements.getAdMixPlacements}
      placementError={admixplacements.error}
    />
  );
};

const mapStateToProps = ({ admixplacements }) => ({
  admixplacements,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    placements: bindActionCreators(AdMixPlacementsActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdMixGroupListContainer);
