import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdMixActions from "../../redux/modules/admix";
import AdMixCreate from "../../components/admix/AdMixCreate";
import Session from "../../services/Session";
import history from "../../services/history";

const AdMixCreateContainer = ({
  location: {
    state: { groupUuid },
  },
  actions,
  admix,
}) => {
  const application = Session.getApplication();

  useEffect(() => {
    // Avoid invalid state if the page was loaded without having selected an ad mix group
    if (!groupUuid) {
      history.push("/admin/application/ad-mix-group");
    }
  }, []);

  return (
    <AdMixCreate
      application={application}
      groupUuid={groupUuid}
      onCreate={actions.admix.createAdMix}
      error={admix.error}
    />
  );
};

const mapStateToProps = ({ admix }) => ({
  admix,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    admix: bindActionCreators(AdMixActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdMixCreateContainer);
