import { takeLatest, put, call } from "redux-saga/effects";
import CampaignActions, { CampaignTypes } from "../modules/campaign";
import history from "../../services/history";

export function* getCampaigns(api, { application_uuid }) {
  try {
    const response = yield call(api.getCampaigns, application_uuid);
    yield put(CampaignActions.getCampaignsSuccess(response.data.campaigns));
  } catch (e) {
    yield put(CampaignActions.getCampaignsFailure(e));
  }
}

export function* updateCampaign(api, { campaign_uuid, data }) {
  try {
    const response = yield call(api.updateCampaign, campaign_uuid, data);
    yield put(CampaignActions.updateCampaignSuccess(response.data.campaign));

    const response2 = yield call(api.getCampaign, campaign_uuid);
    yield put(CampaignActions.getCampaignSuccess(response2.data.campaign));
  } catch (e) {
    yield put(CampaignActions.updateCampaignFailure(e));
  }
}

export function* createCampaign(api, { application_uuid, data, redirect }) {
  try {
    const response = yield call(api.createCampaign, application_uuid, data);
    yield put(CampaignActions.createCampaignSuccess(response.data.campaign));

    if (redirect) {
      const { url } = redirect;
      history.push(url.replace(":uuid", response.data.campaign.uuid));
    }
  } catch (e) {
    yield put(CampaignActions.createCampaignFailure(e));
  }
}

export function* getCampaign(api, { campaign_uuid }) {
  try {
    const response = yield call(api.getCampaign, campaign_uuid);
    yield put(CampaignActions.getCampaignSuccess(response.data.campaign));
  } catch (e) {
    yield put(CampaignActions.getCampaignFailure(e));
  }
}

export function* deleteCampaign(api, { application_uuid, campaign_uuid }) {
  try {
    const response = yield call(api.deleteCampaign, campaign_uuid);
    yield put(CampaignActions.deleteCampaignSuccess(response.data.campaign));

    const response2 = yield call(api.getCampaigns, application_uuid);
    yield put(CampaignActions.getCampaignsSuccess(response2.data.campaigns));
  } catch (e) {
    yield put(CampaignActions.deleteCampaignFailure(e));
  }
}

export function* watchCampaign(api) {
  yield takeLatest(CampaignTypes.GET_CAMPAIGNS, getCampaigns, api);
  yield takeLatest(CampaignTypes.GET_CAMPAIGN, getCampaign, api);
  yield takeLatest(CampaignTypes.CREATE_CAMPAIGN, createCampaign, api);
  yield takeLatest(CampaignTypes.DELETE_CAMPAIGN, deleteCampaign, api);
  yield takeLatest(CampaignTypes.UPDATE_CAMPAIGN, updateCampaign, api);
}
