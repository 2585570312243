import { fork } from "redux-saga/effects";
import { watchAuth } from "./auth";
import { watchApp } from "./app";
import { watchAds } from "./ads";
import { watchMsgs } from "./messages";
import { watchAdMixPlacements } from "./admixplacements";
import { watchApplication } from "./application";
import { watchAdMix } from "./admix";
import { watchAbTest } from "./abtest";
import { watchNetwork } from "./network";
import { watchSetting } from "./setting";
import { watchAdMixGroups } from "./admixgroup";
import { watchPlacementGroup } from "./placementgroups";
import { watchCampaign } from "./campaign";
import { watchAsset } from "./asset";
import { watchSettings } from "./settings";

export default function* root({ api }) {
  yield fork(watchApp, api);
  yield fork(watchAds, api);
  yield fork(watchMsgs, api);
  yield fork(watchAuth, api);
  yield fork(watchApplication, api);
  yield fork(watchAdMix, api);
  yield fork(watchAdMixPlacements, api);
  yield fork(watchAbTest, api);
  yield fork(watchNetwork, api);
  yield fork(watchSetting, api);
  yield fork(watchAdMixGroups, api);
  yield fork(watchPlacementGroup, api);
  yield fork(watchCampaign, api);
  yield fork(watchAsset, api);
  yield fork(watchSettings, api);
}
