import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addNetwork: ["ad_mix_uuid", "name", "ad_provider", "redirect"],
  addNetworkSuccess: null,
  addNetworkFailure: ["error"],

  deleteNetwork: ["ad_mix_uuid", "network_uuid"],
  deleteNetworkSuccess: null,
  deleteNetworkFailure: ["error"],

  getAdMixNetwork: ["ad_mix_network_uuid"],
  getAdMixNetworkSuccess: ["data"],
  getAdMixNetworkFailure: ["ad_error"],

  patchAdMixNetwork: ["ad_mix_network_uuid", "data"],
  patchAdMixNetworkSuccess: ["data"],
  patchAdMixNetworkFailure: ["error"],
});

export const AdMixNetworkTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  created: null,
});

export const reducer = createReducer(INITIAL_STATE, {
  // Add network to ad mix
  [Types.ADD_NETWORK]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [Types.ADD_NETWORK_SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  },
  [Types.ADD_NETWORK_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  },
  // Delete ad mix network
  [Types.DELETE_NETWORK]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [Types.DELETE_NETWORK_SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  },
  [Types.DELETE_NETWORK_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  },
  //Get AdMix Network
  [Types.GET_AD_MIX_NETWORK]: (state) => {
    return {
      ...state,
      loading: true,
      ad_error: null,
      data: [],
    };
  },
  [Types.GET_AD_MIX_NETWORK_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      loading: false,
      data,
    };
  },
  [Types.GET_AD_MIX_NETWORK_FAILURE]: (state, { ad_error }) => {
    return {
      ...state,
      loading: false,
      ad_error: ad_error,
      data: [],
    };
  },
  //PATCH AdMix Network
  [Types.PATCH_AD_MIX_NETWORK]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [Types.PATCH_AD_MIX_NETWORK_SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  },
  [Types.PATCH_AD_MIX_NETWORK_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  },
});
