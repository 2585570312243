import React from "react";

// material-ui icons
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";

// core components
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";

const DialogDeleteModal = ({
  classes,
  dialogModalOpen,
  dialogConfirmAction,
  handleDialogClose,
}) => {
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={dialogModalOpen}
      onClose={handleDialogClose}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Card>
            <CardBody>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <h3>Important</h3>
                </Grid>
                <Grid item xs={12} />
                <Grid item>
                  <h3>Do you want to delete this?</h3>
                </Grid>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Button onClick={dialogConfirmAction} color="primary">
                      Yes
                    </Button>
                    <Button onClick={handleDialogClose} color="rose">
                      No
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default DialogDeleteModal;
