import React, { Component } from "react";
import "../../assets/css/dashboardReport.css";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
  divideTwoNumbers,
  fetchTableColumns,
  sumArrayOfObjectsByKey,
} from "../../helpers/utils";
import { Checkbox, TableFooter } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

class DashboardTableListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      page: 0,
      rowsPerPage: 10,
      sortBy: {
        key: "requests",
        order: "desc",
      },
      selectedRows: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.grouping !== this.props.grouping) {
      this.setState({ selectedRows: [], page: 0 });
    }
  }

  handleChange = (event) => {
    this.setState({ filter: event.target.value });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  /**
   * The following function takes a JS dictionary which has the following structure:
   * [
   *      networkUuid: [
   *          {data}, {data}
   *      ]
   * ],
   *
   * all the networkUuids are unique
   */

  handleItemSelect = (row, grouping) => {
    const selected = this.state.selectedRows;
    let item;
    switch (grouping) {
      case "network":
        item = row["networkUuid"];
        break;
      case "admix":
        item = row["admixId"];
        break;
      default:
        item = row["placement"];
        break;
    }
    if (selected.includes(item)) {
      this.setState({ selectedRows: selected.filter((i) => i !== item) });
    } else {
      selected.push(item);
      this.setState({ selectedRows: selected });
    }
  };

  handleDataSort(keyArg) {
    let key = keyArg;
    let order = "";

    const isNewKey = key !== this.state.sortBy.key;

    const isAlreadyAsc = this.state.sortBy.order === "asc";
    const isAlreadyDesc = this.state.sortBy.order === "desc";

    if (isNewKey) {
      order = "asc";
    } else if (isAlreadyAsc) {
      order = "desc";
    } else if (isAlreadyDesc) {
      key = "";
      order = "";
    }

    this.setState({ sortBy: { key, order } });
  }

  render() {
    const { filter, page, rowsPerPage, sortBy, selectedRows } = this.state;

    const { apiRequest, loading, grouping, onPlotRows } = this.props;
    //Group unique network groups
    let dataTable = apiRequest;

    if (sortBy.key && sortBy.order) {
      dataTable = dataTable.sort((a, b) => {
        const valA = a[sortBy.key];
        const valB = b[sortBy.key];
        const isAsc = sortBy.order === "asc";

        if (isAsc ? valA < valB : valB < valA) return -1;
        if (isAsc ? valA > valB : valB > valA) return 1;
        return 0;
      });
    }

    const lowercasedFilter = filter.toLowerCase();
    const filterField =
      grouping === "network"
        ? "networkName"
        : grouping === "admix"
        ? "admixName"
        : "placement";
    let data = dataTable
      // search only by network name as requested
      .filter((obj) => {
        return obj[filterField].toLowerCase().includes(lowercasedFilter);
      })
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const totalRequests = sumArrayOfObjectsByKey(data, "requests");
    const totalImpressions = sumArrayOfObjectsByKey(data, "impressions");
    const totalClicks = sumArrayOfObjectsByKey(data, "clicks");
    const totalCtr = divideTwoNumbers(totalClicks, totalImpressions) * 100;
    const totalFillRate =
      divideTwoNumbers(totalImpressions, totalRequests) * 100;

    const columns = fetchTableColumns(grouping);

    const isItemSelected = (row) => {
      let item;
      switch (grouping) {
        case "network":
          item = row["networkUuid"];
          break;
        case "admix":
          item = row["admixId"];
          break;
        default:
          item = row["placement"];
          break;
      }
      return selectedRows.includes(item);
    };
    return (
      <div className="tableContainer">
        <div className="d-flex align-items-center justify-content-end">
          <TextField
            style={{
              float: "right",
              background: "#ffffff",
              border: "1px solid #e1e4ed",
              boxSizing: "border-box",
              borderRadius: "18px",
              width: "403px",
              height: "51px",
            }}
            value={filter}
            onChange={this.handleChange}
            placeholder="Search here..."
            InputProps={{
              style: { height: "inherit" },
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ cursor: "pointer" }} />
                </InputAdornment>
              ),
            }}
          />
          <button
            disabled={!selectedRows.length}
            onClick={() => onPlotRows(selectedRows)}
            className="btn btn-danger ml-2"
          >
            Plot Rows
          </button>
        </div>

        <Paper style={{ marginTop: "25px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.key}>
                    <TableSortLabel
                      active={sortBy.key === column.key}
                      direction={sortBy.order || undefined}
                      onClick={() => this.handleDataSort(column.key)}
                    >
                      {column.displayName}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <div
                    className="d-flex align-items-center justify-content-center position-absolute mt-2"
                    style={{ left: "50%" }}
                  >
                    <CircularProgress color="secondary" />
                  </div>
                </TableRow>
              ) : (
                data.map((row, idx) => (
                  <TableRow
                    key={idx}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                    selected={isItemSelected(row, grouping)}
                    onClick={() => this.handleItemSelect(row, grouping)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected(row, grouping)}
                        inputProps={{ "aria-labelledby": true }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {grouping === "network"
                        ? row.networkName
                        : grouping === "admix"
                        ? row.admixName
                        : row.placement}
                    </TableCell>
                    {(grouping === "admix" || grouping === "network") && (
                      <TableCell component="th" scope="row">
                        {row.placement}
                      </TableCell>
                    )}
                    <TableCell>
                      {row.requests !== 0 ? row.requests : "N/A"}
                    </TableCell>
                    <TableCell>{row.impressions}</TableCell>
                    <TableCell>{row.clicks}</TableCell>
                    <TableCell>{row.ctr.toFixed(2) + "%"}</TableCell>
                    <TableCell>
                      {row.fillRate !== 0
                        ? row.fillRate.toFixed(2) + "%"
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
            {!loading && (
              <TableFooter>
                <TableRow>
                  <TableCell>Totals:</TableCell>
                  {grouping === "placement" ? (
                    <TableCell></TableCell>
                  ) : (
                    <>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </>
                  )}
                  <TableCell>{totalRequests}</TableCell>
                  <TableCell>{totalImpressions}</TableCell>
                  <TableCell>{totalClicks}</TableCell>
                  <TableCell>{totalCtr.toFixed(2) + "%"}</TableCell>
                  <TableCell>
                    {totalFillRate !== 0
                      ? totalFillRate.toFixed(2) + "%"
                      : "N/A"}
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dataTable.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ "aria-label": "Previous Page" }}
            nextIconButtonProps={{ "aria-label": "Next Page" }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default DashboardTableListComponent;
