import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  uploadFile: ["file"],
  uploadFileSuccess: ["key", "filename", "filetype"],
  uploadFileFailure: ["error"],
});

export const AssetTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  url: null,
  key: null,
  filename: null,
  filetype: null,
});

export const reducer = createReducer(INITIAL_STATE, {
  // Update application settings
  [Types.UPLOAD_FILE]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [Types.UPLOAD_FILE_SUCCESS]: (state, { key, filename, filetype }) => {
    return {
      ...state,
      loading: false,
      key,
      filename,
      filetype,
    };
  },
  [Types.UPLOAD_FILE_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error: error,
    };
  },
});
