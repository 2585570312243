import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdMixNetworkActions from "../../redux/modules/admix/network";
import AdMixNetworkEdit from "../../components/settings/AdMixNetworkEdit";
import history from "../../services/history";

const AdMixNetworkContainer = ({
  match: {
    params: { uuid },
  },
  admix_network,
  actions,
  history: { goBack },
}) => {
  useEffect(() => {
    actions.admix_network.getAdMixNetwork(uuid);
  }, []);

  const onSave = (data) => {
    actions.admix_network.patchAdMixNetwork(uuid, data);
  };

  return (
    <AdMixNetworkEdit
      data={admix_network.data}
      error={admix_network.error}
      onSave={onSave}
      onBack={goBack}
    />
  );
};

const mapStateToProps = ({ admix_network }) => ({
  admix_network,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    admix_network: bindActionCreators(AdMixNetworkActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdMixNetworkContainer);
