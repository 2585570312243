import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdMixActions from "../../redux/modules/admix";
import AdMixList from "../../components/admix/AdMixList";
import Session from "../../services/Session";

const AdMixListContainer = ({ admix, actions }) => {
  const application = Session.getApplication();
  const groupUuid = Session.get(Session.SELECTED_GROUP_UUID);

  useEffect(() => {
    actions.admix.getAdMixes(groupUuid);
  }, []);

  const refreshItems = () => {
    actions.admix.getAdMixes(groupUuid);
  };

  return (
    <AdMixList
      items={admix.items}
      application={application}
      groupUuid={groupUuid}
      onUpdateAdMix={actions.admix.updateAdMix}
      onDeleteAdMix={actions.admix.deleteAdMix}
      onCloneAdMix={actions.admix.cloneAdMix}
      onRefresh={refreshItems}
    />
  );
};

const mapStateToProps = ({ admix }) => ({
  admix,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    admix: bindActionCreators(AdMixActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdMixListContainer);
