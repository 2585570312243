import React, { useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardHeader from "components/core/Card/CardHeader.js";
import SnackbarContent from "components/core/Snackbar/SnackbarContent.js";
import Chip from "@material-ui/core/Chip";
import { NavLink } from "react-router-dom";
import history from "../../services/history";

import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Assignment from "@material-ui/icons/Assignment";
import Add from "@material-ui/icons/Add";
import IntegerControl from "../settings/IntegerControl";

import AdMixSortableItem from "../placements/AdMixSortableItem";

// core components
import CardIcon from "components/core/Card/CardIcon.js";
import Button from "components/core/CustomButtons/Button.js";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import DialogDeleteModal from "components/core/DialogDeleteModal/DialogDeleteModal";

const StyledChip = withStyles({
  root: {
    backgroundColor: "#2196f3", // here you can do anything actually
  },
  label: {
    // textTransform: "capitalize",
    fontWeight: "bold",
  },
})(Chip);

const PlacementAdMixesList = ({
  classes,
  items,
  application,
  placementName,
  onDeleteAppVersion,
  onDeleteAdMix,
  onSubmitAdmix,
  placementError,
  onCloneAdMix,
  onUpdateAppVersion,
}) => {
  const [displayStatus, setDisplayStatus] = useState(false);
  const [changes, setChanges] = useState({});
  const [groupError, setGroupError] = useState({});
  const [dialogModalOpen, setDialogModalOpen] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [admixUuid, setAdmixUuid] = useState("");

  const onCreateClick = () => {
    history.push(`/admin/placement/${placementName}/create`);
  };

  const onEditAppVersionGroup = (groupId) => {
    history.push(`/admin/placement/${placementName}/update/${groupId}`);
  };

  const onCreateAdMix = (groupId) => {
    history.push(`/admin/placement/${placementName}/${groupId}/ad-mix/create`);
  };

  const onEditAdMix = (groupId, admixUuid) => {
    history.push(
      `/admin/placement/${placementName}/${groupId}/ad-mix/${admixUuid}/details`
    );
  };

  const deleteAdMix = (admixUuid) => {
    setDialogModalOpen(true);
    setAdmixUuid(admixUuid);
  };

  const deleteAppVersion = (groupId) => {
    setDialogModalOpen(true);
    setGroupId(groupId);
  };

  const handleDialogClose = () => {
    setDialogModalOpen(false);
    setGroupId("");
    setAdmixUuid("");
  };

  const onSaveChanges = (group) => {
    let groupChange = changes[group._id];
    if (groupChange != undefined) {
      if (
        Object.keys(groupChange.admixes).length > 0 &&
        group.admixes.length > 0
      ) {
        let sumEnabled = 0;
        const sum = group.admixes.reduce((partialSum, a) => {
          let currentItem = groupChange.admixes[a.uuid];
          let isEnabled =
            currentItem == undefined || currentItem.enabled == undefined
              ? a.enabled
              : currentItem.enabled;
          if (isEnabled) {
            sumEnabled++;
          }

          let percentage = isEnabled
            ? currentItem == undefined || currentItem.percentage == undefined
              ? a.percentage
              : currentItem.percentage
            : 0;
          return partialSum + percentage;
        }, 0);

        if (sumEnabled != 0 && (sum < 100 || sum > 100)) {
          let errorObj = { ...groupError };
          let word = sum < 100 ? "less" : "more";
          errorObj[group._id] = `Sum of percentages is ${word} than 100`;
          setGroupError(errorObj);
          return;
        }
      }
    }
    onUpdateAppVersion(groupChange);
  };

  const renderStatusMessage = () => {
    if (displayStatus) {
      if (placementError) {
        document
          .querySelector("#dashboardMainPanel")
          .scrollTo({ top: 0, behavior: "smooth" });
      }
      if (placementError) {
        setTimeout(() => {
          setDisplayStatus(false);
        }, 1500);
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={6}>
              <SnackbarContent
                message={placementError.getMessage()}
                color="danger"
              />
            </Grid>
          </Grid>
        );
      }
    }
  };

  const renderGroupError = (groupId) => {
    if (groupError[groupId]) {
      setTimeout(() => {
        let groupErr = { ...groupError };
        groupErr[groupId] = null;
        setGroupError(groupErr);
      }, 4000);
      return (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={6}>
            <SnackbarContent message={groupError[groupId]} color="danger" />
          </Grid>
        </Grid>
      );
    }
  };

  const constructVersion = (item) => {
    let name = "";
    if (item.min || item.max) name += ` Group: `;
    if (item.min != null) {
      name += `${item.min.major}.${item.min.minor}.${item.min.patch}`;
    }
    if (item.max != null) {
      name += `-${item.max.major}.${item.max.minor}.${item.max.patch}`;
    }
    return name;
  };

  const checkChanges = (_id, uuid) => {
    let field = { ...changes };
    if (field[_id] == undefined) {
      field[_id] = {
        _id: _id,
        admixes: {},
      };
    }

    if (field[_id].admixes[uuid] == undefined) {
      field[_id].admixes[uuid] = {};
    }
    return field;
  };

  const getPriority = (group) => {
    if (
      changes[group._id] == undefined ||
      changes[group._id].priority == undefined
    ) {
      return group.priority;
    }
    return changes[group._id].priority;
  };

  return (
    <GridContainer>
      <DialogDeleteModal
        classes={classes}
        dialogModalOpen={dialogModalOpen}
        dialogConfirmAction={() => {
          if (admixUuid) {
            onDeleteAdMix(admixUuid);
          }
          if (groupId) {
            onDeleteAppVersion(groupId);
          }
          handleDialogClose();
        }}
        handleDialogClose={handleDialogClose}
      />
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <b>{placementName}</b> placement
            </h4>
          </CardHeader>
          <CardBody>
            {items == null || items.length == 0
              ? null
              : items
                  .sort((left, right) => {
                    if (left.priority < right.priority) return -1;
                    if (left.priority > right.priority) return 1;
                    return 0;
                  })
                  .map((item) => {
                    return (
                      <GridItem
                        xs={12}
                        key={`PlacementGroup-${item.priority}-${item._id}`}
                      >
                        <Card>
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            key={`AdMixPlacement:${item}`}
                          >
                            <Grid item xs={12} md={5}>
                              <div>
                                <StyledChip
                                  label={
                                    <div>
                                      Priority:
                                      <IntegerControl
                                        disableFullWidth={true}
                                        value={getPriority(item)}
                                        onChange={(change) => {
                                          if (change < 0) {
                                            return;
                                          }
                                          let field = { ...changes };
                                          if (field[item._id] == undefined) {
                                            field[item._id] = {
                                              _id: item._id,
                                              admixes: {},
                                            };
                                          }
                                          field[item._id].priority = change;
                                          setChanges(field);
                                        }}
                                      />
                                      {constructVersion(item)}
                                    </div>
                                  }
                                  color="primary"
                                />
                              </div>
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <Chip
                                variant="outlined"
                                label={`Traffic allocated by: ${
                                  item.allocateBy || "impressions"
                                }`}
                              />
                            </Grid>
                            <Grid item xs={12} md={5}>
                              <Grid
                                container
                                direction="row"
                                alignItems="flex-end"
                              >
                                <Grid item xs={12} md={4}>
                                  {item.admixes.length > 0 ? (
                                    <Button
                                      onClick={() => onSaveChanges(item)}
                                      variant="contained"
                                      startIcon={<Add />}
                                      color="warning"
                                    >
                                      Save Changes
                                    </Button>
                                  ) : null}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                  <Button
                                    onClick={() => onCreateAdMix(item._id)}
                                    variant="contained"
                                    startIcon={<Add />}
                                    color="success"
                                  >
                                    Add Admix
                                  </Button>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <Button
                                    justIcon
                                    round
                                    color="success"
                                    onMouseDown={() =>
                                      onEditAppVersionGroup(item._id)
                                    }
                                  >
                                    <Edit />
                                  </Button>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <Button
                                    justIcon
                                    round
                                    color="danger"
                                    onMouseDown={() => {
                                      deleteAppVersion(item._id);
                                    }}
                                  >
                                    <Close />
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <CardBody>
                            {renderGroupError(item._id)}
                            <ul>
                              {item.admixes.map((value, index) => (
                                <AdMixSortableItem
                                  key={`SortableItem-${index}`}
                                  index={index}
                                  value={value}
                                  onEdit={(admixUuid) =>
                                    onEditAdMix(item._id, admixUuid)
                                  }
                                  onChangeActive={(uuid, isActive) => {
                                    let field = checkChanges(item._id, uuid);
                                    field[item._id].admixes[uuid] = {
                                      ...field[item._id].admixes[uuid],
                                      enabled: isActive,
                                    };
                                    setChanges(field);
                                  }}
                                  onChangePercentage={(uuid, percentage) => {
                                    let field = checkChanges(item._id, uuid);
                                    field[item._id].admixes[uuid] = {
                                      ...field[item._id].admixes[uuid],
                                      percentage: percentage,
                                    };
                                    setChanges(field);
                                  }}
                                  onClone={onCloneAdMix}
                                  onDelete={(uuid) => {
                                    deleteAdMix(uuid);
                                  }}
                                />
                              ))}
                            </ul>
                          </CardBody>
                        </Card>
                      </GridItem>
                    );
                  })}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Grid container direction="column" alignItems="flex-end">
          <Grid item>
            <Button
              onClick={onCreateClick}
              variant="contained"
              startIcon={<Add />}
              color="success"
            >
              Add Version Group
            </Button>
          </Grid>
        </Grid>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(extendedTablesStyle)(PlacementAdMixesList);
