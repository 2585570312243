import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// material ui icons
import Add from "@material-ui/icons/Add";
import Check from "@material-ui/icons/Check";
import Grid from "@material-ui/core/Grid";
// core components
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardHeader from "components/core/Card/CardHeader.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardBody from "components/core/Card/CardBody.js";
import AdList from "../Ads/AdList";
import AdForm from "./AdForm";
import DateControl from "../settings/DateControl";
import history from "../../services/history.js";
import StringControl from "../settings/StringControl";
import IntegerControl from "../settings/IntegerControl";
import Session from "./../../services/Session";
import validationFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import SliderBar from "components/common/SliderBar";

const hasGenderFromProps = (gender, props, defaultValue = true) => {
  if (props.campaign.demographics.length == 0) {
    return defaultValue;
  }
  return props.campaign.demographics[0].gender.includes(gender);
};

const getAgeFromProps = (field, props, defaultValue) => {
  if (props.campaign.demographics.length == 0) {
    return defaultValue;
  }
  let minAge = props.campaign.demographics[0][field];
  return minAge !== null ? minAge : defaultValue;
};

const getParsedTimeFromProps = (field, props, defaultValue) => {
  if (props.campaign.schedules.length == 0) {
    return defaultValue;
  }
  let hour = parseInt(props.campaign.schedules[0][field].substring(0, 2));
  return hour;
};

const getWeekdayFromProps = (day, props, defaultValue) => {
  if (props.campaign.schedules.length == 0) {
    return defaultValue;
  }
  return props.campaign.schedules[0].weekday.includes(day);
};

const formatEndTime = (value) => {
  var newVal = null;
  if (value < 12 && value > 0) {
    newVal = Math.floor(value) + ":00 AM";
  } else if (value > 12 && value !== 24) {
    newVal = Math.floor(value - 12) + ":00 PM";
  } else if (value == 0) {
    newVal = "12:00 AM";
  } else if (value == 12) {
    newVal = "12:00 PM";
  } else if (value == 24) {
    newVal = "12:00 AM";
  }
  return newVal;
};

const formatStartTime = (value) => {
  return Math.ceil(value);
};

const getAgeLimitCheck = (props) => {
  if (props.campaign.demographics.length == 0) {
    return 0;
  }
  return (
    props.campaign.demographics[0].min_age !== null &&
    props.campaign.demographics[0].max_age !== null
  );
};

const getUnknownAgeCheck = (props) => {
  if (props.campaign.demographics.length == 0) {
    return 0;
  }

  return props.campaign.demographics[0].include_unknown_ages;
};

const getAllDayCheckFromPropps = (props) => {
  return (
    props.campaign.schedules.length > 0 &&
    props.campaign.schedules[0].weekday.length === 7
  );
};

class CampaignUpdateComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.campaign.name,
      description: this.props.campaign.description,
      start_dt: this.props.campaign.start_dt,
      end_dt: this.props.campaign.end_dt,
      showAdModal: false,
      adToEdit: null,
      maleChecked: hasGenderFromProps("male", this.props),
      femaleChecked: hasGenderFromProps("female", this.props),
      unknownChecked: hasGenderFromProps("unknown", this.props),
      allAgesCheck:
        getAgeFromProps("min_age", this.props, "") === "" &&
        getAgeFromProps("max_age", this.props, "") === "" &&
        getUnknownAgeCheck(this.props),
      setAgeLimits: getAgeLimitCheck(this.props),
      unknownAges: getUnknownAgeCheck(this.props),
      minAge: getAgeFromProps("min_age", this.props, ""),
      maxAge: getAgeFromProps("max_age", this.props, ""),
      startTime: getParsedTimeFromProps("start_time", this.props, 0),
      endTime: getParsedTimeFromProps("end_time", this.props, 24),
      allDaysCheck: getAllDayCheckFromPropps(this.props),
      startTimeFormatted: null,
      endTimeFormatted: null,
      days: {
        1: getWeekdayFromProps(1, this.props, false),
        2: getWeekdayFromProps(2, this.props, false),
        3: getWeekdayFromProps(3, this.props, false),
        4: getWeekdayFromProps(4, this.props, false),
        5: getWeekdayFromProps(5, this.props, false),
        6: getWeekdayFromProps(6, this.props, false),
        7: getWeekdayFromProps(7, this.props, false),
      },
      isStartAm: false,
      allTimeChecked:
        getParsedTimeFromProps("start_time", this.props, 0) === 0 &&
        getParsedTimeFromProps("end_time", this.props, 0) === 24,
      selectedApplication: Session.get(Session.SELECTED_APPLICATION_KEY),
    };
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.adCreated && this.props.adCreated) {
      this.setState({ showAdModal: false, fetched: null }, () => {
        this.props.onFetchAds(this.props.campaign.uuid);
      });
    }
    if (!prevProps.adDeleted && this.props.adDeleted) {
      this.props.onFetchAds(this.props.campaign.uuid);
    }
    if (!prevProps.adFetched && this.props.adFetched) {
      this.setState(
        { showAdModal: true, adToEdit: this.props.adFetched },
        () => {
          this.props.onFetchAds(this.props.campaign.uuid);
        }
      );
    }
    if (!prevProps.adUpdated && this.props.adUpdated) {
      this.props.onFetchAds(this.props.campaign.uuid);
    }
  };

  handleOnEndSlider = (formatedValues) => {
    this.setState({
      startTime: this.getHoursIn24HFormat("start_time", formatedValues[0]),
      endTime: this.getHoursIn24HFormat("end_time", formatedValues[1]),
    });
  };

  getHoursIn24HFormat = (field, time) => {
    const PM = time.indexOf("PM") > 0;
    let hour = parseInt(time.substring(0, 2), 10);
    if (field === "start_time") {
      if (PM) {
        if (hour < 12) {
          hour += 12;
        }
      } else {
        if (hour == 12) {
          hour = 0;
        }
      }
    } else {
      if (PM) {
        if (hour < 12) {
          hour += 12;
        }
      } else {
        if (hour === 12) {
          hour += 12;
        }
      }
    }
    return hour;
  };

  changeValue = (value, field) => {
    this.setState({ [field]: value });
  };

  getErrorFieldMessage = (field) => {
    let errorMessage = null;
    if (this.props.error && this.props.error.hasError(field)) {
      errorMessage = this.props.error.getErrorField(field).message;
    }
    return errorMessage;
  };

  saveChanges = () => {
    let weekday = [];
    for (let day in this.state.days) {
      if (this.state.days[day] === true) {
        weekday.push(parseInt(day));
      }
    }

    if (this.state.allDaysCheck) {
      weekday = [1, 2, 3, 4, 5, 6, 7];
    }

    let gender = [];
    if (this.state.maleChecked) {
      gender.push("male");
    }
    if (this.state.femaleChecked) {
      gender.push("female");
    }
    if (this.state.unknownChecked) {
      gender.push("unknown");
    }

    let startDt =
      this.state.startTime < 10
        ? "0" + this.state.startTime + ":00:00"
        : this.state.startTime + ":00:00";
    let endDt =
      this.state.endTime < 10
        ? "0" + this.state.endTime + ":00:00"
        : this.state.endTime + ":00:00";

    if (this.state.allTimeChecked) {
      startDt = "00:00:00";
      endDt = "24:00:00";
    }

    let includeUnkownAges = 0;

    if (this.state.allAgesCheck || this.state.unknownAges) {
      includeUnkownAges = 1;
    }

    this.props.onUpdateCampaign(this.props.campaign.uuid, {
      name: this.state.name,
      description: this.state.description,
      start_dt: this.state.start_dt,
      end_dt: this.state.end_dt,
      demographic: {
        min_age: this.state.allAgesCheck ? null : this.state.minAge,
        max_age: this.state.allAgesCheck ? null : this.state.maxAge,
        include_unknown_ages: includeUnkownAges,
        gender,
      },
      schedule: {
        weekday,
        start_time: startDt,
        end_time: endDt,
      },
    });
  };

  renderScheduleSlider = (slider) => {
    const { classes } = this.props;
    const sliderStyle = {
      marginTop: 50,
    };
    const timeFrameError = this.getErrorFieldMessage("start_time");

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={2}>
          <FormLabel
            style={{ fontWeight: "bold" }}
            className={classes.labelHorizontal}
          >
            {slider.label}
          </FormLabel>
        </GridItem>
        <GridItem xs={12} sm={12} md={10}>
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={4} style={{ marginTop: 15 }}>
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.allTimeChecked}
                      tabIndex={-1}
                      onClick={() =>
                        this.setState({
                          allTimeChecked: !this.state.allTimeChecked,
                        })
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label="All Day"
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <GridContainer
                style={{
                  display: this.state.allTimeChecked ? "none" : "block",
                }}
              >
                {timeFrameError ? (
                  <React.Fragment>
                    <GridItem xs={12} sm={12} md={12}>
                      <label style={{ color: "red" }}>{timeFrameError}</label>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{ color: "red" }}>
                      <label style={{ color: "red" }}>
                        {this.getErrorFieldMessage("end_time")}
                      </label>
                    </GridItem>
                  </React.Fragment>
                ) : null}
                <GridItem xs={12} sm={12} md={12} style={sliderStyle}>
                  <SliderBar
                    start={[this.state.startTime, this.state.endTime]}
                    formatTo={formatEndTime}
                    formatFrom={formatStartTime}
                    onEndHandler={this.handleOnEndSlider}
                  />
                </GridItem>
              </GridContainer>
            </Grid>
          </Grid>
        </GridItem>
      </GridContainer>
    );
  };

  handleCheckGenders = (genderCheck, value) => {
    const state = { ...this.state };
    state[genderCheck] = value;

    if (!state.femaleChecked && !state.maleChecked && !state.unknownChecked) {
      state.unknownChecked = true;
    }

    this.setState(state);
  };

  renderAdvancedOptions = () => {
    const { classes } = this.props;

    return (
      <GridContainer style={{ marginBottom: 10 }}>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2}>
              <FormLabel
                style={{ fontWeight: "bold", float: "right" }}
                className={classes.labelHorizontal}
              >
                Gender
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={12} md={10} style={{ marginTop: 15 }}>
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.maleChecked}
                      tabIndex={-1}
                      onClick={() =>
                        this.handleCheckGenders(
                          "maleChecked",
                          !this.state.maleChecked
                        )
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label="Male"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checked={this.state.femaleChecked}
                      onClick={() =>
                        this.handleCheckGenders(
                          "femaleChecked",
                          !this.state.femaleChecked
                        )
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label="Female"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      checked={this.state.unknownChecked}
                      onClick={() =>
                        this.handleCheckGenders(
                          "unknownChecked",
                          !this.state.unknownChecked
                        )
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label="Unknown"
                />
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={2}>
              <FormLabel
                style={{ fontWeight: "bold", float: "right" }}
                className={classes.labelHorizontal}
              >
                Age
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={12} md={10} style={{ marginTop: 15 }}>
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.allAgesCheck}
                      tabIndex={-1}
                      onClick={() =>
                        this.setState({
                          allAgesCheck: !this.state.allAgesCheck,
                        })
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label="All Ages"
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}></GridItem>
            <GridItem xs={12} sm={12} md={10}>
              {!this.state.allAgesCheck ? (
                <Grid
                  container
                  direction="row"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid item xs={12} sm={12} md={5}>
                    <IntegerControl
                      value={this.state.minAge}
                      onChange={(value) => this.changeValue(value, "minAge")}
                      errorMessage={this.getErrorFieldMessage("min_age")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    style={{ textAlign: "center" }}
                  >
                    To
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <IntegerControl
                      value={this.state.maxAge}
                      onChange={(value) => this.changeValue(value, "maxAge")}
                      errorMessage={this.getErrorFieldMessage("max_age")}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </GridItem>
            <GridItem xs={12} sm={12} md={2}></GridItem>
            <GridItem xs={12} sm={12} md={10}>
              {!this.state.allAgesCheck ? (
                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.unknownAges}
                        tabIndex={-1}
                        onClick={() =>
                          this.setState({
                            unknownAges: !this.state.unknownAges,
                          })
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label="Include unknown ages"
                  />
                </div>
              ) : null}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  renderPlacements = () => {
    return null;
  };

  handleCloseAdModal = () => {
    this.setState({ showAdModal: false, adToEdit: null });
  };

  render() {
    const {
      classes,
      onUploadFile,
      onCreateAd,
      onUpdateAd,
      uploadedKey,
      adError,
      adCreated,
      adUpdated,
      ads,
      onDeleteAd,
      onFetchAd,
    } = this.props;
    const {
      showAdModal,
      adToEdit,
      name,
      description,
      start_dt,
      end_dt,
      allDaysCheck,
      days,
      selectedApplication,
    } = this.state;

    return (
      <React.Fragment>
        <AdForm
          visible={showAdModal}
          onClose={this.handleCloseAdModal}
          onUploadFile={onUploadFile}
          onCreateAd={onCreateAd}
          onUpdateAd={onUpdateAd}
          assetKey={uploadedKey}
          error={adError}
          created={adCreated}
          updated={adUpdated}
          fetched={adToEdit}
        />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <CardIcon color="default">
                  <div style={{ color: "white" }}>Campaign Background</div>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={4}>
                        <h3>General Info</h3>
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormLabel
                        style={{ fontWeight: "bold" }}
                        className={classes.labelHorizontal}
                      >
                        Name
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                      <StringControl
                        value={name}
                        onChange={(value) => this.changeValue(value, "name")}
                        errorMessage={this.getErrorFieldMessage("name")}
                        required={true}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormLabel
                        style={{ fontWeight: "bold" }}
                        className={classes.labelHorizontal}
                      >
                        Description
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                      <StringControl
                        value={description}
                        onChange={(value) =>
                          this.changeValue(value, "description")
                        }
                        errorMessage={this.getErrorFieldMessage("description")}
                        required={true}
                      />
                    </GridItem>
                  </GridContainer>
                  {this.renderPlacements()}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={4}>
                        <h3>Ad Units</h3>
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}></GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                      <AdList
                        items={ads}
                        onDelete={onDeleteAd}
                        onEdit={onFetchAd}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}></GridItem>
                    <GridItem xs={1} sm={1} md={10}>
                      <Button
                        style={{ float: "right" }}
                        onClick={() => {
                          this.setState({ showAdModal: true });
                        }}
                        justIcon
                        round
                        color="success"
                        className={classes.marginRight}
                      >
                        <Add />
                      </Button>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={4}>
                        <h3>Campaign Targeting</h3>
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                  {this.renderAdvancedOptions()}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridItem xs={12} sm={12} md={4}>
                        <h3>Scheduling setup</h3>
                      </GridItem>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormLabel
                        style={{ fontWeight: "bold" }}
                        className={classes.labelHorizontal}
                      >
                        Start date
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                      <br />
                      <DateControl
                        timeFormat={true}
                        value={start_dt}
                        onChange={(value) =>
                          this.changeValue(value, "start_dt")
                        }
                        errorMessage={this.getErrorFieldMessage("start_dt")}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormLabel
                        style={{ fontWeight: "bold" }}
                        className={classes.labelHorizontal}
                      >
                        End date
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                      <br />
                      <DateControl
                        timeFormat={true}
                        value={end_dt}
                        onChange={(value) => this.changeValue(value, "end_dt")}
                        errorMessage={this.getErrorFieldMessage("end_dt")}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormLabel
                        style={{ fontWeight: "bold" }}
                        className={classes.labelHorizontal}
                      >
                        Days of Week
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                      <GridContainer>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          style={{ marginTop: 15 }}
                        >
                          <div className={classes.checkboxAndRadio}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={allDaysCheck}
                                  tabIndex={-1}
                                  onClick={() =>
                                    this.setState({
                                      allDaysCheck: !allDaysCheck,
                                    })
                                  }
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                              }}
                              label="Every Day"
                            />
                          </div>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={12}
                          md={10}
                          style={{ marginTop: 15 }}
                        >
                          {!allDaysCheck ? (
                            <div className={classes.checkboxAndRadio}>
                              {[
                                { label: "Monday", value: 1 },
                                { label: "Tuesday", value: 2 },
                                { label: "Wednesday", value: 3 },
                                { label: "Thursday", value: 4 },
                                { label: "Friday", value: 5 },
                                { label: "Saturday", value: 6 },
                                { label: "Sunday", value: 7 },
                              ].map((item, index) => {
                                return (
                                  <FormControlLabel
                                    key={`CampaignUpdateComponent1:${index}`}
                                    control={
                                      <Checkbox
                                        checked={days[item.value]}
                                        onClick={() => {
                                          const { days } = this.state;
                                          days[item.value] = !days[item.value];
                                          this.setState({ days });
                                        }}
                                        checkedIcon={
                                          <Check
                                            className={classes.checkedIcon}
                                          />
                                        }
                                        icon={
                                          <Check
                                            className={classes.uncheckedIcon}
                                          />
                                        }
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot,
                                        }}
                                      />
                                    }
                                    classes={{ label: classes.label }}
                                    label={item.label}
                                  />
                                );
                              })}
                            </div>
                          ) : null}
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  {this.renderScheduleSlider({
                    label: "Time of Day",
                    ref: this.s1,
                  })}
                  <Grid container direction="column" alignItems="flex-end">
                    <Grid item xs={12} sm={12} md={12}>
                      <Button
                        onClick={() =>
                          history.push(
                            `/admin/a/${selectedApplication.slug}/campaign`
                          )
                        }
                        color="rose"
                        className={classes.marginRight}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.saveChanges}
                        color="primary"
                        className={classes.marginRight}
                      >
                        Update campaign
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(validationFormsStyle)(CampaignUpdateComponent);
