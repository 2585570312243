import { takeLatest, put, call } from "redux-saga/effects";
import MsgActions, { MsgTypes } from "../modules/messages";

export function* updateMessage(api, { setting_uuid, data }) {
  try {
    const response = yield call(api.updateMessage, setting_uuid, data);
    yield put(MsgActions.updateMessageSuccess(response.data.message));
  } catch (e) {
    yield put(MsgActions.updateMessageFailure(e));
  }
}

export function* createMessage(api, { setting_uuid, data }) {
  try {
    const response = yield call(api.createMessage, setting_uuid, data);
    yield put(MsgActions.createMessageSuccess(response.data.message));
  } catch (e) {
    yield put(MsgActions.createMessageFailure(e));
  }
}

export function* deleteMessage(api, { setting_uuid }) {
  try {
    yield put(MsgActions.deleteMessageSuccess(setting_uuid));
  } catch (e) {
    yield put(MsgActions.deleteMessageFailure(e));
  }
}

export function* getMessage(api, { setting_uuid }) {
  try {
    const response = yield call(api.getMessage, setting_uuid);
    yield put(MsgActions.getMessageSuccess(response.data.message));
  } catch (e) {
    yield put(MsgActions.getMessageFailure(e));
  }
}

export function* watchMsgs(api) {
  yield takeLatest(MsgTypes.UPDATE_MESSAGE, updateMessage, api);
  yield takeLatest(MsgTypes.CREATE_MESSAGE, createMessage, api);
  yield takeLatest(MsgTypes.DELETE_MESSAGE, deleteMessage, api);
  yield takeLatest(MsgTypes.GET_MESSAGE, getMessage, api);
}
