import React from "react";

import Modal from "@material-ui/core/Modal";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardHeader from "components/core/Card/CardHeader.js";
import Grid from "@material-ui/core/Grid";
import Assignment from "@material-ui/icons/Assignment";

import Button from "components/core/CustomButtons/Button.js";

const DeleteCampaignModal = ({
  classes,
  dialogModalOpen,
  handleDialogClose,
  onDeleteAction,
}) => {
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={dialogModalOpen}
      onClose={handleDialogClose}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Delete campaign</h4>
            </CardHeader>
            <CardBody>
              <Grid
                style={{ marginLeft: 20 }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <h3>
                    Ads from this campaign will no longer be served. This will
                    take effect immediately.
                  </h3>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <br />
                  <br />
                  <Button
                    onClick={handleDialogClose}
                    color="rose"
                    className={classes.marginRight}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={onDeleteAction}
                    color="primary"
                    className={classes.marginRight}
                  >
                    Delete Campaign
                  </Button>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default DeleteCampaignModal;
