import React from "react";
import { NavLink } from "react-router-dom";

import cx from "classnames";

import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";

// this function creates the links and collapses that appear in the sidebar (left menu)
const SidebarLinks = ({
  routes,
  classes,
  color,
  rtlActive,
  slug,
  state,
  miniActive,
  getCollapseInitialState,
  setState,
  activeRoute,
}) => {
  return routes.map((prop, key) => {
    const newPath =
      prop.path.indexOf(":slug") > -1
        ? prop.path.replace(":slug", slug)
        : prop.path;
    if (prop.hidden) {
      return null;
    }
    if (prop.redirect) {
      return null;
    }
    if (prop.collapse) {
      var st = {};
      st[prop["state"]] = !state[prop.state];
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes.collapseActive]: getCollapseInitialState(prop.views),
        });
      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]: miniActive && miniActive,
          [classes.itemTextMiniRTL]: rtlActive && miniActive && miniActive,
          [classes.itemTextRTL]: rtlActive,
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]: miniActive && miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && miniActive && miniActive,
          [classes.collapseItemTextRTL]: rtlActive,
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive,
        });
      const caret =
        classes.caret +
        " " +
        cx({
          [classes.caretRTL]: rtlActive,
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive,
        });
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={"#"}
            activeClassName={navLinkClasses}
            onClick={(e) => {
              e.preventDefault();
              setState(st);
            }}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>
                {rtlActive ? prop.rtlMini : prop.mini}
              </span>
            )}
            <ListItemText
              primary={rtlActive ? prop.rtlName : prop.name}
              secondary={
                <b
                  className={
                    caret + " " + (state[prop.state] ? classes.caretActive : "")
                  }
                />
              }
              disableTypography={true}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
          </NavLink>
          <Collapse in={state[prop.state]} unmountOnExit>
            <List className={classes.list + " " + classes.collapseList}>
              <SidebarLinks
                routes={prop.views}
                classes={classes}
                color={color}
                rtlActive={rtlActive}
                slug={slug}
                state={state}
                miniActive={miniActive}
                getCollapseInitialState={getCollapseInitialState}
                setState={setState}
                activeRoute={activeRoute}
              />
            </List>
          </Collapse>
        </ListItem>
      );
    }
    const innerNavLinkClasses =
      classes.collapseItemLink +
      " " +
      cx({
        [" " + classes[color]]: activeRoute(prop.layout + newPath),
      });
    const collapseItemMini =
      classes.collapseItemMini +
      " " +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive,
      });
    const navLinkClasses =
      classes.itemLink +
      " " +
      cx({
        [" " + classes[color]]: activeRoute(prop.layout + newPath),
      });
    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: miniActive && miniActive,
        [classes.itemTextMiniRTL]: rtlActive && miniActive && miniActive,
        [classes.itemTextRTL]: rtlActive,
      });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]: miniActive && miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && miniActive && miniActive,
        [classes.collapseItemTextRTL]: rtlActive,
      });
    const itemIcon =
      classes.itemIcon +
      " " +
      cx({
        [classes.itemIconRTL]: rtlActive,
      });
    return (
      <ListItem
        key={key}
        className={cx(
          { [classes.item]: prop.icon !== undefined },
          { [classes.collapseItem]: prop.icon === undefined }
        )}
      >
        <NavLink
          to={prop.layout + newPath}
          className={cx(
            { [navLinkClasses]: prop.icon !== undefined },
            { [innerNavLinkClasses]: prop.icon === undefined },
            { "border-top": prop.borderTop ? true : false }
          )}
        >
          {prop.icon !== undefined ? (
            typeof prop.icon === "string" ? (
              <Icon className={itemIcon}>{prop.icon}</Icon>
            ) : (
              <prop.icon className={itemIcon} />
            )
          ) : (
            <span className={collapseItemMini}>
              {rtlActive ? prop.rtlMini : prop.mini}
            </span>
          )}
          <ListItemText
            primary={rtlActive ? prop.rtlName : prop.name}
            disableTypography={true}
            className={cx(
              { [itemText]: prop.icon !== undefined },
              { [collapseItemText]: prop.icon === undefined }
            )}
          />
        </NavLink>
      </ListItem>
    );
  });
};

export default SidebarLinks;
