import React from "react";
import Grid from "@material-ui/core/Grid";
import CustomInput from "../core/CustomInput/CustomInput.js";

class PeriodControl extends React.Component {
  state = {
    value: this.props.value ? this.props.value : {},
    years: this.props.value ? this.props.value.years : null,
    months: this.props.value ? this.props.value.months : null,
    days: this.props.value ? this.props.value.days : null,
    hours: this.props.value ? this.props.value.hours : null,
    minutes: this.props.value ? this.props.value.minutes : null,
    seconds: this.props.value ? this.props.value.seconds : null,
  };
  static validate = ({ required }, value) => {
    if (!required) {
      return true;
    }

    for (var i in value) {
      if (value[i] === null) {
        return false;
      }
    }
  };
  getErrorState = (type) => {
    return this.props.required === true && this.state[type] === null;
  };
  changePeriod = (type, value) => {
    this.setState({ ...this.state, [type]: parseInt(value) }, () => {
      const { value, ...rest } = this.state;
      this.props.onChange(rest);
    });
  };
  render() {
    return (
      <Grid container direction="row">
        <Grid item xs={2} md={2}>
          <CustomInput
            error={this.getErrorState("years")}
            formControlProps={{ fullWidth: true }}
            labelText="Years"
            inputProps={{
              onChange: (event) =>
                this.changePeriod("years", event.target.value),
              type: "number",
              defaultValue: this.state.value.years,
            }}
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <CustomInput
            labelText="Months"
            error={this.getErrorState("months")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: (event) =>
                this.changePeriod("months", event.target.value),
              type: "number",
              defaultValue: this.state.value.months,
            }}
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <CustomInput
            labelText="Days"
            error={this.getErrorState("days")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: (event) =>
                this.changePeriod("days", event.target.value),
              type: "number",
              defaultValue: this.state.value.days,
            }}
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <CustomInput
            labelText="Hours"
            error={this.getErrorState("hours")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: (event) =>
                this.changePeriod("hours", event.target.value),
              type: "number",
              defaultValue: this.state.value.hours,
            }}
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <CustomInput
            labelText="Minutes"
            error={this.getErrorState("minutes")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: (event) =>
                this.changePeriod("minutes", event.target.value),
              type: "number",
              defaultValue: this.state.value.minutes,
            }}
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <CustomInput
            labelText="Seconds"
            error={this.getErrorState("seconds")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: (event) =>
                this.changePeriod("seconds", event.target.value),
              type: "number",
              defaultValue: this.state.value.seconds,
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

PeriodControl.defaultProps = {
  value: {
    years: null,
    months: null,
    days: null,
    hours: null,
    minutes: null,
    seconds: null,
  },
  required: false,
};

export default PeriodControl;
