import React, { useCallback, useEffect, useMemo, useState } from "react";

import api from "../../services/api";
import Session from "../../services/Session";

import GridContainer from "components/core/Grid/GridContainer";
import GridItem from "components/core/Grid/GridItem";
import Card from "components/core/Card/Card";
import CustomDropdown from "components/core/CustomDropdown/CustomDropdown";
import CustomInput from "components/core/CustomInput/CustomInput";
import CardBody from "components/core/Card/CardBody";
import Pagination from "components/core/Pagination/Pagination";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import { chunkArray } from "helpers/utils";
import { Add, Edit } from "@material-ui/icons";

import Button from "components/core/CustomButtons/Button.js";
import CardHeader from "components/core/Card/CardHeader";
import CustomAccordion from "components/core/Accordion/Accordion";
import AuditLogsTable from "components/auditlogs/AuditLogsTable";

import { Grid, Switch, withStyles } from "@material-ui/core";

import SnackbarContent from "components/core/Snackbar/SnackbarContent";

import { SdkModal } from "./SdkModal";

const SdkListContainer = ({ classes }) => {
  const applicationId = Session.getApplication().uuid;
  const refreshItems = () => {
    api.getSdkNetworks().then(setItems).catch(console.error);
  };

  const validateItem = (item) => {
    return (
      item.type != "sdk" ||
      (Object.keys(item.adMixParamsSchema).length > 0 &&
        Object.keys(item.appParamsSchema).length > 0)
    );
  };

  const [items, setItems] = useState([]);
  useEffect(() => {
    refreshItems();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [chunkSize, setChunkSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusMessage, setStatusMessage] = useState("");

  let itemsToRender = useMemo(() => {
    return [...items].sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  }, [items]);

  if (searchValue) {
    itemsToRender = itemsToRender.filter((item) =>
      item.name.includes(searchValue)
    );
  }

  const pages = useMemo(() => chunkArray(itemsToRender, chunkSize), [
    itemsToRender,
    chunkSize,
  ]);

  if (pages.length && currentPage > pages.length) {
    setCurrentPage(pages.length);
    return null;
  }

  itemsToRender = pages.length ? pages[currentPage - 1] : [];

  const handleModalClose = () => {
    setIsModalOpen(false);
    setEditItem(null);
  };

  const onEdit = (item) => {
    setEditItem(item);
    setIsModalOpen(true);
  };

  const RenderPlacements = useCallback(() => {
    return itemsToRender.map((item, index) => {
      return (
        <Grid
          container
          direction="row"
          alignItems="center"
          key={`SDK:${index}`}
        >
          <Grid item xs={12} md={10}>
            <span>{item.name}</span>
          </Grid>
          <Grid item xs={12} md={2}>
            <Grid container direction="row" alignItems="flex-end">
              <Grid item xs={12} md={6}>
                <Switch
                  defaultChecked={item.enabled}
                  onChange={(e) => {
                    const { checked } = e.target;
                    if (!validateItem(item)) {
                      setStatusMessage("SDK Status Update Failed");
                      return false;
                    }
                    api
                      .updateSdkNetworks(item.uuid, {
                        ...item,
                        applicationId,
                        enabled: checked,
                      })
                      .then(refreshItems)
                      .catch(console.error);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Button
                  justIcon
                  round
                  color="success"
                  onMouseDown={() => {
                    onEdit(item);
                  }}
                >
                  <Edit />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  }, [itemsToRender]);

  const renderStatusMessage = () => {
    if (statusMessage) {
      document
        .querySelector("#dashboardMainPanel")
        .scrollTo({ top: 0, behavior: "smooth" });
      setTimeout(() => {
        setStatusMessage("");
      }, 1500);
      return (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={6}>
            <SnackbarContent message={statusMessage} color="danger" />
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <GridContainer>
      {renderStatusMessage()}
      <SdkModal
        classes={classes}
        item={editItem}
        modalOpen={isModalOpen}
        onSubmit={(item) => {
          const payload = {
            ...item,
            appParamsSchema: item.appParamsSchema
              ? JSON.parse(item.appParamsSchema)
              : {},
            adMixParamsSchema: item.adMixParamsSchema
              ? JSON.parse(item.adMixParamsSchema)
              : {},
            applicationId: applicationId,
          };

          let promise = item.uuid
            ? api.updateSdkNetworks(item.uuid, payload)
            : api.createSdkNetworks(payload);

          promise
            .then(() => {
              setStatusMessage(
                item.uuid
                  ? "SDK Updated Successfully"
                  : "SDK Successfully created"
              );
              refreshItems();
            })
            .catch((e) => {
              setStatusMessage(e.toString());
            });
          handleModalClose();
        }}
        handleModalClose={handleModalClose}
      />
      <GridItem xs={11}>
        <Card>
          <GridContainer>
            <GridItem xs={9}>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>SDK Configurations</h4>

                <CustomDropdown
                  hoverColor="primary"
                  buttonText={`Show ${chunkSize} entries`}
                  onClick={(selected) => {
                    setChunkSize(+selected);
                  }}
                  buttonProps={{
                    round: true,
                    style: { marginBottom: "0" },
                    color: "primary",
                  }}
                  dropdownList={[10, 25, 50]}
                />
              </CardHeader>
            </GridItem>
            <GridItem xs={3}>
              <CustomInput
                labelText="Search"
                inputProps={{ onChange: (e) => setSearchValue(e.target.value) }}
              />
            </GridItem>
          </GridContainer>
          <CardBody>
            <RenderPlacements />
            <Pagination
              pages={[
                {
                  active: false,
                  disabled: currentPage === 1,
                  text: "PREV",
                  onClick: () => {
                    if (currentPage === 1) return;
                    setCurrentPage((curr) => curr - 1);
                  },
                },
                ...pages.map((_, i) => {
                  const page = i + 1;
                  return {
                    active: page == currentPage,
                    disabled: false,
                    text: page,
                    onClick: () => {
                      setCurrentPage(page);
                    },
                  };
                }),
                {
                  active: false,
                  disabled: currentPage === pages.length,
                  text: "NEXT",
                  onClick: () => {
                    if (currentPage === pages.length) return;
                    setCurrentPage((curr) => curr + 1);
                  },
                },
              ]}
            ></Pagination>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={11}>
        <Grid container direction="column" alignItems="flex-end">
          <Grid item>
            <Button
              onClick={() => {
                onEdit(null);
              }}
              variant="contained"
              startIcon={<Add />}
              color="success"
            >
              Add SDK
            </Button>
          </Grid>
        </Grid>
      </GridItem>
      <GridItem xs={11}>
        <Card>
          <CardHeader color="rose" icon>
            <h4 className={classes.cardIconTitle}>Audit Logs</h4>
          </CardHeader>
          <CardBody>
            <CustomAccordion
              collapses={[
                {
                  title: "Show/Hide",
                  content: (
                    <AuditLogsTable
                      filters={{
                        domain: "Admixes",
                        scope: "SDK",
                      }}
                    />
                  ),
                },
              ]}
            ></CustomAccordion>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(extendedTablesStyle)(SdkListContainer);
