import React from "react";
import cx from "classnames";
import { useDispatch } from "react-redux";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import AuthActions from "../redux/modules/auth";

// core components
import AdminNavbar from "components/core/Navbars/AdminNavbar.js";
import Sidebar from "components/core/Sidebar/Sidebar.js";
import Session from "../services/Session";
import routes from "routes.js";

import SidebarImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo-white.svg";

let ps;
const useStyles = makeStyles(styles);

const Dashboard = () => {
  let location = useLocation();

  // check if user is authenticated
  const dispatch = useDispatch();
  dispatch(AuthActions.checkAuth());
  // session
  const selectedApplication = Session.get(Session.SELECTED_APPLICATION_KEY);
  const identity = Session.get(Session.IDENTITY_UUID_KEY);
  const applications = Session.get(Session.APPLICATIONS);
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getRoute = () => {
    return location.pathname !== "/admin/full-screen-maps";
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Admixes";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        const newPath =
          routes[i].path.indexOf(":slug") > -1 && selectedApplication
            ? routes[i].path.replace(":slug", selectedApplication.slug)
            : routes[i].path;
        if (location.pathname.indexOf(routes[i].layout + newPath) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const swithchRoutes = (
    <Switch>
      {getRoutes(routes)}
      <Redirect from="/admin" to="/admin/dashboard" />
    </Switch>
  );

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={selectedApplication ? selectedApplication.name : " -- "}
        logo={logo}
        image={SidebarImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color="blue"
        bgColor="black"
        miniActive={miniActive}
        applications={applications}
        identity={identity}
        slug={selectedApplication?.slug}
      />
      <div className={mainPanelClasses} ref={mainPanel} id="dashboardMainPanel">
        <AdminNavbar
          sidebarMinimize={sidebarMinimize}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{swithchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{swithchRoutes}</div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
