import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Add from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";

// core components
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardHeader from "components/core/Card/CardHeader.js";
import history from "../../services/history";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import { SortableContainer } from "react-sortable-hoc";
import arrayMove from "array-move";
import SortableItem from "./AdMixSortableComponent";
import AdMixDeleteModal from "./AdMixDeleteModal";

const SortableList = SortableContainer(
  ({ items, onEdit, onClone, onDelete, onSortEnd }) => {
    return (
      <ul>
        {items.map((value, index) => (
          <SortableItem
            key={`SortableItem-${index}`}
            index={index}
            value={value}
            onEdit={onEdit}
            onClone={onClone}
            onDelete={onDelete}
            onSortEnd={onSortEnd}
            disabled={true}
          />
        ))}
      </ul>
    );
  }
);

class AdMixList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],

      // State to handle confirm modal
      dialogModalOpen: false,
      dialogConfirmAction: null,

      avoidDraggEvent: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // let nextItems = nextProps.items[nextProps.placementName];
    // nextItems = nextItems == undefined ? [] : nextItems;
    // if (nextItems.length !== prevState.items.length) {
    //   return {
    //     items: nextItems,
    //     avoidDraggEvent: false,
    //     dialogModalOpen: false,
    //   };
    // }
    return prevState;
  }

  handleDialogClose = () => {
    this.setState({
      dialogModalOpen: false,
      dialogConfirmAction: null,
      avoidDraggEvent: false,
    });
  };

  handleDialogOpen = (dialogConfirmAction) => {
    if (!dialogConfirmAction) {
      throw new Error("Undefined dialog confirm action callback");
    }

    this.setState({
      dialogModalOpen: true,
      dialogConfirmAction,
      avoidDraggEvent: true,
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (!this.state.avoidDraggEvent) {
      this.setState(
        ({ items }) => ({
          items: arrayMove(items, oldIndex, newIndex),
        }),
        () => {
          const adMix = this.state.items[newIndex];
          this.props.onUpdateAdMix(adMix.uuid, { priority: newIndex });
        }
      );
    }
  };

  onChangeActive = (uuid, is_active) => {
    const index = this.state.items.findIndex((item) => {
      return item.uuid === uuid;
    });
    const items = [...this.state.items];
    items[index].is_active = is_active;
    this.setState({ items }, () => {
      this.props.onUpdateAdMix(uuid, { is_active });
    });
  };

  onEdit = (uuid) => {
    history.push("/admin/ad-mix/" + uuid + "/details");
  };

  onClone = (uuid) => {
    this.props.onCloneAdMix(this.props.groupUuid, uuid);
  };

  onDelete = (uuid) => {
    this.handleDialogOpen(() => {
      this.props.onDeleteAdMix(this.props.groupUuid, uuid);
    });
  };

  onCreateClick = () => {
    // history.push({
    //   pathname: "/admin/ad-mix/create",
    //   state: { groupUuid: this.props.groupUuid },
    // });
  };

  render() {
    const { classes } = this.props;
    const { dialogModalOpen, dialogConfirmAction } = this.state;
    return (
      <GridContainer>
        <AdMixDeleteModal
          classes={classes}
          dialogModalOpen={dialogModalOpen}
          dialogConfirmAction={dialogConfirmAction}
          handleDialogClose={this.handleDialogClose}
        />
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                <b>{this.props.placementName}</b> placement
              </h4>
            </CardHeader>
            <CardBody>
              <SortableList
                items={this.state.items}
                onChangeActive={this.onChangeActive}
                onEdit={this.onEdit}
                onClone={this.onClone}
                onDelete={this.onDelete}
                onSortEnd={this.onSortEnd}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Grid container direction="column" alignItems="flex-end">
            <Grid item>
              <Button
                onClick={this.onCreateClick}
                variant="contained"
                startIcon={<Add />}
                color="success"
              >
                Add Version Group
              </Button>
            </Grid>
          </Grid>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(extendedTablesStyle)(AdMixList);
