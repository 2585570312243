import React from "react";
// @material-ui/core components
import Assignment from "@material-ui/icons/Assignment";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import Modal from "@material-ui/core/Modal";

// core components
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardHeader from "components/core/Card/CardHeader.js";
import CustomDropdown from "components/core/CustomDropdown/CustomDropdown.js";
import SnackbarContent from "components/core/Snackbar/SnackbarContent.js";

import StringControl from "../settings/StringControl";

const AdMixUpdateNetworkModal = ({
  classes,
  networkModalOpen,
  displayNetworkModalStatus,
  handleClose,
  admix_network_error,
  networks,
  network_name,
  handleSelectedNetwork,
  ad_mix_network_name,
  handleChangeTextInput,
  onSubmitNetwork,
}) => {
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={networkModalOpen}
      onClose={handleClose}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ padding: 20 }}
      >
        <Grid item md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Add a network to the Ad Mix
              </h4>
            </CardHeader>
            <CardBody>
              {displayNetworkModalStatus && admix_network_error !== null ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item md={6}>
                    <SnackbarContent
                      message={admix_network_error.getMessage()}
                      color="warning"
                    />
                  </Grid>
                </Grid>
              ) : null}
              <Grid
                style={{ marginLeft: 20 }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <h3>Add a new Network</h3>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <FormLabel>Network</FormLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <CustomDropdown
                    hoverColor="primary"
                    buttonText={network_name}
                    onClick={handleSelectedNetwork}
                    buttonProps={{
                      round: true,
                      style: { marginBottom: "0" },
                      color: "primary",
                    }}
                    dropdownList={[
                      ...networks.map((network) => {
                        return network.name;
                      }),
                      "None",
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <FormLabel>Description</FormLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <StringControl
                    required={true}
                    value={ad_mix_network_name}
                    onChange={(value) =>
                      handleChangeTextInput("ad_mix_network_name", value)
                    }
                  />
                </Grid>
                <Grid container direction="column" alignItems="flex-end">
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      onClick={handleClose}
                      color="rose"
                      className={classes.marginRight}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={onSubmitNetwork}
                      color="primary"
                      className={classes.marginRight}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AdMixUpdateNetworkModal;
