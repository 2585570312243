import { takeLatest, put, call } from "redux-saga/effects";
import AdMixActions, { AdMixTypes } from "../modules/admix";
import AdMixNetworkActions, {
  AdMixNetworkTypes,
} from "../modules/admix/network";
import Session from "../../services/Session";
import history from "../../services/history";

export function* getAdMixes(api, action) {
  try {
    const { group_uuid } = action;
    const response = yield call(api.getGroupAdMixes, group_uuid);
    yield put(AdMixActions.getAdMixesSuccess(response.data.ad_mixes));
  } catch (e) {
    yield put(AdMixActions.getAdMixesFailure(e));
  }
}

export function* updateAdMix(api, { ad_mix_uuid, data }) {
  try {
    const response = yield call(api.updateAdMix, ad_mix_uuid, data);
    yield put(AdMixActions.updateAdMixSuccess(response.data));

    const response2 = yield call(api.getAdMix, ad_mix_uuid);
    yield put(AdMixActions.getAdMixSuccess(response2.data));
  } catch (e) {
    yield put(AdMixActions.updateAdMixFailure(e));
  }
}

export function* createAdMix(api, { group_uuid, data, redirect }) {
  try {
    const response = yield call(api.createAdMix, group_uuid, data);
    yield put(AdMixActions.createAdMixSuccess(response.data.ad_mix));

    Session.admix.add(Session.getApplication().uuid, response.data.ad_mix.uuid);

    if (redirect) {
      const { url } = redirect;
      history.push(url.replace(":uuid", response.data.ad_mix.uuid));
    }
  } catch (e) {
    yield put(AdMixActions.createAdMixFailure(e));
  }
}

export function* getAdMix(api, { ad_mix_uuid }) {
  try {
    const response = yield call(api.getAdMix, ad_mix_uuid);
    yield put(AdMixActions.getAdMixSuccess(response.data));
  } catch (e) {
    yield put(AdMixActions.getAdMixFailure(e));
  }
}

export function* addNetwork(api, { ad_mix_uuid, name, ad_provider, redirect }) {
  try {
    const response = yield call(api.createAdMixNetwork, ad_mix_uuid, {
      name: ad_provider,
      description: name,
    });
    yield put(AdMixNetworkActions.addNetworkSuccess([]));

    const response2 = yield call(api.getAdMix, ad_mix_uuid);
    yield put(AdMixActions.getAdMixSuccess(response2.data));

    if (redirect) {
      const { url } = redirect;
      history.push(url.replace(":uuid", ad_mix_uuid));
    }
  } catch (e) {
    yield put(AdMixNetworkActions.addNetworkFailure(e));
  }
}

export function* deleteNetwork(api, { ad_mix_uuid, network_uuid }) {
  try {
    const response = yield call(api.deleteAdMixNetwork, network_uuid);
    yield put(AdMixNetworkActions.deleteNetworkSuccess());

    const response2 = yield call(api.getAdMix, ad_mix_uuid);
    yield put(AdMixActions.getAdMixSuccess(response2.data));
  } catch (e) {
    yield put(AdMixNetworkActions.deleteNetworkFailure(e));
  }
}

export function* deleteAdMix(api, { placement, ad_mix_uuid, redirect }) {
  try {
    const response = yield call(
      api.deleteAdMix,
      Session.getApplication().uuid,
      placement,
      ad_mix_uuid
    );
    yield put(AdMixActions.deleteAdMixSuccess(response.data));
    if (redirect) {
      redirect();
    }
  } catch (e) {
    yield put(AdMixActions.deleteAdMixFailure(e));
  }
}

export function* cloneAdMix(api, { ad_mix_uuid, redirect }) {
  try {
    const response = yield call(api.cloneAdMix, ad_mix_uuid);
    yield put(AdMixActions.cloneAdMixSuccess(response.data));

    if (redirect) {
      redirect();
    }
  } catch (e) {
    yield put(AdMixActions.cloneAdMixFailure(e));
  }
}

export function* getAdMixNetwork(api, { ad_mix_network_uuid }) {
  try {
    const response = yield call(api.getAdMixNetwork, ad_mix_network_uuid);
    const networkParams = yield call(
      api.getNetworkParameters,
      response.data.name
    );
    const data = { ...response.data };
    data.validationParams = networkParams.adMixParamsSchema?.parameters || [];
    yield put(AdMixNetworkActions.getAdMixNetworkSuccess(data));
  } catch (e) {
    yield put(AdMixNetworkActions.getAdMixNetworkFailure(e));
  }
}

export function* patchAdMixNetwork(api, { ad_mix_network_uuid, data }) {
  try {
    const response = yield call(
      api.patchAdMixNetwork,
      ad_mix_network_uuid,
      data
    );
    yield put(AdMixNetworkActions.patchAdMixNetworkSuccess(response.data));
  } catch (e) {
    if (
      e.response != null &&
      e.response.data != null &&
      e.response.data.error != null
    ) {
      e.message = e.response.data.error;
    }
    yield put(AdMixNetworkActions.patchAdMixNetworkFailure(e));
  }
}

export function* watchAdMix(api) {
  yield takeLatest(AdMixTypes.GET_AD_MIXES, getAdMixes, api);
  yield takeLatest(AdMixTypes.UPDATE_AD_MIX, updateAdMix, api);
  yield takeLatest(AdMixTypes.CREATE_AD_MIX, createAdMix, api);
  yield takeLatest(AdMixTypes.GET_AD_MIX, getAdMix, api);
  yield takeLatest(AdMixTypes.DELETE_AD_MIX, deleteAdMix, api);
  yield takeLatest(AdMixTypes.CLONE_AD_MIX, cloneAdMix, api);

  yield takeLatest(AdMixNetworkTypes.ADD_NETWORK, addNetwork, api);
  yield takeLatest(AdMixNetworkTypes.DELETE_NETWORK, deleteNetwork, api);
  yield takeLatest(AdMixNetworkTypes.GET_AD_MIX_NETWORK, getAdMixNetwork, api);
  yield takeLatest(
    AdMixNetworkTypes.PATCH_AD_MIX_NETWORK,
    patchAdMixNetwork,
    api
  );
}
