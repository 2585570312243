import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getAbTests: null,
  getAbTestsSuccess: ["items"],
  getAbTestsFailure: ["error"],
});

export const AbTestTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  items: [],
  selected: null,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_AB_TESTS]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      items: [],
    };
  },
  [Types.GET_AB_TESTS_SUCCESS]: (state, { items }) => {
    return {
      ...state,
      loading: false,
      items: items,
      error: null,
    };
  },
  [Types.GET_AB_TESTS_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error: error,
      items: [],
    };
  },
});
