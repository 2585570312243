import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getPlacementGroup: ["placement"],
  getPlacementGroupSuccess: ["items"],
  getPlacementGroupFailure: ["error"],

  insertPlacementGroup: ["placement", "data", "redirect"],
  insertPlacementGroupSuccess: [],
  insertPlacementGroupFailure: ["error"],

  insertGroupAdmix: ["placement", "group", "data", "redirect"],
  insertGroupAdmixSuccess: [],
  insertGroupAdmixFailure: ["error"],

  deletePlacementGroup: ["placement", "group_uuid", "redirect"],
  deletePlacementGroupSuccess: [],
  deletePlacementGroupFailure: ["error"],
});

export const PlacementTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  items: [],
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PLACEMENT_GROUP]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      items: [],
    };
  },
  [Types.GET_PLACEMENT_GROUP_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      items: action.items,
      error: null,
    };
  },
  [Types.GET_PLACEMENT_GROUP_FAILURE]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      items: [],
    };
  },
  //Insert new placement group
  [Types.INSERT_PLACEMENT_GROUP]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [Types.INSERT_PLACEMENT_GROUP_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      items: action.items,
      error: null,
    };
  },
  [Types.INSERT_PLACEMENT_GROUP_FAILURE]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  },
  //Insert new admix
  [Types.INSERT_GROUP_ADMIX]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [Types.INSERT_GROUP_ADMIX_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  },
  [Types.INSERT_GROUP_ADMIX_FAILURE]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  },
  //Insert new admix
  [Types.DELETE_PLACEMENT_GROUP]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [Types.DELETE_PLACEMENT_GROUP_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  },
  [Types.DELETE_PLACEMENT_GROUP_FAILURE]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  },
});
