import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import SnackbarContent from "components/core/Snackbar/SnackbarContent.js";

const StatusMessage = ({ message, color }) => {
  const [open, setOpen] = useState(true);

  return open ? (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item md={6}>
        <SnackbarContent
          onClose={() => setOpen(false)}
          close
          message={message}
          color={color}
        />
      </Grid>
    </Grid>
  ) : null;
};

export default StatusMessage;
