import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import createStore from "./redux";
import api from "./services/api";
import * as moment from "moment-timezone";
import history from "services/history";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import LoginContainer from "views/auth/LoginContainer";
import Session from "./services/Session";

const lastLogin = Session.get(Session.LAST_LOGIN_KEY);

if (!lastLogin || moment(lastLogin).diff(moment(), "hours") <= -24) {
  Session.destroy();
  history.push("/auth/login");
}

moment.tz.setDefault("Etc/UTC");

const store = createStore({ api });

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/auth/login" component={LoginContainer} />
        <Route path="/rtl" component={RtlLayout} />
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
