import { takeLatest } from "redux-saga/effects";
import { ActionTypes } from "../modules/app";

export function handleError() {
  // Any special handling goes here (redirectio to Error component is happening in the UI)
}

export function* watchApp({ config }) {
  yield takeLatest(ActionTypes.APP_ERROR, handleError, config);
}
