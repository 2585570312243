import { Modal, Grid, Card, FormLabel } from "@material-ui/core";
import { Assignment } from "@material-ui/icons";
import CardBody from "components/core/Card/CardBody";
import CardHeader from "components/core/Card/CardHeader";
import CardIcon from "components/core/Card/CardIcon";
import BooleanControl from "components/settings/BooleanControl";
import EnumControl from "components/settings/EnumControl";
import StringControl from "components/settings/StringControl";
import Button from "components/core/CustomButtons/Button.js";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";

const validJSON = (value, { type }) => {
  try {
    const val = JSON.parse(value);
    if (type === "sdk" && !Object.keys(val).length) {
      return "This can't be empty on SDK Typed Networks";
    } else return undefined;
  } catch (e) {
    if (value == "" && type !== "sdk") return undefined;
    return "Should be a valid JSON";
  }
};

const minLength = (min) => (value) => {
  if (value && value.length >= min) return undefined;
  return `Name should have minimum ${min} characters`;
};

export const SdkModal = ({
  classes,
  item,
  modalOpen,
  handleModalClose,
  onSubmit,
}) => {
  const [type, setType] = useState(item?.type ?? "sdk");

  const defaultValues = {
    name: "",
    type: "sdk",
    enabled: true,
    adMixParamsSchema: JSON.stringify(
      item?.adMixParamsSchema ?? {
        parameters: [
          {
            name: "cache_time",
            type: "integer",
            validation: null,
          },
          {
            name: "placement_id",
            type: "string",
            validation: null,
          },
          {
            name: "type",
            type: "string",
            validation: null,
          },
        ],
      },
      null,
      4
    ),
    appParamsSchema: JSON.stringify(
      item?.appParamsSchema ?? {
        parameters: [
          {
            name: "apiKey",
            type: "string",
            validation: null,
          },
          {
            name: "SDKVersion",
            type: "string",
            validation: null,
          },
        ],
      },
      null,
      4
    ),
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={modalOpen}
      onClose={handleModalClose}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ padding: 20 }}
      >
        <Grid item md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {item != null && item != ""
                  ? "Edit current SDK"
                  : "Add new SDK"}
              </h4>
            </CardHeader>
            <CardBody>
              <Form
                onSubmit={onSubmit}
                initialValues={
                  item
                    ? {
                        ...item,
                        adMixParamsSchema: JSON.stringify(
                          item.adMixParamsSchema,
                          null,
                          4
                        ),
                        appParamsSchema: JSON.stringify(
                          item.appParamsSchema,
                          null,
                          4
                        ),
                      }
                    : defaultValues
                }
              >
                {({ handleSubmit }) => (
                  <Grid
                    style={{ marginLeft: 20 }}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={12} md={3}>
                      <FormLabel>Name</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Field name="name" validate={minLength(3)}>
                        {({ input: Input, meta }) => {
                          return (
                            <>
                              <StringControl
                                required={true}
                                value={Input.value}
                                onChange={Input.onChange}
                              />
                              {meta.error && meta.touched && (
                                <span className={classes.errorSpan}>
                                  {meta.error}
                                </span>
                              )}
                            </>
                          );
                        }}
                      </Field>
                    </Grid>

                    <Grid item xs={12} sm={12} md={3}>
                      <FormLabel>Type</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Field name="type" defaultValue={"sdk"}>
                        {({ input: Input }) => {
                          return (
                            <>
                              <EnumControl
                                required={true}
                                value={Input.value}
                                options={["sdk", "html"]}
                                onChange={(e) => {
                                  Input.onChange(e);
                                  setType(e);
                                }}
                              />
                            </>
                          );
                        }}
                      </Field>
                    </Grid>

                    <Grid item xs={12} sm={12} md={3}>
                      <FormLabel>Enabled</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Field name="enabled">
                        {({ input: Input }) => {
                          return (
                            <>
                              <BooleanControl
                                required={true}
                                value={Input.value}
                                onChange={Input.onChange}
                              />
                            </>
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <FormLabel>Application Setting Schema</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Field
                        name="appParamsSchema"
                        parse={(i) => i}
                        validate={validJSON}
                      >
                        {({ input: Input, meta }) => {
                          return (
                            <>
                              <StringControl
                                required={type === "sdk"}
                                value={Input.value}
                                onChange={Input.onChange}
                                multiline={true}
                                rows={5}
                              />
                              {meta.error && meta.touched && (
                                <span className={classes.errorSpan}>
                                  {meta.error}
                                </span>
                              )}
                            </>
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <FormLabel>Admix Setting Schema</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Field
                        name="adMixParamsSchema"
                        parse={(i) => i}
                        validate={validJSON}
                      >
                        {({ input: Input, meta }) => {
                          return (
                            <>
                              <StringControl
                                required={type === "sdk"}
                                value={Input.value}
                                onChange={Input.onChange}
                                multiline={true}
                                rows={5}
                              />
                              {meta.error && meta.touched && (
                                <span className={classes.errorSpan}>
                                  {meta.error}
                                </span>
                              )}
                            </>
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid container direction="column" alignItems="flex-end">
                      <Grid item xs={12} sm={12} md={12}>
                        <Button
                          onClick={handleModalClose}
                          color="rose"
                          className={classes.marginRight}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleSubmit}
                          color="primary"
                          className={classes.marginRight}
                        >
                          {item != null && item != "" ? "Edit" : "Add"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Form>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
};
