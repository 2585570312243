import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CampaignActions from "../../redux/modules/campaign";
import ApplicationActions from "../../redux/modules/application";
import AdActions from "../../redux/modules/ads";
import AssetActions from "../../redux/modules/asset";
import CampaignUpdateComponent from "../../components/campaign/CampaignUpdateComponent";
import validateUrl from "../ValidateApplicationUrl";

const CampaignUpdateContainer = ({
  match: {
    params: { uuid, slug },
  },
  campaign,
  actions,
  asset,
  ads,
}) => {
  useEffect(() => {
    validateUrl(slug, actions.application.selectApplication);

    actions.campaign.getCampaign(uuid);
    actions.ads.getAds(uuid);
    // TODO : actions.abtest.getAdTypes();
  }, []);

  const onCreateAd = (ad) => {
    ads.createAd(uuid, ad);
  };

  return campaign.fetched ? (
    <CampaignUpdateComponent
      campaign={campaign.fetched}
      error={campaign.error}
      onUpdateCampaign={actions.campaign.updateCampaign}
      onUploadFile={actions.asset.uploadFile}
      uploadedKey={asset.key}
      uploadedFilename={asset.filename}
      uploadedFiletype={asset.filetype}
      onCreateAd={onCreateAd}
      onUpdateAd={actions.ads.updateAd}
      onDeleteAd={actions.ads.deleteAd}
      onFetchAds={actions.ads.getAds}
      onFetchAd={actions.ads.getAd}
      adError={ads.error}
      adCreated={ads.created}
      adUpdated={ads.updated}
      adFetched={ads.fetched}
      adDeleted={ads.deleted}
      ads={ads.items}
    />
  ) : null;
};

const mapStateToProps = ({ campaign, asset, ads }) => ({
  campaign,
  asset,
  ads,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    campaign: bindActionCreators(CampaignActions, dispatch),
    asset: bindActionCreators(AssetActions, dispatch),
    ads: bindActionCreators(AdActions, dispatch),
    application: bindActionCreators(ApplicationActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignUpdateContainer);
