import React, { useState } from "react";
// @material-ui/core components
import Assignment from "@material-ui/icons/Assignment";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import Modal from "@material-ui/core/Modal";

// core components
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardHeader from "components/core/Card/CardHeader.js";
import SnackbarContent from "components/core/Snackbar/SnackbarContent.js";

import StringControl from "../settings/StringControl";

const AddPlacementModal = ({
  classes,
  placementModalOpen,
  placement,
  handleDialogClose,
  onSubmitPlacement,
}) => {
  const [newPlacement, setPlacement] = useState("");
  const [isFirstOpen, setFirstOpen] = useState(false);
  if (!isFirstOpen && placementModalOpen) {
    setPlacement(placement);
    setFirstOpen(true);
  }
  if (!placementModalOpen && isFirstOpen) {
    setFirstOpen(false);
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={placementModalOpen}
      onClose={handleDialogClose}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ padding: 20 }}
      >
        <Grid item md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {placement != null && placement != ""
                  ? "Edit current placement"
                  : "Add new placement"}
              </h4>
            </CardHeader>
            <CardBody>
              <Grid
                style={{ marginLeft: 20 }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={3}>
                  <FormLabel>Placement name</FormLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <StringControl
                    required={true}
                    value={newPlacement == null ? "" : newPlacement}
                    onChange={(newText) => {
                      setPlacement(newText);
                    }}
                  />
                </Grid>
                <Grid container direction="column" alignItems="flex-end">
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      onClick={handleDialogClose}
                      color="rose"
                      className={classes.marginRight}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => onSubmitPlacement(placement, newPlacement)}
                      color="primary"
                      className={classes.marginRight}
                    >
                      {placement != null && placement != "" ? "Edit" : "Add"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddPlacementModal;
