import React, { useState } from "react";

import { SortableElement } from "react-sortable-hoc";

import Edit from "@material-ui/icons/Edit";
import FileCopy from "@material-ui/icons/FileCopy";
import Close from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import IntegerControl from "../settings/IntegerControl";

const StyledChip = withStyles({
  root: {
    backgroundColor: "#f5f5dc", // here you can do anything actually
  },
  label: {
    // textTransform: "capitalize",
    fontWeight: "bold",
  },
})(Chip);

const AdMixSortableItem = ({
  value,
  onEdit,
  onClone,
  onDelete,
  onChangeActive,
  onChangePercentage,
}) => {
  const [percentage, setPercentage] = useState(value.percentage);
  const [isEnabled, setEnabled] = useState(value.enabled);
  const backgroundColor = !isEnabled ? "#d3d3d3" : "#FFFFFF";

  const updatePercentage = (percentage) => {
    setPercentage(percentage);
    onChangePercentage(value.uuid, percentage);
  };

  const updateEnabled = (enabled) => {
    setEnabled(enabled);
    onChangeActive(value.uuid, enabled);
  };

  return (
    <li style={{ listStyle: "none" }}>
      <Card style={{ backgroundColor: backgroundColor }}>
        <CardBody>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} md={1}>
              <Grid container direction="row" alignItems="flex-end">
                <Grid item xs={12} md={10}>
                  <StyledChip
                    label={
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item xs={12} md={7}>
                          <IntegerControl
                            value={percentage}
                            showPercentage={true}
                            onChange={(item) => {
                              updatePercentage(
                                item == null || item < 0
                                  ? 0
                                  : item >= 100
                                  ? 100
                                  : item
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <strong
                            style={{
                              color: "#000",
                              fontSize: 25,
                            }}
                          >
                            %
                          </strong>
                        </Grid>
                      </Grid>
                    }
                    color="primary"
                  />
                </Grid>
                <Grid item xs={12} md={2}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <strong>{value.name}</strong>
            </Grid>
            <Grid item xs={12} md={3}>
              {value.description}
            </Grid>
            <Grid item xs={12} md={2}>
              <Switch
                checked={isEnabled}
                onChange={() => {
                  updateEnabled(!isEnabled);
                }}
                label={"Is Active"}
              />
              <small>{isEnabled ? "Enabled" : "Disabled"}</small>
            </Grid>
            <Grid item xs={12} md={2}>
              <Grid container direction="row" alignItems="flex-end">
                <Grid item xs={12} md={4}>
                  <Button
                    justIcon
                    round
                    color="success"
                    onMouseDown={() => onEdit(value.uuid)}
                  >
                    <Edit />
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    justIcon
                    round
                    color="primary"
                    onMouseDown={() => onClone(value.uuid)}
                  >
                    <FileCopy />
                  </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    justIcon
                    round
                    color="danger"
                    onMouseDown={() => onDelete(value.uuid)}
                  >
                    <Close />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </li>
  );
};

export default AdMixSortableItem;
