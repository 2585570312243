import React from "react";

import { SortableElement } from "react-sortable-hoc";

import Edit from "@material-ui/icons/Edit";
import FileCopy from "@material-ui/icons/FileCopy";
import Close from "@material-ui/icons/Close";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";

const StyledChip = withStyles({
  root: {
    // backgroundColor: "red", // here you can do anything actually
  },
  label: {
    // textTransform: "capitalize",
    fontWeight: "bold",
  },
})(Chip);

const SortableItem = SortableElement(
  ({ value, onEdit, onClone, onDelete, onChangeActive }) => {
    return (
      <li style={{ listStyle: "none" }}>
        <Card>
          <div>
            <StyledChip
              label={
                "Placement: " +
                (value.placement == null || value.placement === ""
                  ? "Default"
                  : value.placement)
              }
              color="primary"
            />
          </div>
          <CardBody>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={12} md={1}>
                {value.priority}
              </Grid>
              <Grid item xs={12} md={3}>
                <strong>{value.name}</strong>
              </Grid>
              <Grid item xs={12} md={3}>
                {value.description}
              </Grid>
              <Grid item xs={12} md={2}>
                <Switch
                  checked={value.enabled}
                  onChange={() => {
                    onChangeActive(value.uuid, !value.enabled);
                  }}
                  label={"Is Active"}
                />
                <small>{value.is_active ? "Enabled" : "Disabled"}</small>
              </Grid>
              <Grid item xs={12} md={2}>
                <Grid container direction="row" alignItems="flex-end">
                  <Grid item xs={12} md={4}>
                    <Button
                      justIcon
                      round
                      color="success"
                      onMouseDown={() => onEdit(value.uuid)}
                    >
                      <Edit />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Button
                      justIcon
                      round
                      color="primary"
                      onMouseDown={() => onClone(value.uuid)}
                    >
                      <FileCopy />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Button
                      justIcon
                      round
                      color="danger"
                      onMouseDown={() => onDelete(value.uuid)}
                    >
                      <Close />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </li>
    );
  }
);

export default SortableItem;
