import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getApplications: null,
  getApplicationsSuccess: ["items"],
  getApplicationsFailure: ["error"],

  selectApplication: ["uuid"],
  selectApplicationSuccess: ["selected"],
  selectApplicationFailure: ["error"],

  getSelectedApplication: null,
  getSelectedApplicationSuccess: ["selected"],

  updateApplication: ["uuid", "data"],
  updateApplicationSuccess: ["updated"],
  updateApplicationFailure: ["error"],
});

export const ApplicationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  items: [],
  selected: null,
  updated: null,
  settings: [],
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_APPLICATIONS]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      items: [],
    };
  },
  [Types.GET_APPLICATIONS_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      items: action.items,
      error: null,
    };
  },
  [Types.GET_APPLICATIONS_FAILURE]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      items: [],
    };
  },

  // Select an application
  [Types.SELECT_APPLICATION]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      selected: null,
    };
  },
  [Types.SELECT_APPLICATION_SUCCESS]: (state, { selected }) => {
    return {
      ...state,
      loading: false,
      error: null,
      selected,
    };
  },
  [Types.SELECT_APPLICATION_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      selected: null,
    };
  },

  // Get Selected application
  [Types.GET_SELECTED_APPLICATION]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      selected: null,
    };
  },
  [Types.GET_SELECTED_APPLICATION_SUCCESS]: (state, { selected }) => {
    return {
      ...state,
      loading: false,
      error: null,
      selected,
    };
  },

  // Update application
  [Types.UPDATE_APPLICATION]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      updated: null,
    };
  },
  [Types.UPDATE_APPLICATION_SUCCESS]: (state, { updated }) => {
    return {
      ...state,
      loading: false,
      updated,
      error: null,
    };
  },
  [Types.UPDATE_APPLICATION_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      updated: null,
    };
  },
});
