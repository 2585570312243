import React from "react";
import PropTypes from "prop-types";
import CustomDropdown from "../core/CustomDropdown/CustomDropdown.js";

class EnumControl extends React.Component {
  static validate = (value) => {
    console.log("undefined validation -> value", value);
  };
  getErrorState = () => {
    if (!this.props.errorMessage) {
      return (
        this.props.required === true &&
        (this.props.value === null || this.props.value === "")
      );
    } else {
      return !!this.props.errorMessage;
    }
  };
  getErrorString = () => {
    if (!this.props.errorMessage) {
      return this.props.required === true && this.getErrorState()
        ? "Required *"
        : "";
    } else if (this.props.errorMessage) {
      return this.props.errorMessage;
    }
  };
  handleChange = (event) => {
    this.props.onChange(event.target.value);
  };

  handleSelectedAdType = (value) => {
    this.handleChange({ target: { value: value } });
  };
  render() {
    return (
      <CustomDropdown
        hoverColor="primary"
        buttonText={this.props.value || this.getErrorString()}
        buttonProps={{
          round: true,
          style: { marginBottom: "0" },
          color: "primary",
        }}
        dropdownList={this.props.options}
        onClick={this.handleSelectedAdType}
      />
    );
  }
}

EnumControl.defaultProps = {
  required: false,
  value: "",
  multiline: false,
  rows: 1,
};

EnumControl.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default EnumControl;
