import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import validationFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

class BooleanControl extends React.Component {
  handleChange = () => {
    this.props.onChange(!this.props.value);
  };
  render() {
    return (
      <Switch
        checked={!!this.props.value}
        onChange={this.handleChange}
        label={this.props.label}
      />
    );
  }
}

// TODO add propTypes

export default withStyles(validationFormsStyle)(BooleanControl);
