import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PlacementActions from "../../redux/modules/placementgroups";
import AdMixCreate from "../../components/admix/AdMixCreate";
import Session from "../../services/Session";
import history from "../../services/history";

const PlacementAdMixCreateContainer = ({
  match: {
    params: { placement, group },
  },
  actions,
  placementgroup,
}) => {
  const application = Session.getApplication();

  useEffect(() => {}, []);

  return (
    <AdMixCreate
      application={application}
      placement={placement}
      group={group}
      onCreate={actions.placement.insertGroupAdmix}
      error={placementgroup.error}
    />
  );
};

const mapStateToProps = ({ placementgroup }) => ({
  placementgroup,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    placement: bindActionCreators(PlacementActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlacementAdMixCreateContainer);
