import { takeLatest, put, call } from "redux-saga/effects";
import NetworkActions, { NetworkTypes } from "../modules/network";
import Session from "services/Session";

export function* getNetworks(api) {
  try {
    const response = yield call(api.getNetworks, Session.getApplication().uuid);
    yield put(NetworkActions.getNetworksSuccess(response));
  } catch (e) {
    yield put(NetworkActions.getNetworksFailure(e));
  }
}

export function* getNetworkParameters(api, { name }) {
  try {
    const response = yield call(api.getNetworkParameters, name);
    yield put(
      NetworkActions.getNetworkParametersSuccess(response.data.parameters)
    );
  } catch (e) {
    yield put(NetworkActions.getNetworkParametersFailure(e));
  }
}

export function* watchNetwork(api) {
  yield takeLatest(NetworkTypes.GET_NETWORKS, getNetworks, api);
  yield takeLatest(
    NetworkTypes.GET_NETWORK_PARAMETERS,
    getNetworkParameters,
    api
  );
}
