import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import ReactJson from "react-json-view";
import validationFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

class JsonControl extends React.Component {
  state = {
    value: this.props.value ? this.props.value : {},
  };
  handleChange = (data) => {
    this.setState({ value: data.updated_src }, () => {
      this.props.onChange(data.updated_src);
    });
  };
  onEdit = (data) => {
    this.handleChange(data);
  };
  onDelete = (data) => {
    this.handleChange(data);
  };
  onAdd = (data) => {
    this.handleChange(data);
  };
  render() {
    return (
      <ReactJson
        theme="monokai"
        src={this.state.value}
        onEdit={this.onEdit}
        onDelete={this.onDelete}
        onAdd={this.onAdd}
      />
    );
  }
}

export default withStyles(validationFormsStyle)(JsonControl);
