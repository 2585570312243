import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SettingActions from "../../redux/modules/setting";
import Settings from "../../components/settings/Settings";

const SettingsContainer = ({
  match: {
    params: { uuid },
  },
  actions,
  setting,
  history: { goBack },
}) => {
  useEffect(() => {
    actions.setting.fetch(uuid);
  }, []);

  const onSave = (settings) => {
    actions.setting.update(uuid, settings);
  };

  return (
    <Settings
      items={setting.items}
      error={setting.error}
      onSave={onSave}
      onBack={goBack}
    />
  );
};

const mapStateToProps = ({ setting }) => ({
  setting,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    setting: bindActionCreators(SettingActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
