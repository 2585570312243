import { takeLatest, put, call } from "redux-saga/effects";
import PlacementActions, { PlacementTypes } from "../modules/placementgroups";
import Session from "../../services/Session";
import history from "../../services/history";

export function* getPlacementGroup(api, { placement }) {
  try {
    const response = yield call(
      api.getPlacementGroup,
      Session.getApplication().uuid,
      placement
    );
    yield put(PlacementActions.getPlacementGroupSuccess(response.data.groups));
  } catch (e) {
    yield put(PlacementActions.getPlacementGroupFailure(e));
  }
}

export function* insertPlacementGroup(api, { placement, data, redirect }) {
  try {
    yield call(
      api.insertPlacementGroup,
      Session.getApplication().uuid,
      placement,
      data
    );
    yield put(PlacementActions.insertPlacementGroupSuccess());

    if (redirect) {
      const { url } = redirect;
      if (url == undefined) {
        redirect();
      } else {
        history.push(url);
      }
    }
  } catch (e) {
    if (
      e.getMessage() == null &&
      e.response != null &&
      e.response.data != null
    ) {
      e.message = e.response.data.error;
    }

    yield put(PlacementActions.insertPlacementGroupFailure(e));
  }
}

export function* deletePlacementGroup(
  api,
  { placement, group_uuid, redirect }
) {
  try {
    yield call(
      api.deletePlacementGroup,
      Session.getApplication().uuid,
      placement,
      group_uuid
    );
    yield put(PlacementActions.deletePlacementGroupSuccess());

    if (redirect) {
      const { url } = redirect;
      if (url == undefined) {
        redirect();
      } else {
        history.push(url);
      }
    }
  } catch (e) {
    if (
      e.getMessage() == null &&
      e.response != null &&
      e.response.data != null
    ) {
      e.message = e.response.data.error;
    }

    yield put(PlacementActions.deletePlacementGroupFailure(e));
  }
}

export function* insertGroupAdmix(api, { placement, group, data, redirect }) {
  try {
    const response = yield call(
      api.insertGroupAdmix,
      Session.getApplication().uuid,
      placement,
      group,
      data
    );
    yield put(PlacementActions.insertGroupAdmixSuccess());
    if (redirect) {
      const { url } = redirect;
      history.push(url.replace(":uuid", response.data.uuid));
    }
  } catch (e) {
    yield put(PlacementActions.insertGroupAdmixFailure(e));
  }
}

export function* watchPlacementGroup(api) {
  yield takeLatest(PlacementTypes.GET_PLACEMENT_GROUP, getPlacementGroup, api);
  yield takeLatest(
    PlacementTypes.INSERT_PLACEMENT_GROUP,
    insertPlacementGroup,
    api
  );
  yield takeLatest(PlacementTypes.INSERT_GROUP_ADMIX, insertGroupAdmix, api);
  yield takeLatest(
    PlacementTypes.DELETE_PLACEMENT_GROUP,
    deletePlacementGroup,
    api
  );
}
