import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getAdMixes: ["group_uuid"],
  getAdMixesSuccess: ["items"],
  getAdMixesFailure: ["error"],

  updateAdMix: ["ad_mix_uuid", "data"],
  updateAdMixSuccess: ["updated"],
  updateAdMixFailure: ["error"],

  createAdMix: ["group_uuid", "data", "redirect"],
  createAdMixSuccess: ["created"],
  createAdMixFailure: ["error"],

  getAdMix: ["ad_mix_uuid"],
  getAdMixSuccess: ["fetched"],
  getAdMixFailure: ["error"],

  deleteAdMix: ["placement", "ad_mix_uuid", "redirect"],
  deleteAdMixSuccess: null,
  deleteAdMixFailure: ["error"],

  cloneAdMix: ["ad_mix_uuid", "redirect"],
  cloneAdMixSuccess: null,
  cloneAdMixFailure: ["error"],
});

export const AdMixTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  items: [],
  updated: null,
  created: null,
  fetched: null,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_AD_MIXES]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      items: [],
    };
  },
  [Types.GET_AD_MIXES_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      items: action.items,
      error: null,
    };
  },
  [Types.GET_AD_MIXES_FAILURE]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      items: [],
    };
  },

  // Update ad mix
  [Types.UPDATE_AD_MIX]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      updated: null,
    };
  },
  [Types.UPDATE_AD_MIX_SUCCESS]: (state, { updated }) => {
    return {
      ...state,
      loading: false,
      updated,
      error: null,
    };
  },
  [Types.UPDATE_AD_MIX_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      updated: null,
    };
  },

  // Create an ad mix
  [Types.CREATE_AD_MIX]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      created: null,
    };
  },
  [Types.CREATE_AD_MIX_SUCCESS]: (state, { created }) => {
    return {
      ...state,
      loading: false,
      created,
      error: null,
    };
  },
  [Types.CREATE_AD_MIX_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      created: null,
    };
  },

  // Fetch ad mix
  [Types.GET_AD_MIX]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      fetched: null,
    };
  },
  [Types.GET_AD_MIX_SUCCESS]: (state, { fetched }) => {
    return {
      ...state,
      loading: false,
      fetched,
      error: null,
    };
  },
  [Types.GET_AD_MIX_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      fetched: null,
    };
  },

  // Delete ad mix
  [Types.DELETE_AD_MIX]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [Types.DELETE_AD_MIX_SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  },
  [Types.DELETE_AD_MIX_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  },

  // Clone ad mix
  [Types.CLONE_AD_MIX]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [Types.CLONE_AD_MIX_SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  },
  [Types.CLONE_AD_MIX_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  },
});
