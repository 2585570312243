import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";

// core components
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import Table from "components/core/Table/Table.js";
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardHeader from "components/core/Card/CardHeader.js";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

class AdList extends React.Component {
  state = {
    dialogModalOpen: false,
    dialogConfirmAction: null,
  };
  handleDialogClose = () => {
    this.setState({
      dialogModalOpen: false,
      dialogConfirmAction: null,
    });
  };
  handleDialogOpen = (dialogConfirmAction) => {
    if (!dialogConfirmAction) {
      throw new Error("Undefined dialog confirm action callback");
    }

    this.setState({
      dialogModalOpen: true,
      dialogConfirmAction,
    });
  };
  onDelete = (uuid) => {
    this.handleDialogOpen(() => {
      this.props.onDelete(uuid);
      this.handleDialogClose();
    });
  };
  renderDialogModal = () => {
    const { classes } = this.props;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.dialogModalOpen}
        onClose={this.handleDialogClose}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={6}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Delete Ad</h4>
              </CardHeader>
              <CardBody>
                <Grid
                  style={{ marginLeft: 20 }}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <h3>
                      This will immediately remove the ad from the campaign.
                    </h3>
                  </Grid>
                  <Grid container direction="column" alignItems="flex-end">
                    <Grid item xs={12} sm={12} md={12}>
                      <Button
                        onClick={this.handleDialogClose}
                        color="rose"
                        className={classes.marginRight}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.state.dialogConfirmAction}
                        color="primary"
                        className={classes.marginRight}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Modal>
    );
  };
  renderRows = (classes) => {
    return this.props.items.map((item, index) => {
      const image =
        item.assets && item.assets.length > 0
          ? item.assets[0].url
          : "https://cdn4.iconfinder.com/data/icons/eldorado-documents/40/html-512.png";
      return [
        <div key={`AdList1:${index}`} className={classes.imgContainer}>
          <img src={image} alt="..." className={classes.img} />
        </div>,
        <span key={`AdList2:${index}`}>
          <a href="#jacket" className={classes.tdNameAnchor}>
            {item.title}
          </a>
          <br />
          <small className={classes.tdNameSmall}>{item.description}</small>
        </span>,
        item.type,
        <a
          key={`AdList3:${index}`}
          href={item.url}
          target="_blank"
          rel="noreferrer"
        >
          {item.url}
        </a>,
        <React.Fragment key={`AdList4:${index}`}>
          <Button
            onClick={() => this.props.onEdit(item.uuid)}
            color="info"
            round
          >
            Edit
            <KeyboardArrowRight className={classes.icon} />
          </Button>
          <Button onClick={() => this.onDelete(item.uuid)} color="danger" round>
            <Close />
          </Button>
        </React.Fragment>,
      ];
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {this.renderDialogModal()}
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <Table
                  tableHead={["", "Name", "Ad Type", "Url", ""]}
                  tableData={this.renderRows(classes)}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(extendedTablesStyle)(AdList);
