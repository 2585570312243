import { takeLatest, put, call } from "redux-saga/effects";
import AbTestActions, { AbTestTypes } from "../modules/abtest";

export function* getAbTests(api) {
  try {
    const response = yield call(api.getAbTests);
    yield put(AbTestActions.getAbTestsSuccess(response.data.ab_tests));
  } catch (e) {
    yield put(AbTestActions.getAbTestsFailure(e));
  }
}

export function* watchAbTest(api) {
  yield takeLatest(AbTestTypes.GET_AB_TESTS, getAbTests, api);
}
