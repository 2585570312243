import { takeLatest, put, call } from "redux-saga/effects";
import SettingsActions, { SettingsTypes } from "../modules/settings";

export function* fetchAppSettings(api, { application_uuid }) {
  try {
    const response = yield call(api.fetchSettings, application_uuid);
    yield put(SettingsActions.fetchAppSettingsSuccess(response.data));
  } catch (e) {
    yield put(SettingsActions.fetchAppSettingsFailure(e));
  }
}

export function* fetchGlobalSettings(api) {
  try {
    const response = yield call(api.fetchSettings, "global");
    yield put(SettingsActions.fetchGlobalSettingsSuccess(response.data));
  } catch (e) {
    yield put(SettingsActions.fetchGlobalSettingsFailure(e));
  }
}

export function* updateAppSettings(api, { application_uuid, data }) {
  try {
    const response = yield call(api.updateSettings, application_uuid, data);
    yield put(SettingsActions.updateAppSettingsSuccess(response.data));
  } catch (e) {
    yield put(SettingsActions.updateAppSettingsFailure(e));
  }
}

export function* updateGlobalSettings(api, { data }) {
  try {
    const response = yield call(api.updateSettings, "global", data);
    yield put(SettingsActions.updateGlobalSettingsSuccess(response.data));
  } catch (e) {
    yield put(SettingsActions.updateGlobalSettingsFailure(e));
  }
}

export function* watchSettings(api) {
  yield takeLatest(SettingsTypes.FETCH_APP_SETTINGS, fetchAppSettings, api);
  yield takeLatest(
    SettingsTypes.FETCH_GLOBAL_SETTINGS,
    fetchGlobalSettings,
    api
  );
  yield takeLatest(SettingsTypes.UPDATE_APP_SETTINGS, updateAppSettings, api);
  yield takeLatest(
    SettingsTypes.UPDATE_GLOBAL_SETTINGS,
    updateGlobalSettings,
    api
  );
}
