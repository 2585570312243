import { takeLatest, put, call } from "redux-saga/effects";
import AdActions, { AdTypes } from "../modules/ads";

export function* getAds(api, { campaign_uuid }) {
  try {
    const response = yield call(api.getAds, campaign_uuid);
    yield put(AdActions.getAdsSuccess(response.data.ads));
  } catch (e) {
    yield put(AdActions.getAdsFailure(e));
  }
}

export function* updateAd(api, { ad_uuid, data }) {
  try {
    const response = yield call(api.updateAd, ad_uuid, data);
    yield put(AdActions.updateAdSuccess(response.data.ad));
  } catch (e) {
    yield put(AdActions.updateAdFailure(e));
  }
}

export function* createAd(api, { campaign_uuid, data }) {
  try {
    const response = yield call(api.createAd, campaign_uuid, data);
    yield put(AdActions.createAdSuccess(response.data.ad));
  } catch (e) {
    yield put(AdActions.createAdFailure(e));
  }
}

export function* getAd(api, { ad_uuid }) {
  try {
    const response = yield call(api.getAd, ad_uuid);
    yield put(AdActions.getAdSuccess(response.data.ad));
  } catch (e) {
    yield put(AdActions.getAdFailure(e));
  }
}

export function* deleteAd(api, { ad_uuid }) {
  try {
    yield put(AdActions.deleteAdSuccess(ad_uuid));
  } catch (e) {
    yield put(AdActions.deleteAdFailure(e));
  }
}

export function* watchAds(api) {
  yield takeLatest(AdTypes.GET_ADS, getAds, api);
  yield takeLatest(AdTypes.UPDATE_AD, updateAd, api);
  yield takeLatest(AdTypes.CREATE_AD, createAd, api);
  yield takeLatest(AdTypes.GET_AD, getAd, api);
  yield takeLatest(AdTypes.DELETE_AD, deleteAd, api);
}
