import { Modal, Grid, Card, FormLabel, MenuItem } from "@material-ui/core";
import { Assignment } from "@material-ui/icons";
import CardBody from "components/core/Card/CardBody";
import CardHeader from "components/core/Card/CardHeader";
import CardIcon from "components/core/Card/CardIcon";
import BooleanControl from "components/settings/BooleanControl";
import StringControl from "components/settings/StringControl";
import Button from "components/core/CustomButtons/Button.js";
import React, { useState, useEffect } from "react";
import { Field, Form } from "react-final-form";

import Select from "@material-ui/core/Select";

export const NetworkSettingsModal = ({
  classes,
  item,
  modalOpen,
  handleModalClose,
  onSubmit,
  sdkNetworks,
}) => {
  const [selectedSdk, setSelectedSdk] = useState(
    item?.networkUuid ?? sdkNetworks[0].uuid
  );
  const [params, setParams] = useState(
    sdkNetworks
      .find((network) => network.uuid == selectedSdk)
      ?.appParamsSchema?.parameters?.reduce((acc, curr) => {
        // support only string type for now
        acc[curr.name] = item?.params[curr.name] ?? "";
        return acc;
      }, {}) ?? {}
  );

  useEffect(() => {
    setParams(
      sdkNetworks
        .find((network) => network.uuid == selectedSdk)
        ?.appParamsSchema?.parameters?.reduce((acc, curr) => {
          // support only string type for now
          acc[curr.name] = item?.params[curr.name] ?? "";
          return acc;
        }, {}) ?? {}
    );
  }, [selectedSdk, item]);

  useEffect(() => {
    if (item && modalOpen) {
      setSelectedSdk(item.networkUuid);
    }
    if (!modalOpen) {
      setSelectedSdk(sdkNetworks[0]);
      setParams({});
    }
  }, [item, modalOpen]);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={modalOpen}
      onClose={handleModalClose}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ padding: 20 }}
      >
        <Grid item md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {item != null && item != ""
                  ? "Edit current Network Setting"
                  : "Add new Network Setting"}
              </h4>
            </CardHeader>
            <CardBody>
              <Form onSubmit={onSubmit} initialValues={item ? item : {}}>
                {({ handleSubmit }) => (
                  <Grid
                    style={{ marginLeft: 20 }}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={12} md={3}>
                      <FormLabel>SDK Partner</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Field
                        name="networkUuid"
                        validate={(value) => {
                          if (
                            typeof value === "string" ||
                            value instanceof String
                          ) {
                            return undefined;
                          } else return "Please select a Sdk Partner";
                        }}
                      >
                        {({ input: Input, meta }) => {
                          return (
                            <>
                              <Select
                                value={Input.value}
                                disabled={item?._id ? true : undefined}
                                onChange={(e) => {
                                  setSelectedSdk(e.target.value);
                                  Input.onChange(e);
                                }}
                              >
                                {sdkNetworks.map((network) => {
                                  return (
                                    <MenuItem
                                      value={network.uuid}
                                      key={network.uuid}
                                    >
                                      {network.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {meta.error && meta.touched && (
                                <span className={classes.errorSpan}>
                                  {meta.error}
                                </span>
                              )}
                            </>
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <FormLabel>Enabled</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Field name="enabled" defaultValue={true}>
                        {({ input: Input }) => {
                          return (
                            <>
                              <BooleanControl
                                value={Input.value}
                                onChange={Input.onChange}
                              />
                            </>
                          );
                        }}
                      </Field>
                    </Grid>
                    {Object.keys(params).map((key) => (
                      <>
                        <Grid item xs={12} sm={12} md={3}>
                          <FormLabel>{key}</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                          <Field
                            name={`params.${key}`}
                            defaultValue=""
                            parse={(a) => a}
                          >
                            {({ input: Input }) => {
                              return (
                                <>
                                  <StringControl
                                    required={true}
                                    value={Input.value}
                                    onChange={Input.onChange}
                                  />
                                </>
                              );
                            }}
                          </Field>
                        </Grid>
                      </>
                    ))}
                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      style={{}}
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <Button
                          onClick={handleModalClose}
                          color="rose"
                          className={classes.marginRight}
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleSubmit}
                          color="primary"
                          className={classes.marginRight}
                        >
                          {item != null && item != "" ? "Edit" : "Add"}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Form>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
};
