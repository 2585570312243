import React from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Error from "@material-ui/icons/Error";

// core components
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardHeader from "components/core/Card/CardHeader.js";
import history from "../../services/history";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

class ResourceNotFound extends React.Component {
  state = {
    open: true,
  };
  getErrorMessage = () => {
    return (
      "ERROR 404: Resource was not found for the given url " +
      (this.props.url ? this.props.url : "")
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{ minHeight: 400 }}>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Error />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>404 Page not found</h3>
            </CardHeader>
            <CardBody>
              <GridItem xs={12}>
                <label>The given url was not found in the platform</label>
              </GridItem>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Grid container direction="column" alignItems="flex-end">
            <Grid item>
              <Button
                onClick={() => history.push("/ad-mix/create")}
                justIcon
                round
                color="success"
              >
                <Error />
              </Button>
            </Grid>
          </Grid>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(extendedTablesStyle)(ResourceNotFound);
