import React, { useEffect, useRef } from "react";
import nouislider from "nouislider";
import "./CommonStyle.css";

const SliderBar = ({
  start = [0, 10],
  step = 1,
  range = { min: 0, max: 24 },
  connect = true,
  tooltips = true,
  behaviour = "drag",
  formatFrom = function (val) {
    return val;
  },
  formatTo = function (val) {
    return parseInt(val, 10);
  },
  onEndHandler,
}) => {
  const sliderContainer = useRef();

  useEffect(() => {
    let slider = nouislider.create(sliderContainer.current, {
      start,
      step,
      range,
      connect,
      tooltips,
      behaviour,
      format: {
        to: formatTo,
        from: formatFrom,
      },
    });

    if (onEndHandler) slider.on("end", onEndHandler);
  }, []);

  return <div className="slider-primary" ref={sliderContainer} />;
};

export default SliderBar;
