import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  updateMessage: ["setting_uuid", "data"],
  updateMessageSuccess: ["updated"],
  updateMessageFailure: ["error"],

  createMessage: ["setting_uuid", "data", "redirect"],
  createMessageSuccess: ["created"],
  createMessageFailure: ["error"],

  deleteMessage: ["msg_uuid"],
  deleteMessageSuccess: ["msg_uuid"],
  deleteMessageFailure: ["error"],

  getMessage: ["setting_uuid"],
  getMessageSuccess: ["fetched"],
  getMessageFailure: ["error"],
});

export const MsgTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  items: [],
  updated: null,
  created: null,
  fetched: null,
  deleted: null,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_MESSAGE]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      updated: null,
    };
  },
  [Types.UPDATE_MESSAGE_SUCCESS]: (state, { updated }) => {
    return {
      ...state,
      loading: false,
      updated,
      error: null,
    };
  },
  [Types.UPDATE_MESSAGE_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      updated: null,
    };
  },
  [Types.CREATE_MESSAGE]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      created: null,
    };
  },
  [Types.CREATE_MESSAGE_SUCCESS]: (state, { created }) => {
    return {
      ...state,
      loading: false,
      created,
      fetched: created,
      error: null,
    };
  },
  [Types.CREATE_MESSAGE_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      created: null,
    };
  },
  // Delete ad mix
  [Types.DELETE_MESSAGE]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      deleted: null,
    };
  },
  [Types.DELETE_MESSAGE_SUCCESS]: (state, { ad_uuid }) => {
    return {
      ...state,
      loading: false,
      deleted: ad_uuid,
      error: null,
    };
  },
  [Types.DELETE_MESSAGE_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      deleted: null,
    };
  },

  [Types.GET_MESSAGE]: (state) => {
    return {
      ...state,
      fetched: null,
      loading: true,
      error: null,
    };
  },
  [Types.GET_MESSAGE_SUCCESS]: (state, { fetched }) => {
    return {
      ...state,
      loading: false,
      fetched: fetched,
      error: null,
    };
  },
  [Types.GET_MESSAGE_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      fetched: null,
    };
  },
});
