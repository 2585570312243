import { takeLatest, put, call } from "redux-saga/effects";
import AssetActions, { AssetTypes } from "../modules/asset";

export function* uploadFile(api, { file }) {
  try {
    const response = yield call(api.getSignedUrl);
    const { url, key } = response.data;
    yield call(api.uploadFileToS3, url, file);
    yield put(AssetActions.uploadFileSuccess(key, file.name, file.type));
  } catch (e) {
    yield put(AssetActions.uploadFileFailure(e));
  }
}

export function* watchAsset(api) {
  yield takeLatest(AssetTypes.UPLOAD_FILE, uploadFile, api);
}
