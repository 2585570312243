import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PlacementActions from "../../redux/modules/placementgroups";
import PlacementGroupCreate from "../../components/placements/PlacementGroupCreate";
import Session from "../../services/Session";

const PlacementGroupCreateContainer = ({
  match: {
    params: { placement },
  },
  actions,
  placementgroup,
}) => {
  const application = Session.getApplication();
  useEffect(() => {
    // Avoid invalid state if the page was loaded without having selected an ad mix group
    // if (!groupUuid) {
    //   history.push("/admin/application/ad-mix-group");
    // }
  }, []);

  return (
    <PlacementGroupCreate
      application={application}
      placement={placement}
      onSubmit={actions.placement.insertPlacementGroup}
      error={placementgroup.error}
    />
  );
};

const mapStateToProps = ({ placementgroup }) => ({
  placementgroup,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    placement: bindActionCreators(PlacementActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlacementGroupCreateContainer);
