import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CampaignActions from "../../redux/modules/campaign";
import ApplicationActions from "../../redux/modules/application";
import Session from "../../services/Session";
import CampaignCardListComponent from "../../components/campaign/CampaignCardListComponent";
import validateUrl from "../ValidateApplicationUrl";

class CampaignCardListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      application: Session.getApplication(),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.application.updated &&
      nextProps.application.updated.in_house_percentage !==
        prevState.application.in_house_percentage
    ) {
      return {
        application: nextProps.application.updated,
      };
    }
    return prevState;
  }

  componentDidMount() {
    validateUrl(
      this.props.match.params.slug,
      this.props.actions.application.selectApplication
    );
    this.props.actions.campaign.getCampaigns(this.state.application.uuid);
  }

  refreshItems = () => {
    this.props.actions.campaign.getCampaigns(this.state.application.uuid);
  };

  render() {
    return (
      <CampaignCardListComponent
        items={this.props.campaign.items}
        application={this.state.application}
        onUpdateCampaign={this.props.actions.campaign.updateCampaign}
        onDeleteCampaign={this.props.actions.campaign.deleteCampaign}
        onRefresh={this.refreshItems}
        onUpdateApplication={this.props.actions.application.updateApplication}
      />
    );
  }
}

const mapStateToProps = ({ campaign, application }) => ({
  campaign,
  application,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    campaign: bindActionCreators(CampaignActions, dispatch),
    application: bindActionCreators(ApplicationActions, dispatch),
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignCardListContainer);
