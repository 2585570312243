import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Add from "@material-ui/icons/Add";

// core components
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import Button from "components/core/CustomButtons/Button.js";
import CampaignCardComponent from "./CampaignCardComponent";
import history from "../../services/history.js";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import Session from "./../../services/Session";
import SliderBar from "components/common/SliderBar";
import DeleteCampaignModal from "./DeleteCampaignModal";

class CampaignCardListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inHousePercentage: this.props.application.in_house_percentage,
      thirdPartyPercentage: 100 - this.props.application.in_house_percentage,
      dialogModalOpen: false,
      onDeleteAction: null,
      items: this.props.items,
      selectedApplication: Session.get(Session.SELECTED_APPLICATION_KEY),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.application.in_house_percentage !== prevState.inHousePercentage
    ) {
      return {
        inHousePercentage: nextProps.application.in_house_percentage,
        thirdPartyPercentage: 100 - nextProps.application.in_house_percentage,
      };
    }
    if (nextProps.items.length !== prevState.items.length) {
      return {
        items: nextProps.items,
        dialogModalOpen: false,
        onDeleteAction: null,
      };
    }
    return prevState;
  }

  sliderOnEndHandler = (values) => {
    this.setState(
      {
        inHousePercentage: values[0],
        thirdPartyPercentage: 1 - parseInt(values[0]),
      },
      () => {
        this.props.onUpdateApplication(this.props.application.uuid, {
          in_house_percentage: values[0],
        });
      }
    );
  };

  handleDialogClose = () => {
    this.setState({ dialogModalOpen: false });
  };

  onDeleteCampaign = (campaignUuid) => {
    const onDeleteAction = () =>
      this.props.onDeleteCampaign(this.props.application.uuid, campaignUuid);
    this.setState({ dialogModalOpen: true, onDeleteAction });
  };

  render() {
    return (
      <React.Fragment>
        <DeleteCampaignModal
          classes={this.props.classes}
          dialogModalOpen={this.state.dialogModalOpen}
          handleDialogClose={this.handleDialogClose}
          onDeleteAction={this.state.onDeleteAction}
        />
        <Grid container direction="column" justifyContent="center" spacing={16}>
          {this.props.application.in_house_percentage !== null ? (
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2}>
                          Ad source
                        </GridItem>
                        <GridItem xs={12} sm={12} md={7}>
                          <label>
                            Where do you want your source of ads to come from?
                          </label>
                          <br />
                          <br />
                          <br />
                          <br />
                          <SliderBar
                            start={[this.state.inHousePercentage]}
                            range={{ min: 0, max: 100 }}
                            formatTo={function (value) {
                              return parseInt(value);
                            }}
                            formatFrom={function (value) {
                              return parseInt(value);
                            }}
                            onEndHandler={this.sliderOnEndHandler}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            spacing={16}
                          >
                            <Grid item>
                              In House Ads: {this.state.inHousePercentage}%
                            </Grid>
                            <Grid item>
                              Third Party Ads: {this.state.thirdPartyPercentage}
                              %
                            </Grid>
                          </Grid>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </GridItem>
          ) : null}
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              {this.state.items.map((item, index) => {
                return (
                  <GridItem
                    key={`CampaignCardListComponent:${index}`}
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: 20 }}
                  >
                    <CampaignCardComponent
                      campaign={item}
                      onDelete={this.onDeleteCampaign}
                    />
                  </GridItem>
                );
              })}
            </GridContainer>
          </GridItem>
          <Grid container direction="column" alignItems="flex-end">
            <Grid item>
              <Button size="lg" justIcon round color="success">
                <Add
                  onClick={() =>
                    history.push(
                      `/admin/a/${this.state.selectedApplication.slug}/campaign/create`
                    )
                  }
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(extendedTablesStyle)(CampaignCardListComponent);
