import React, { useEffect, useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import Table from "components/core/Table/Table.js";
import Pagination from "components/core/Pagination/Pagination";
import Session from "services/Session";

import * as moment from "moment-timezone";

const AuditLogsTable = ({ filters }) => {
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const auditApiUrl = new URL(
    "/internal/audit/v1",
    process.env.REACT_APP_AIRFIND_API_URL
  );
  auditApiUrl.searchParams.set("limit", 10);
  auditApiUrl.searchParams.set("page", currentPage);
  auditApiUrl.searchParams.set(
    "clientId",
    Session.get(Session.SELECTED_APPLICATION_KEY).uuid
  );
  auditApiUrl.searchParams.set("apiKey", process.env.REACT_APP_API_KEY);

  for (const key in filters) {
    if (Object.hasOwnProperty.call(filters, key)) {
      const value = filters[key];
      auditApiUrl.searchParams.set(key, value);
    }
  }

  useEffect(() => {
    fetch(auditApiUrl.toString())
      .then((res) => res.json())
      .then(setData);
  }, [currentPage]);

  if (!data) return null;

  const tableData =
    data.result?.map((row) => {
      return [moment.utc(row.date).format("llll"), row.userId, row.message];
    }) || [];

  return (
    <>
      <GridContainer>
        <GridItem xs={11}>
          <Table
            tableHead={["Time", "User", "Description"]}
            tableData={tableData}
          />
        </GridItem>

        <GridItem xs={11}>
          <Pagination
            pages={[
              {
                active: false,
                disabled: currentPage === 1,
                text: "prev",
                onClick: () => {
                  if (currentPage === 1) return;
                  setCurrentPage((curr) => curr - 1);
                },
              },
              ...Array.from({ length: data.totalPages }).map((_, i) => {
                const page = i + 1;
                return {
                  active: page == currentPage,
                  disabled: false,
                  text: page,
                  onClick: () => {
                    setCurrentPage(page);
                  },
                };
              }),
              {
                active: false,
                disabled: currentPage === data.totalPages,
                text: "next",
                onClick: () => {
                  if (currentPage === data.totalPages) return;
                  setCurrentPage((curr) => curr + 1);
                },
              },
            ]}
          ></Pagination>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default withStyles(extendedTablesStyle)(AuditLogsTable);
