import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getGroups: ["application_uuid"],
  getGroupsSuccess: ["items"],
  getGroupsFailure: ["error"],

  selectGroup: ["uuid"],
  selectGroupSuccess: ["selected"],
  selectGroupFailure: ["error"],
});

export const GroupTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  items: [],
  selected: null,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_GROUPS]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      items: [],
    };
  },
  [Types.GET_GROUPS_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      items: action.items,
      error: null,
    };
  },
  [Types.GET_GROUPS_FAILURE]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      items: [],
    };
  },

  // Select a group
  [Types.SELECT_GROUP]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      selected: null,
    };
  },
  [Types.SELECT_GROUP_SUCCESS]: (state, { selected }) => {
    return {
      ...state,
      loading: false,
      error: null,
      selected,
    };
  },
  [Types.SELECT_GROUP_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      selected: null,
    };
  },
});
