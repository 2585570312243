import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/core/CustomButtons/Button.js";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import SystemMessageForm from "./SystemMessageForm";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ApplicationActions from "../../redux/modules/application";
import MessageActions from "../../redux/modules/messages";
import AssetActions from "../../redux/modules/asset";

class ExtraControl extends React.Component {
  state = {
    uuid: this.props.uuid ? this.props.uuid : {},
    value: this.props.value ? this.props.value : {},
    showModal: false,
  };

  componentDidMount = () => {
    this.props.actions.messages.getMessage(this.state.uuid);
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  onCreateMessage = (message) => {
    this.props.actions.messages.createMessage(this.state.uuid, message);
  };

  onUpdateMessage = (message) => {
    this.props.actions.messages.updateMessage(this.state.uuid, message);
  };

  onFetched = (fetchedUuid) => {
    this.setState({ value: fetchedUuid }, () => {
      this.props.onChange(fetchedUuid);
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {/* <SystemMessageForm
          visible={this.state.showModal}
          onClose={this.handleCloseModal}
          onUploadFile={this.props.actions.asset.uploadFile}
          assetKey={this.props.asset.key}
          onCreateMessage={this.onCreateMessage}
          onUpdateMessage={this.onUpdateMessage}
          error={this.props.messages.error}
          created={this.props.messages.created}
          updated={this.props.messages.updated}
          fetched={this.props.messages.fetched}
          onFetched={this.onFetched}
        /> */}
        <Button
          onClick={() => {
            this.setState({ showModal: true });
          }}
          color="primary"
          className={classes.marginRight}
        >
          {this.props.messages.fetched == null ? "Message" : "Change Message"}
        </Button>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ asset, messages }) => ({
  asset,
  messages,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    asset: bindActionCreators(AssetActions, dispatch),
    messages: bindActionCreators(MessageActions, dispatch),
    application: bindActionCreators(ApplicationActions, dispatch),
  },
});

const extraControl = connect(mapStateToProps, mapDispatchToProps)(ExtraControl);

export default withStyles(extendedTablesStyle)(extraControl);
