import React from "react";
import PropTypes from "prop-types";

// import { useDispatch } from "react-redux";

// import AuthActions from "redux/modules/auth"

// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../components/core/Grid/GridContainer.js";
import GridItem from "../../components/core/Grid/GridItem.js";
import CustomInput from "../../components/core/CustomInput/CustomInput.js";
import Button from "../../components/core/CustomButtons/Button.js";
import Card from "../../components/core/Card/Card.js";
import CardBody from "../../components/core/Card/CardBody.js";
import CardHeader from "../../components/core/Card/CardHeader.js";
import CardFooter from "../../components/core/Card/CardFooter.js";
import SnackbarContent from "../../components/core/Snackbar/SnackbarContent.js";

import { Form, Field } from "react-final-form";

import loginPageStyle from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import history from "../../services/history";

// const useStyles = makeStyles(loginPageStyle);

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimaton: "cardHidden",
      disabledLogin: false,
      username: null,
      password: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (localStorage.getItem("access_token")) {
      history.push("/");
    }
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  validate() {
    return undefined;
  }
  onSubmit(values) {
    try {
      const { username, password } = values;
      this.props.onLogin(username, password);
    } catch (error) {
      console.error(error.message);
    }
  }
  onValidate() {
    return undefined;
  }
  getErrorState(field) {
    return this.props.error && this.props.error.hasError(field);
  }
  onChange(field, value) {
    let state = { ...this.state };
    state[field] = value;
    state.disabledLogin = !state.username || !state.password;
    this.setState(state);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={6} md={4}>
            <Form onSubmit={this.onSubmit}>
              {({ handleSubmit }) => (
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="primary"
                  >
                    <h4 className={classes.cardTitle}>Log in</h4>
                  </CardHeader>
                  <CardBody>
                    {this.props.error ? (
                      <SnackbarContent
                        message="Email or Password is incorrect. Please try again."
                        color="warning"
                      />
                    ) : null}
                    <Field name="username" validate={this.validate}>
                      {({ input: Input }) => (
                        <CustomInput
                          error={this.getErrorState("username")}
                          labelText="Email"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: (e) => {
                              Input.onChange(e.target.value);
                              this.onChange("username", e.target.value);
                            },
                          }}
                        />
                      )}
                    </Field>
                    <Field name="password" validate={this.validate}>
                      {({ input: Input }) => (
                        <CustomInput
                          error={this.getErrorState("password")}
                          labelText="Password"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: (e) => {
                              Input.onChange(e.target.value);
                              this.onChange("password", e.target.value);
                            },
                            type: "password",
                          }}
                        />
                      )}
                    </Field>
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button
                      disabled={this.state.disabledLogin}
                      color="rose"
                      size="lg"
                      simple
                      block
                      onClick={handleSubmit}
                    >
                      Login
                    </Button>
                  </CardFooter>
                </Card>
              )}
            </Form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(loginPageStyle)(Login);
