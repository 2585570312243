import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardHeader from "components/core/Card/CardHeader.js";
import Button from "components/core/CustomButtons/Button.js";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { Field, Form } from "react-final-form";
import { FormLabel, Grid } from "@material-ui/core";
import IntegerControl from "components/settings/IntegerControl";
import CustomAccordion from "components/core/Accordion/Accordion";
import AuditLogsTable from "components/auditlogs/AuditLogsTable";

const GlobalSettings = ({ classes, onSubmit, onCancel, formData }) => {
  const validate = (min, max) => (value) => {
    if (isNaN(value) || (value >= min && value <= max)) {
      return undefined;
    } else {
      return `Should be between ${min} and ${max}`;
    }
  };

  return (
    <GridContainer style={{ paddingBottom: 200 }}>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <h4 className={classes.cardIconTitle}>Global Ad Configurations</h4>
          </CardHeader>
          <CardBody>
            <Form onSubmit={onSubmit} initialValues={formData}>
              {({ handleSubmit }) => (
                <Grid
                  style={{ marginLeft: 20 }}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={2}>
                    <FormLabel>Cycle Ad Count</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10}>
                    <Field name="cycleAdCount" validate={validate(1, 3600)}>
                      {({ input: Input, meta }) => {
                        return (
                          <>
                            <IntegerControl
                              value={Input.value}
                              onChange={Input.onChange}
                            />
                            {meta.error && (
                              <span className={classes.errorSpan}>
                                {meta.error}
                              </span>
                            )}
                          </>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <FormLabel>Ad Minimum Lifetime</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10}>
                    <Field
                      name="adMinimumLifetime"
                      validate={validate(1, 36000)}
                    >
                      {({ input: Input, meta }) => {
                        return (
                          <>
                            <IntegerControl
                              value={Input.value}
                              onChange={Input.onChange}
                            />
                            {meta.error && (
                              <span className={classes.errorSpan}>
                                {meta.error}
                              </span>
                            )}
                          </>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <FormLabel>Maximum Ad Queue</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10}>
                    <Field name="maximumAdQueue" validate={validate(0, 20)}>
                      {({ input: Input, meta }) => {
                        return (
                          <>
                            <IntegerControl
                              value={Input.value}
                              onChange={Input.onChange}
                            />
                            {meta.error && (
                              <span className={classes.errorSpan}>
                                {meta.error}
                              </span>
                            )}
                          </>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <FormLabel>Maximum Wait For Ad</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10}>
                    <Field name="maximumWaitForAd" validate={validate(1, 360)}>
                      {({ input: Input, meta }) => {
                        return (
                          <>
                            <IntegerControl
                              value={Input.value}
                              onChange={Input.onChange}
                            />
                            {meta.error && (
                              <span className={classes.errorSpan}>
                                {meta.error}
                              </span>
                            )}
                          </>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      color="rose"
                      className={classes.marginRight}
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      color="primary"
                      className={classes.marginRight}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Form>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <h4 className={classes.cardIconTitle}>Audit Logs</h4>
          </CardHeader>
          <CardBody>
            <CustomAccordion
              collapses={[
                {
                  title: "Show/Hide",
                  content: (
                    <AuditLogsTable
                      filters={{
                        domain: "Admixes",
                        clientId: "global",
                      }}
                    />
                  ),
                },
              ]}
            ></CustomAccordion>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(extendedTablesStyle)(GlobalSettings);
