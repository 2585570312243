// import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, applyMiddleware, createStore } from "redux";
import loggerMiddleware from "redux-logger";
import createSagaMiddleware from "redux-saga";
import appSagas from "./sagas";
import { reducer as adsReducer } from "./modules/ads";
import { reducer as authReducer } from "./modules/auth";
import { reducer as applicationReducer } from "./modules/application";
import { reducer as admixReducer } from "./modules/admix";
import { reducer as abTestReducer } from "./modules/abtest";
import { reducer as networkReducer } from "./modules/network";
import { reducer as admixNetworkReducer } from "./modules/admix/network";
import { reducer as settingReducer } from "./modules/setting";
import { reducer as messagesReducer } from "./modules/messages";
import { reducer as admixgroupReducer } from "./modules/admixgroup";
import { reducer as campaignReducer } from "./modules/campaign";
import { reducer as assetReducer } from "./modules/asset";
import { reducer as admixplacementsReducer } from "./modules/admixplacements";
import { reducer as placementGroupReducer } from "./modules/placementgroups";
import { reducer as settingsReducer } from "./modules/settings";

export default ({ api, config }) => {
  let middlewares = [];
  const sagaMiddleware = createSagaMiddleware();
  middlewares.push(sagaMiddleware);
  middlewares.push(loggerMiddleware);

  const reducer = combineReducers({
    ads: adsReducer,
    auth: authReducer,
    application: applicationReducer,
    admix: admixReducer,
    abtest: abTestReducer,
    network: networkReducer,
    admix_network: admixNetworkReducer,
    setting: settingReducer,
    admixgroup: admixgroupReducer,
    campaign: campaignReducer,
    asset: assetReducer,
    messages: messagesReducer,
    admixplacements: admixplacementsReducer,
    placementgroup: placementGroupReducer,
    settings: settingsReducer,
  });

  const store = createStore(reducer, applyMiddleware(...middlewares));

  sagaMiddleware.run(appSagas, { api, config });

  return store;
};
