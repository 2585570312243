import React, { useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Assignment from "@material-ui/icons/Assignment";

import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";

// core components
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardHeader from "components/core/Card/CardHeader.js";
import SnackbarContent from "components/core/Snackbar/SnackbarContent.js";

import history from "../../services/history";
import StringControl from "../settings/StringControl";

import { Form, Field } from "react-final-form";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const AdMixCreate = ({ onCreate, group, error, classes, placement }) => {
  const [displayStatus, setDisplayStatus] = useState(false);

  const onSubmit = (values) => {
    setDisplayStatus(true);
    onCreate(placement, group, values, {
      url: `/admin/placement/${placement}/${group}/ad-mix/:uuid/details`,
    });
  };

  const renderStatusMessage = () => {
    if (displayStatus) {
      if (error) {
        document
          .querySelector("#dashboardMainPanel")
          .scrollTo({ top: 0, behavior: "smooth" });
      }
      if (error) {
        setTimeout(() => {
          setDisplayStatus(false);
        }, 3000);
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={6}>
              <SnackbarContent message={error.getMessage()} color="danger" />
            </Grid>
          </Grid>
        );
      }
    }
  };

  return (
    <GridContainer>
      {renderStatusMessage()}
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>New Ad Mix</h4>
          </CardHeader>
          <CardBody>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit }) => (
                <Grid
                  style={{ marginLeft: 20 }}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={2}>
                    <FormLabel>Name</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10}>
                    <Field name="name">
                      {({ input: Input }) => {
                        return (
                          <StringControl
                            value={Input.value}
                            onChange={Input.onChange}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <FormLabel>Description</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10}>
                    <Field name="description">
                      {({ input: Input }) => {
                        return (
                          <StringControl
                            value={Input.value}
                            onChange={Input.onChange}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      onClick={() =>
                        history.push(
                          `/admin/application/placement/${placement}`
                        )
                      }
                      color="rose"
                      className={classes.marginRight}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      color="primary"
                      className={classes.marginRight}
                    >
                      Save Ad Mix
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(extendedTablesStyle)(AdMixCreate);
