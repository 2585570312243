import axios from "axios";
import HttpError from "./api/HttpError";
import Session from "./Session";

const headers = {
  "Content-Type": "application/json",
};

const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: process.env.REACT_APP_API_TIMEOUT,
  headers,
  reportingApiURL: process.env.REACT_APP_REPORTING_API_URL,
});

request.interceptors.response.use(
  (response) => response.data,
  (error) => {
    return Promise.reject(new HttpError(error.response));
  }
);

request.interceptors.request.use(
  (request) => {
    const token = Session.get(Session.ACCESS_TOKEN_KEY);
    if (token) {
      request.headers = { ...request.headers, "x-access-token": token };
    }
    return request;
  },
  (error) => {
    return Promise.reject(new HttpError(error.response));
  }
);

export default request;
