import React from "react";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";

// core components
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardHeader from "components/core/Card/CardHeader.js";

const AdMixDeleteModal = ({
  classes,
  dialogModalOpen,
  dialogConfirmAction,
  handleDialogClose,
}) => {
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={dialogModalOpen}
      onClose={handleDialogClose}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Delete Ad Mix</h4>
            </CardHeader>
            <CardBody>
              <Grid
                style={{ marginLeft: 20 }}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <h3>This will immediately remove the ad mix from the list</h3>
                </Grid>
                <Grid container direction="column" alignItems="flex-end">
                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      onClick={handleDialogClose}
                      color="rose"
                      className={classes.marginRight}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={dialogConfirmAction}
                      color="primary"
                      className={classes.marginRight}
                    >
                      Delete!
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AdMixDeleteModal;
