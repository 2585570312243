import React from "react";

// material-ui icons
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
// core components
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardHeader from "components/core/Card/CardHeader.js";
import CardFooter from "components/core/Card/CardFooter.js";
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import history from "../../services/history.js";
import priceImage1 from "../../assets/img/card-2.jpeg";
import Session from "./../../services/Session";

const WEEKDAYS = {
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  7: "Sun",
};
class CampaignCardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDelete: false,
      selectedApplication: Session.get(Session.SELECTED_APPLICATION_KEY),
    };
  }

  formatDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
    });
  };

  renderSchedule = () => {
    const {
      campaign: { schedules },
    } = this.props;
    if (schedules.length > 0) {
      const days = schedules[0].weekday.map((day) => WEEKDAYS[day]).join(", ");
      let time = null;
      if (
        schedules[0].start_time != "00:00:00" &&
        schedules[0].end_time != "24:00:00"
      ) {
        const startParts = schedules[0].start_time.split(":");
        const endParts = schedules[0].end_time.split(":");
        const startHour = parseInt(startParts[0]);
        const endHour = parseInt(endParts[0]);
        const startType = startHour < 12 ? "AM" : "PM";
        const endType = endHour < 12 ? "AM" : "PM";
        time = (
          <GridItem xs={12} sm={12} md={12}>
            {startHour + ":" + startParts[1] + " " + startType} -{" "}
            {endHour + ":" + endParts[1] + " " + endType}
          </GridItem>
        );
      }
      return (
        <React.Fragment>
          <GridItem xs={12} sm={12} md={12}>
            {days}
          </GridItem>
          {time}
        </React.Fragment>
      );
    }
  };

  renderDemographic = () => {
    const { campaign } = this.props;
    if (campaign.demographics.length > 0) {
      const demographics = campaign.demographics[0];
      let gender = "Any Gender";
      const mapGenders = demographics.gender
        .map((gender) => gender.charAt(0).toUpperCase() + gender.slice(1))
        .join(" or ");
      if (demographics.gender.length !== 3) {
        gender = mapGenders;
      }

      let ages = "All Ages";
      if (demographics.min_age && demographics.max_age) {
        ages = demographics.min_age + "-" + demographics.max_age;
      } else if (demographics.min_age && !demographics.max_age) {
        ages = "Over " + demographics.min_age;
      } else if (!demographics.min_age && demographics.max_age) {
        ages = "Under " + demographics.max_age;
      }
      return (
        <GridItem xs={12} sm={12} md={12}>
          {gender}, {ages}
        </GridItem>
      );
    }
  };

  render() {
    const {
      campaign: { image, name, uuid, start_dt, end_dt, created_dt },
      onDelete,
      classes,
    } = this.props;
    const imageUrl = !image ? priceImage1 : image;
    const gridStyle = {
      marginTop: 10,
    };

    return (
      <Card product className={classes.cardHover}>
        <CardHeader
          image
          className={classes.cardHeaderHover}
          style={{ textAlign: "center" }}
        >
          <a href="#pablo" onClick={(e) => e.preventDefault()}>
            <div
              style={{
                width: "100%",
                height: 250,
                paddingLeft: 30,
                paddingRight: 30,
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${imageUrl})`,
                  backgroundSize: "cover",
                  height: 250,
                  width: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "50%",
                  borderRadius: "10px",
                }}
              ></div>
            </div>
          </a>
        </CardHeader>
        <CardBody style={{ minHeight: 210 }}>
          <div className={classes.cardHoverUnder}>
            <Button color="success" simple justIcon>
              <Edit
                className={classes.underChartIcons}
                onClick={() =>
                  history.push(
                    `/admin/a/${this.state.selectedApplication.slug}/campaign/${uuid}/details`
                  )
                }
              />
            </Button>
            <Button color="danger" simple justIcon>
              <Delete
                className={classes.underChartIcons}
                onClick={() => {
                  onDelete(uuid);
                }}
              />
            </Button>
          </div>
          <h4
            className={classes.cardProductTitle}
            style={{ textAlign: "center" }}
          >
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              {name}
            </a>
          </h4>
          <GridContainer style={gridStyle}>
            <GridItem xs={12} sm={12} md={4}>
              Date
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              {this.formatDate(start_dt)} - {this.formatDate(end_dt)}
            </GridItem>
          </GridContainer>
          <GridContainer style={gridStyle}>
            <GridItem xs={12} sm={12} md={4}>
              Schedule
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <GridContainer>{this.renderSchedule()}</GridContainer>
            </GridItem>
          </GridContainer>
          <GridContainer style={gridStyle}>
            <GridItem xs={12} sm={12} md={4}>
              Demographic
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <GridContainer>{this.renderDemographic()}</GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter product>
          <div className={classes.price}>
            <small>
              <strong>Created on {this.formatDate(created_dt)}</strong>
            </small>
          </div>
        </CardFooter>
      </Card>
    );
  }
}

export default withStyles(dashboardStyle)(CampaignCardComponent);
