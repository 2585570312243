import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Settings from "@material-ui/icons/Settings";
import EditIcon from "@material-ui/icons/Edit";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// core components
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardHeader from "components/core/Card/CardHeader.js";
import CustomInput from "components/core/CustomInput/CustomInput.js";
import CustomDropdown from "components/core/CustomDropdown/CustomDropdown.js";
import SnackbarContent from "components/core/Snackbar/SnackbarContent.js";

import history from "../../services/history";
import BooleanControl from "../settings/BooleanControl";
import IntegerControl from "../settings/IntegerControl";
import StringControl from "../settings/StringControl";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import AdMixUpdateNetworkModal from "./AdMixUpdateNetworkModal";
import DialogDeleteModal from "components/core/DialogDeleteModal/DialogDeleteModal";

const mapAdMixNetworks = (props) => {
  let ret = props.ad_mix.networks.map((adMixNetwork) => {
    return {
      ad_mix_network_uuid: adMixNetwork.uuid,
      network_name: adMixNetwork.name,
      network_uuid: adMixNetwork.uuid,
      description: adMixNetwork.description,
      priority: adMixNetwork.priority == null ? 1 : adMixNetwork.priority,
      enabled: adMixNetwork.enabled == null ? false : adMixNetwork.enabled,
    };
  });
  return ret;
};

const getAppVersion = (obj) => {
  let appVersion = "0.0.0";
  if (obj == undefined) return appVersion;
  if (obj.min != null) {
    appVersion = `${obj.min.major}.${obj.min.minor}.${obj.min.patch}`;
  }
  if (obj.max != null) {
    appVersion += ` - ${obj.max.major}.${obj.max.minor}.${obj.max.patch}`;
  }
  return appVersion;
};
const selectNetwork = "Select a Network";

class AdMixUpdateGeneralInfo extends React.Component {
  constructor(props) {
    super(props);
    let findGroup = props.groups.filter((item) => item._id == props.group)[0];
    this.state = {
      // State for ad mix
      name: this.props.ad_mix.name,
      description: this.props.ad_mix.description,
      enabled: this.props.ad_mix.enabled,
      ad_mix_networks: mapAdMixNetworks(this.props),
      groups: this.props.groups,
      appVersionGroup: getAppVersion(findGroup),

      // State for network modal
      networkModalOpen: false,
      network_name: selectNetwork,
      network_uuid: null,
      ad_mix_network_name: "",

      // State to handle confirm modal
      dialogModalOpen: false,
      dialogConfirmAction: null,

      displayStatus: false,
      displayNetworkModalStatus: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.ad_mix.networks.length !== prevState.ad_mix_networks.length) {
      return {
        ad_mix_networks: mapAdMixNetworks(nextProps),
        networkModalOpen: false,
        dialogModalOpen: false,
        dialogConfirmAction: null,
      };
    }
    if (nextProps.groups.length !== prevState.groups.length) {
      let findGroup = nextProps.groups.filter(
        (item) => item._id == nextProps.group
      )[0];
      return {
        groups: nextProps.groups,
        appVersionGroup: getAppVersion(findGroup),
      };
    }
    return prevState;
  }

  resetNetworkModal = () => {
    this.setState({
      network_name: selectNetwork,
      network_uuid: null,
      ad_mix_network_name: "",
    });
  };

  handleOpen = () => {
    this.setState(
      {
        networkModalOpen: true,
      },
      () => {
        this.resetNetworkModal();
      }
    );
  };

  handleClose = () => {
    this.setState({
      networkModalOpen: false,
      displayNetworkModalStatus: false,
    });
  };

  handleDialogClose = () => {
    this.setState({ dialogModalOpen: false, dialogConfirmAction: null });
  };

  handleDialogOpen = (dialogConfirmAction) => {
    if (!dialogConfirmAction) {
      throw new Error("Undefined dialog confirm action callback");
    }

    this.setState({
      dialogModalOpen: true,
      dialogConfirmAction,
    });
  };

  handleChangeTextInput = (field, value) => {
    let state = { ...this.state };
    state[field] = value;
    this.setState(state);
  };

  onSubmitNetwork = () => {
    if (this.state.network_uuid) {
      this.saveAdMix();
      this.props.onAddNetwork({
        description: this.state.ad_mix_network_name,
        name: this.state.network_name,
      });
      this.setState({ displayNetworkModalStatus: true });
    }
  };

  saveAdMix = () => {
    //TODO: revisit
    let state = { ...this.state };
    let groupId = state.groups.filter(
      (item) => state.appVersionGroup == getAppVersion(item)
    )[0]._id;
    let data = {
      name: state.name,
      description: state.description,
      enabled: state.enabled,
      group: groupId,
      networks: state.ad_mix_networks.map((item) => {
        return {
          uuid: item.network_uuid,
          enabled: item.enabled,
          priority: item.priority,
          description: item.description,
        };
      }),
    };

    this.props.onSave(data);
  };

  onSubmit = () => {
    this.saveAdMix();
    this.setState({ displayStatus: true });
  };

  handleSelectedUnitTypw = (value) => {
    this.setState({ unitType: value });
  };

  handleSelectedNetwork = (networkName) => {
    let filtered = {
      networkUuid: null,
      name: "Select Network",
    };

    if (networkName !== "None") {
      filtered = this.props.networks.filter((network) => {
        return network.name === networkName;
      });
      filtered = filtered[0];
    }

    this.setState({
      network_name: filtered.name,
      network_uuid: filtered.networkUuid,
    });
  };

  renderStatusMessage = () => {
    const { displayStatus } = this.state;
    const { error, ad_mix_updated } = this.props;

    if (displayStatus) {
      if (error || ad_mix_updated) {
        document
          .querySelector("#dashboardMainPanel")
          .scrollTo({ top: 0, behavior: "smooth" });
      }
      if (error) {
        return (
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={6}>
              <SnackbarContent message={error.getMessage()} color="warning" />
            </Grid>
          </Grid>
        );
      }
      if (ad_mix_updated) {
        setTimeout(() => {
          history.push(`/admin/application/placement/${this.props.placement}`);
        }, 1500);
        return (
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={6}>
              <SnackbarContent
                message={"Ad mix update was success! Redirecting..."}
                color="success"
              />
            </Grid>
          </Grid>
        );
      }
    }
  };

  onChangeAdMixNetwork = (field, index, value) => {
    let adMixNetworks = [...this.state.ad_mix_networks];
    adMixNetworks[index][field] = value;
    this.setState({
      ad_mix_networks: adMixNetworks,
    });
  };

  handleAppVersionSelection = (item) => {
    this.setState({ appVersionGroup: item });
  };

  editNetwork = (network) => {
    this.saveAdMix();
    history.push(`/admin/ad-mix-network/${network}/details`);
  };

  render() {
    const { classes, admix_network_error, networks } = this.props;
    const {
      networkModalOpen,
      displayNetworkModalStatus,
      network_name,
      ad_mix_network_name,
      dialogModalOpen,
      dialogConfirmAction,
    } = this.state;

    return (
      <React.Fragment>
        <React.Fragment>
          <DialogDeleteModal
            classes={classes}
            dialogModalOpen={dialogModalOpen}
            dialogConfirmAction={dialogConfirmAction}
            handleDialogClose={this.handleDialogClose}
          />
          <AdMixUpdateNetworkModal
            classes={classes}
            networks={networks}
            admix_network_error={admix_network_error}
            networkModalOpen={networkModalOpen}
            displayNetworkModalStatus={displayNetworkModalStatus}
            network_name={network_name}
            ad_mix_network_name={ad_mix_network_name}
            handleSelectedNetwork={this.handleSelectedNetwork}
            handleClose={this.handleClose}
            handleChangeTextInput={this.handleChangeTextInput}
            onSubmitNetwork={this.onSubmitNetwork}
          />
          {this.renderStatusMessage()}
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" icon>
                  <CardIcon color="rose">
                    <Assignment />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Ad Mix Details</h4>
                </CardHeader>
                <CardBody>
                  <Grid
                    style={{ marginLeft: 20 }}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <h3>General Info</h3>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <FormLabel>Name</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <StringControl
                        value={this.state.name}
                        onChange={(value) =>
                          this.handleChangeTextInput("name", value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <FormLabel>Description</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <StringControl
                        value={this.state.description}
                        onChange={(value) =>
                          this.handleChangeTextInput("description", value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <FormLabel>Status</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <BooleanControl
                        value={this.state.enabled}
                        onChange={(value) =>
                          this.handleChangeTextInput("enabled", value)
                        }
                      />
                      <small>
                        {this.state.enabled ? "Enabled" : "Disabled"}
                      </small>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <FormLabel>Placement name</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <FormLabel>{this.props.placement}</FormLabel>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ marginLeft: 20 }}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={12} md={3}>
                        <FormLabel>App version group</FormLabel>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8}>
                        <CustomDropdown
                          hoverColor="primary"
                          buttonText={this.state.appVersionGroup}
                          onClick={this.handleAppVersionSelection}
                          buttonProps={{
                            round: true,
                            style: { marginBottom: "0" },
                            color: "primary",
                          }}
                          dropdownList={this.props.groups.map((item) =>
                            getAppVersion(item)
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ marginLeft: 20 }}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <h3>Networks</h3>
                    </Grid>
                    {this.state.ad_mix_networks.map((adMixNEtwork, index) => {
                      return (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          key={index}
                        >
                          <Grid item xs={12} sm={12} md={2}>
                            {index === 0 ? <label>Network</label> : null}
                            <CustomInput
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                value: adMixNEtwork.network_name,
                                type: "text",
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={3}>
                            {index === 0 ? <label>Description</label> : null}
                            <StringControl
                              required={true}
                              value={adMixNEtwork.description}
                              onChange={(value) =>
                                this.onChangeAdMixNetwork(
                                  "description",
                                  index,
                                  value
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={1}>
                            {index === 0 ? <label>Priority</label> : null}
                            <IntegerControl
                              value={adMixNEtwork.priority}
                              onChange={(value) =>
                                this.onChangeAdMixNetwork(
                                  "priority",
                                  index,
                                  value
                                )
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={1}>
                            {index === 0 ? <label>Enabled</label> : null}
                            <div style={{ paddingTop: 27 }}>
                              <BooleanControl
                                value={adMixNEtwork.enabled}
                                onChange={(value) =>
                                  this.onChangeAdMixNetwork(
                                    "enabled",
                                    index,
                                    value
                                  )
                                }
                              />
                              <small>
                                {adMixNEtwork.enabled ? "Enabled" : "Disabled"}
                              </small>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={1}>
                            <Grid
                              container
                              direction="row"
                              alignItems="flex-end"
                            >
                              <Grid item xs={12} md={6}>
                                <Button
                                  onClick={() =>
                                    this.editNetwork(
                                      adMixNEtwork.ad_mix_network_uuid
                                    )
                                  }
                                  justIcon
                                  round
                                  color="primary"
                                >
                                  <EditIcon />
                                </Button>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Button
                                  onClick={() => {
                                    this.handleDialogOpen(() => {
                                      this.props.onDeleteNetwork(
                                        adMixNEtwork.network_uuid
                                      );
                                    });
                                  }}
                                  justIcon
                                  round
                                  color="danger"
                                >
                                  <Close />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Button
                      onClick={this.handleOpen}
                      color="primary"
                      className={classes.marginRight}
                    >
                      Select an Ad Network
                    </Button>
                  </Grid>
                  <Grid container direction="column" alignItems="flex-end">
                    <Grid item xs={12} sm={12} md={12}>
                      <Button
                        onClick={() =>
                          history.push(
                            `/admin/application/placement/${this.props.placement}`
                          )
                        }
                        color="rose"
                        className={classes.marginRight}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.onSubmit}
                        color="primary"
                        className={classes.marginRight}
                      >
                        Save Ad Mix
                      </Button>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default withStyles(extendedTablesStyle)(AdMixUpdateGeneralInfo);
