import React, { useMemo, useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardHeader from "components/core/Card/CardHeader.js";
import SnackbarContent from "components/core/Snackbar/SnackbarContent.js";
import { NavLink } from "react-router-dom";

import FileCopy from "@material-ui/icons/FileCopy";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import Add from "@material-ui/icons/Add";

import DialogDeleteModal from "components/core/DialogDeleteModal/DialogDeleteModal";
import AddPlacementModal from "../placements/AddPlacementModal";

// core components
import Button from "components/core/CustomButtons/Button.js";
import CustomDropdown from "components/core/CustomDropdown/CustomDropdown.js";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import CustomInput from "components/core/CustomInput/CustomInput";
import Pagination from "components/core/Pagination/Pagination";
import { chunkArray } from "helpers/utils";
import CustomAccordion from "components/core/Accordion/Accordion";
import AuditLogsTable from "components/auditlogs/AuditLogsTable";

const AdMixGroupList = ({
  classes,
  items,
  onClonePlacement,
  onDeletePlacement,
  onSubmitPlacement,
  placementError,
  refreshItems,
}) => {
  const [dialogModalOpen, setModalOpen] = useState(false);
  const [placementDialogOpen, setPlacementModalOpen] = useState(false);
  const [dialogConfirmName, setDialogConfirmName] = useState(null);
  const [editPlacement, setEditPlacement] = useState(null);
  const [displayStatus, setDisplayStatus] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [chunkSize, setChunkSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  let itemsToRender = useMemo(() => {
    return [...items].sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  }, [items]);

  if (searchValue) {
    itemsToRender = itemsToRender.filter((item) =>
      item.name.includes(searchValue)
    );
  }

  const pages = useMemo(() => chunkArray(itemsToRender, chunkSize), [
    itemsToRender,
    chunkSize,
  ]);

  if (pages.length && currentPage > pages.length) {
    setCurrentPage(pages.length);
    return null;
  }

  itemsToRender = pages.length ? pages[currentPage - 1] : [];

  const handleDialogClose = () => {
    setModalOpen(false);
    setDialogConfirmName(null);
  };

  const handlePlacementDialogClose = () => {
    setPlacementModalOpen(false);
    setEditPlacement(null);
  };

  const onDelete = (name) => {
    setModalOpen(true);
    setDialogConfirmName(name);
  };

  const onEdit = (name) => {
    setEditPlacement(name);
    setPlacementModalOpen(true);
  };

  const onClone = (id, name) => {
    const newPlacementName = findPlacementCopyName(name);
    onClonePlacement(id, newPlacementName);
    setTimeout(() => {
      setDisplayStatus(true);
      refreshItems();
      setSuccessMessage(`${name} placement cloned successfully`);
    }, 1500);
  };

  const findPlacementCopyName = (name, index = 1) => {
    if (
      itemsToRender.findIndex(
        (item) => item.name == `Copy ${index} of ${name}`
      ) == -1
    ) {
      return `Copy ${index} of ${name}`;
    }
    return findPlacementCopyName(name, index + 1);
  };

  const RenderPlacements = () => {
    return itemsToRender.map((item, index) => {
      return (
        <Grid
          container
          direction="row"
          alignItems="center"
          key={`AdMixPlacement:${index}`}
        >
          <Grid item xs={12} md={10}>
            <span>
              <NavLink
                onClick={() => {}}
                to={`/admin/application/placement/${item.name}`}
                className={classes.tdNameAnchor}
              >
                {item.name}
              </NavLink>
            </span>
          </Grid>
          <Grid item xs={12} md={2}>
            <Grid container direction="row" alignItems="flex-end">
              <Grid item xs={12} md={4}>
                <Button
                  justIcon
                  round
                  color="success"
                  onMouseDown={() => {
                    onEdit(item.name);
                  }}
                >
                  <Edit />
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  justIcon
                  round
                  color="primary"
                  onMouseDown={() => {
                    onClone(item._id, item.name);
                  }}
                >
                  <FileCopy />
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  justIcon
                  round
                  color="danger"
                  onMouseDown={() => {
                    onDelete(item.name);
                  }}
                >
                  <Close />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  const renderStatusMessage = () => {
    if (displayStatus) {
      if (placementError || successMessage) {
        document
          .querySelector("#dashboardMainPanel")
          .scrollTo({ top: 0, behavior: "smooth" });
      }
      if (placementError || successMessage) {
        setTimeout(() => {
          setDisplayStatus(false);
        }, 1500);
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={6}>
              <SnackbarContent
                message={placementError?.getMessage() || successMessage}
                color={placementError ? "danger" : "success"}
              />
            </Grid>
          </Grid>
        );
      }
    }
  };

  return (
    <GridContainer>
      {renderStatusMessage()}
      <DialogDeleteModal
        classes={classes}
        dialogModalOpen={dialogModalOpen}
        dialogConfirmAction={() => {
          setDisplayStatus(true);
          onDeletePlacement(dialogConfirmName);
          handleDialogClose();
        }}
        handleDialogClose={handleDialogClose}
      />
      <AddPlacementModal
        classes={classes}
        placement={editPlacement}
        placementModalOpen={placementDialogOpen}
        onSubmitPlacement={(prevName, newName) => {
          setDisplayStatus(true);
          onSubmitPlacement(prevName, newName);
          handlePlacementDialogClose();
        }}
        handleDialogClose={handlePlacementDialogClose}
      />
      <GridItem xs={11}>
        <Card>
          <GridContainer>
            <GridItem xs={9}>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>
                  Ad Mix Placement Groups
                </h4>
                <h6 className={classes.cardIconTitle}>
                  Admixes (waterfalls) are grouped in placements
                </h6>

                <CustomDropdown
                  hoverColor="primary"
                  buttonText={`Show ${chunkSize} entries`}
                  onClick={(selected) => {
                    setChunkSize(+selected);
                  }}
                  buttonProps={{
                    round: true,
                    style: { marginBottom: "0" },
                    color: "primary",
                  }}
                  dropdownList={[10, 25, 50]}
                />
              </CardHeader>
            </GridItem>
            <GridItem xs={3}>
              <CustomInput
                labelText="Search"
                inputProps={{ onChange: (e) => setSearchValue(e.target.value) }}
              />
            </GridItem>
          </GridContainer>
          <CardBody>
            <RenderPlacements />
            <Pagination
              pages={[
                {
                  active: false,
                  disabled: currentPage === 1,
                  text: "PREV",
                  onClick: () => {
                    if (currentPage === 1) return;
                    setCurrentPage((curr) => curr - 1);
                  },
                },
                ...pages.map((_, i) => {
                  const page = i + 1;
                  return {
                    active: page == currentPage,
                    disabled: false,
                    text: page,
                    onClick: () => {
                      setCurrentPage(page);
                    },
                  };
                }),
                {
                  active: false,
                  disabled: currentPage === pages.length,
                  text: "NEXT",
                  onClick: () => {
                    if (currentPage === pages.length) return;
                    setCurrentPage((curr) => curr + 1);
                  },
                },
              ]}
            ></Pagination>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={11}>
        <Grid container direction="column" alignItems="flex-end">
          <Grid item>
            <Button
              onClick={() => {
                onEdit(null);
              }}
              variant="contained"
              startIcon={<Add />}
              color="success"
            >
              Add Placement
            </Button>
          </Grid>
        </Grid>
      </GridItem>
      <GridItem xs={11}>
        <Card>
          <CardHeader color="rose" icon>
            <h4 className={classes.cardIconTitle}>Audit Logs</h4>
          </CardHeader>
          <CardBody>
            <CustomAccordion
              collapses={[
                {
                  title: "Show/Hide",
                  content: (
                    <AuditLogsTable
                      filters={{
                        domain: "Admixes",
                      }}
                    />
                  ),
                },
              ]}
            ></CustomAccordion>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(extendedTablesStyle)(AdMixGroupList);
