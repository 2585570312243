export function getObject(theObject, searchKey, searchValue) {
  let result = null;

  if (theObject instanceof Array) {
    //eslint-disable-next-line no-unused-vars
    for (const [index, value] of theObject.entries()) {
      result = getObject(value, searchKey, searchValue);

      if (result) {
        break;
      }
    }
  } else {
    for (const key of Object.keys(theObject)) {
      const value = theObject[key];
      if (key === searchKey) {
        if (value === searchValue) {
          return theObject;
        }
      }

      if (value instanceof Object || value instanceof Array) {
        result = getObject(value, searchKey, searchValue);

        if (result) {
          break;
        }
      }
    }
  }

  return result;
}

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: "instant",
  });
}

export function uuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

export function sumArrayOfObjectsByKey(array, key) {
  return array.map((item) => item[key]).reduce((prev, curr) => prev + curr, 0);
}

export function divideTwoNumbers(numerator, denominator) {
  //Check potential division by zero
  return denominator === 0 ? 0 : numerator / denominator;
}

export function groupByKey(list, key) {
  return list.reduce(
    (hash, obj) => ({
      ...hash,
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    }),
    {}
  );
}

export function fetchTableColumns(grouping) {
  const commonColumns = [
    {
      displayName: "Ad Request",
      key: "requests",
    },

    {
      displayName: "Impression",
      key: "impressions",
    },

    {
      displayName: "Clicks",
      key: "clicks",
    },
    {
      displayName: "CTR",
      key: "ctr",
    },
    {
      displayName: "fillRate",
      key: "fillRate",
    },
  ];

  const networkColumns = [
    {},
    {
      displayName: "Ad Networks",
      key: "networkName",
    },
    {
      displayName: "Placements",
      key: "placement",
    },
  ];

  const placementColumns = [
    {},
    {
      displayName: "Placements",
      key: "placement",
    },
  ];

  const admixColumns = [
    {},
    {
      displayName: "Admixes",
      key: "admixName",
    },
    {
      displayName: "Placements",
      key: "placement",
    },
  ];

  switch (grouping) {
    case "placement":
      return placementColumns.concat(commonColumns);
    case "admix":
      return admixColumns.concat(commonColumns);
    default:
      return networkColumns.concat(commonColumns);
  }
}

export function fetchCsvColumns(grouping) {
  const commonCsvColumns = [
    { label: "Date", key: "date" },
    { label: "Requests", key: "requests" },
    { label: "Impressions", key: "impressions" },
    { label: "Clicks", key: "clicks" },
    { label: "Ctr", key: "ctr" },
    { label: "Fill Rate", key: "fillRate" },
  ];

  const placementColumn = { label: "Placements", key: "placement" };
  const admixColumns = [
    { label: "Admixes", key: "admixName" },
    { label: "Placements", key: "placement" },
  ];
  const networkColumns = [
    { label: "Network Name", key: "networkName" },
    { label: "Placements", key: "placement" },
  ];
  switch (grouping) {
    case "placement":
      commonCsvColumns.unshift(placementColumn);
      return commonCsvColumns;
    case "admix":
      commonCsvColumns.unshift(admixColumns[0], admixColumns[1]);
      return commonCsvColumns;
    default:
      commonCsvColumns.unshift(networkColumns[0], networkColumns[1]);
      return commonCsvColumns;
  }
}

export const chunkArray = (array, chunkSize) =>
  Array(Math.ceil(array.length / chunkSize))
    .fill()
    .map((_, index) => index * chunkSize)
    .map((begin) => array.slice(begin, begin + chunkSize));
