import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AuthActions from "../../redux/modules/auth";
import Login from "../../components/auth/Login";
import { useDispatch } from "react-redux";

// Commented so if we want to add the background image later, we can just uncomment these
// import login from "assets/img/login.jpeg";

import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

const LoginContainer = ({ /* classes, */ auth, actions, ...props }) => {
  const dispatch = useDispatch();
  if (props.location.pathname === "/auth/logout") {
    dispatch(AuthActions.logout());
  }
  return (
    <div
    // className={classes.fullPage}
    // style={{ backgroundImage: "url(" + login + ")" }}
    >
      <Login error={auth.error} onLogin={actions.auth.login} {...props} />
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    auth: bindActionCreators(AuthActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LoginContainer));
