import React from "react";
import PropTypes from "prop-types";
import CustomInput from "../core/CustomInput/CustomInput.js";

class StringControl extends React.Component {
  static validate = (value) => {
    console.log("undefined validation -> value", value);
  };
  getErrorState = () => {
    if (!this.props.errorMessage) {
      return (
        this.props.required === true &&
        (this.props.value === null || this.props.value === "")
      );
    } else {
      return !!this.props.errorMessage;
    }
  };
  getErrorString = () => {
    if (!this.props.errorMessage) {
      return this.props.required === true && this.getErrorState()
        ? "Required *"
        : "";
    } else if (this.props.errorMessage) {
      return this.props.errorMessage;
    }
  };
  handleChange = (event) => {
    this.props.onChange(event.target.value);
  };
  render() {
    return (
      <CustomInput
        error={this.getErrorState()}
        labelText={this.getErrorString()}
        formControlProps={{ fullWidth: true }}
        inputProps={{
          onChange: this.handleChange,
          value: this.props.value,
          type: "text",
          multiline: this.props.multiline,
          rows: this.props.rows,
        }}
      />
    );
  }
}

StringControl.defaultProps = {
  required: false,
  value: "",
  multiline: false,
  rows: 1,
};

StringControl.propTypes = {
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default StringControl;
