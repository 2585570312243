import { takeLatest, put, call, select } from "redux-saga/effects";
import ApplicationActions, { ApplicationTypes } from "../modules/application";
import Session from "../../services/Session";

export function* getApplications(api) {
  try {
    const response = yield call(api.getApplications);
    yield put(
      ApplicationActions.getApplicationsSuccess(response.data.applications)
    );
  } catch (e) {
    yield put(ApplicationActions.getApplicationsFailure(e));
  }
}

export function* getSelectedApplication() {
  let selected = Session.get(Session.SELECTED_APPLICATION_KEY);
  if (!selected) {
    const {
      application: { items },
    } = yield select();
    selected = items[0];
  }

  yield put(ApplicationActions.getSelectedApplicationSuccess(selected));
}

export function* selectApplication(api, { uuid }) {
  try {
    let filtered = Session.get("applications").filter((app) => {
      return app.uuid === uuid;
    });

    if (filtered.length === 0) {
      throw new Error(
        "Application with uuid " + uuid + " not found in local state"
      );
    }
    Session.set(Session.SELECTED_APPLICATION_KEY, filtered[0]);
    yield put(ApplicationActions.selectApplicationSuccess(filtered[0]));
  } catch (e) {
    yield put(ApplicationActions.selectApplicationFailure(e));
  }
}

export function* updateApplication(api, { uuid, data }) {
  try {
    const response = yield call(api.updateApplication, uuid, data);
    yield put(
      ApplicationActions.updateApplicationSuccess(response.data.application)
    );

    Session.set(Session.SELECTED_APPLICATION_KEY, response.data.application);
  } catch (e) {
    yield put(ApplicationActions.updateApplicationFailure(e));
  }
}

export function* watchApplication(api) {
  yield takeLatest(ApplicationTypes.GET_APPLICATIONS, getApplications, api);
  yield takeLatest(ApplicationTypes.SELECT_APPLICATION, selectApplication, api);
  yield takeLatest(
    ApplicationTypes.GET_SELECTED_APPLICATION,
    getSelectedApplication
  );
  yield takeLatest(ApplicationTypes.UPDATE_APPLICATION, updateApplication, api);
}
