import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ApplicationActions from "../../redux/modules/application";
import SettingActions from "../../redux/modules/setting";
import ApplicationSettings from "../../components/application/ApplicationSettings";
import Session from "../../services/Session";
import validateUrl from "../ValidateApplicationUrl";

const ApplicationSettingsContainer = ({
  actions,
  setting,
  history: { goBack },
  match: {
    params: { slug },
  },
}) => {
  const application = Session.getApplication();

  useEffect(() => {
    actions.setting.fetch(application.settings_profile_uuid);

    validateUrl(slug, actions.application.selectApplication);
  }, []);

  const onSave = (settings) => {
    actions.setting.update(application.settings_profile_uuid, settings);
  };

  return (
    <ApplicationSettings
      items={setting.items}
      error={setting.error}
      onSave={onSave}
      onBack={goBack}
    />
  );
};

const mapStateToProps = ({ setting }) => ({
  setting,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    setting: bindActionCreators(SettingActions, dispatch),
    application: bindActionCreators(ApplicationActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationSettingsContainer);
