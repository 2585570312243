import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PlacementActions from "../../redux/modules/placementgroups";
import Session from "../../services/Session";
import PlacementAdMixesList from "components/placements/PlacementAdMixesList";
import AdMixActions from "../../redux/modules/admix";

const PlacementAdMixListContainer = ({
  match: {
    params: { placement },
  },
  placementgroup,
  actions,
}) => {
  const application = Session.getApplication();

  useEffect(() => {
    actions.placement.getPlacementGroup(placement);
  }, []);

  const refreshItems = () => {
    actions.placement.getPlacementGroup(placement);
  };

  return (
    <PlacementAdMixesList
      items={placementgroup.items}
      application={application}
      placementName={placement}
      onRefresh={refreshItems}
      onDeleteAdMix={(admix_uuid) => {
        actions.admix.deleteAdMix(placement, admix_uuid, refreshItems);
      }}
      onSubmitAppVersion={actions.placement.insertPlacementGroup}
      onCloneAdMix={(uuid) => {
        actions.admix.cloneAdMix(uuid, refreshItems);
      }}
      onDeleteAppVersion={(groupId) => {
        actions.placement.deletePlacementGroup(
          placement,
          groupId,
          refreshItems
        );
      }}
      onUpdateAppVersion={(groupData) =>
        actions.placement.insertPlacementGroup(
          placement,
          groupData,
          refreshItems
        )
      }
    />
  );
};

const mapStateToProps = ({ placementgroup, admix }) => ({
  placementgroup,
  admix,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    placement: bindActionCreators(PlacementActions, dispatch),
    admix: bindActionCreators(AdMixActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlacementAdMixListContainer);
