import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetch: ["setting_profile_uuid"],
  fetchSuccess: ["items"],
  fetchFailure: ["error"],

  update: ["setting_profile_uuid", "items"],
  updateSuccess: ["items"],
  updateFailure: ["error"],
});

export const SettingTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  items: [],
});

export const reducer = createReducer(INITIAL_STATE, {
  // Get application settings
  [Types.FETCH]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      items: [],
    };
  },
  [Types.FETCH_SUCCESS]: (state, { items }) => {
    return {
      ...state,
      loading: false,
      items,
    };
  },
  [Types.FETCH_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error: error,
      items: [],
    };
  },

  // Update application settings
  [Types.UPDATE]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [Types.UPDATE_SUCCESS]: (state, { items }) => {
    return {
      ...state,
      loading: false,
      items,
    };
  },
  [Types.UPDATE_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error: error,
    };
  },
});
