import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getAdMixPlacements: [],
  getAdMixPlacementsSuccess: ["items"],
  getAdMixPlacementsFailure: ["error"],

  deleteAdMixPlacement: ["placement"],
  deleteAdMixPlacementSuccess: null,
  deleteAdMixPlacementFailure: ["error"],

  changeAdMixPlacement: ["prev_placement", "new_placement"],
  changeAdMixPlacementSuccess: null,
  changeAdMixPlacementFailure: ["error"],

  cloneAdMixPlacement: ["ad_mix_placement_id", "name"],
  cloneAdMixPlacementSuccess: null,
  cloneAdMixPlacementFailure: ["error"],
});

export const AdMixPlacementTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  items: [],
  selected: null,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_AD_MIX_PLACEMENTS]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      items: [],
    };
  },
  [Types.GET_AD_MIX_PLACEMENTS_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      items: action.items,
      error: null,
    };
  },
  [Types.GET_AD_MIX_PLACEMENTS_FAILURE]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      items: [],
    };
  },
  // Delete ad mix placement
  [Types.DELETE_AD_MIX_PLACEMENT]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [Types.DELETE_AD_MIX_PLACEMENT_SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  },
  [Types.DELETE_AD_MIX_PLACEMENT_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  },
  // Change ad mix placement
  [Types.CHANGE_AD_MIX_PLACEMENT]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [Types.CHANGE_AD_MIX_PLACEMENT_SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  },
  [Types.CHANGE_AD_MIX_PLACEMENT_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  },

  // Clone ad mix placement group
  [Types.CLONE_AD_MIX_PLACEMENT]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  },
  [Types.CLONE_AD_MIX_PLACEMENT_SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  },
  [Types.CLONE_AD_MIX_PLACEMENT_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  },
});
