import React, { Component } from "react";
import BooleanControl from "./BooleanControl";
import DateControl from "./DateControl";
import DatetimeControl from "./DatetimeControl";
import IntegerControl from "./IntegerControl";
import PeriodControl from "./PeriodControl";
import JsonControl from "./JsonControl";
import StringControl from "./StringControl";
import MillisecondControl from "./MillisecondControl";
import ExtraControl from "./ExtraControl";
import EnumControl from "./EnumControl";

const controls = {
  enum: EnumControl,
  boolean: BooleanControl,
  date: DateControl,
  datetime: DatetimeControl,
  integer: IntegerControl,
  period: PeriodControl,
  json: JsonControl,
  string: StringControl,
  milliseconds: MillisecondControl,
  extra: ExtraControl,
  code_snippet: StringControl,
};

class InvalidControl extends Component {
  static validate = (value) => {
    console.log("undefined validation -> value", value);
  };
  render() {
    return <h1> Nothing here! </h1>;
  }
}

export default class ControlFactory {
  static build(type) {
    const ControlComponent = controls[type] || InvalidControl;
    if (!Object.prototype.hasOwnProperty.call(ControlComponent, "validate")) {
      ControlComponent.validate = InvalidControl.validate;
    }

    if (
      !Object.prototype.hasOwnProperty.call(ControlComponent, "defaultProps")
    ) {
      ControlComponent.defaultProps = {
        required: false,
      };
    }

    return ControlComponent;
  }
}
