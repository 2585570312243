import { takeLatest, put, call } from "redux-saga/effects";
import AdMixPlacementActions, {
  AdMixPlacementTypes,
} from "../modules/admixplacements";
import Session from "../../services/Session";

export function* getAdMixPlacements(api) {
  try {
    const response = yield call(
      api.getAdMixPlacements,
      Session.getApplication().uuid
    );
    yield put(AdMixPlacementActions.getAdMixPlacementsSuccess(response.data));
  } catch (e) {
    yield put(AdMixPlacementActions.getAdMixPlacementsFailure(e));
  }
}

export function* deleteAdMixPlacement(api, { placement }) {
  try {
    yield call(
      api.deleteAdMixPlacement,
      Session.getApplication().uuid,
      placement
    );
    yield put(AdMixPlacementActions.deleteAdMixPlacementSuccess());

    // Reset admix placements
    const response = yield call(
      api.getAdMixPlacements,
      Session.getApplication().uuid
    );
    yield put(AdMixPlacementActions.getAdMixPlacementsSuccess(response.data));
  } catch (e) {
    if (
      e.getMessage() == null &&
      e.response != null &&
      e.response.data != null
    ) {
      e.message = e.response.data.error;
    }
    yield put(AdMixPlacementActions.deleteAdMixPlacementFailure(e));
  }
}

export function* changeAdMixPlacement(api, { prev_placement, new_placement }) {
  try {
    yield call(
      api.changeAdMixPlacement,
      Session.getApplication().uuid,
      prev_placement,
      new_placement
    );
    yield put(AdMixPlacementActions.changeAdMixPlacementSuccess());

    // Reset admix placements
    const response = yield call(
      api.getAdMixPlacements,
      Session.getApplication().uuid
    );
    yield put(AdMixPlacementActions.getAdMixPlacementsSuccess(response.data));
  } catch (e) {
    if (
      e.getMessage() == null &&
      e.response != null &&
      e.response.data != null
    ) {
      e.message = e.response.data.error;
    }
    yield put(AdMixPlacementActions.changeAdMixPlacementFailure(e));
  }
}
export function* cloneAdMixPlacement(api, { ad_mix_placement_id, name }) {
  try {
    const response = yield call(
      api.cloneAdMixPlacement,
      ad_mix_placement_id,
      name
    );
    yield put(AdMixPlacementActions.cloneAdMixPlacementSuccess(response.data));
  } catch (e) {
    yield put(AdMixPlacementActions.cloneAdMixPlacementFailure(e));
  }
}

export function* watchAdMixPlacements(api) {
  yield takeLatest(
    AdMixPlacementTypes.GET_AD_MIX_PLACEMENTS,
    getAdMixPlacements,
    api
  );
  yield takeLatest(
    AdMixPlacementTypes.DELETE_AD_MIX_PLACEMENT,
    deleteAdMixPlacement,
    api
  );

  yield takeLatest(
    AdMixPlacementTypes.CHANGE_AD_MIX_PLACEMENT,
    changeAdMixPlacement,
    api
  );

  yield takeLatest(
    AdMixPlacementTypes.CLONE_AD_MIX_PLACEMENT,
    cloneAdMixPlacement,
    api
  );
}
