import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Assignment from "@material-ui/icons/Assignment";

import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";

// core components
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardHeader from "components/core/Card/CardHeader.js";

import history from "../../services/history";
import StringControl from "../settings/StringControl";
import DateControl from "../settings/DateControl";

import { Form, Field } from "react-final-form";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const CampaignCreate = ({ onCreate, application, error, classes }) => {
  const onSubmit = (values) => {
    onCreate(
      application.uuid,
      {
        ...values,
        use_default_demographic: 1,
        use_default_schedule: 1,
      },
      { url: `/admin/a/${application.slug}/campaign/:uuid/details` }
    );
  };

  const getErrorFieldMessage = (field) => {
    let errorMessage = null;
    if (error && error.hasError(field)) {
      errorMessage = error.getErrorField(field).message;
    }
    return errorMessage;
  };

  return (
    <GridContainer style={{ minHeight: 700 }}>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>New Campaign</h4>
          </CardHeader>
          <CardBody>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit }) => (
                <Grid
                  style={{ marginLeft: 20 }}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={2}>
                    <FormLabel>Name</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9}>
                    <Field name="name">
                      {({ input: Input }) => {
                        return (
                          <StringControl
                            value={Input.value}
                            onChange={Input.onChange}
                            errorMessage={getErrorFieldMessage("name")}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <FormLabel>Description</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9}>
                    <Field name="description">
                      {({ input: Input }) => {
                        return (
                          <StringControl
                            value={Input.value}
                            onChange={Input.onChange}
                            errorMessage={getErrorFieldMessage("description")}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={12} md={2}>
                      <FormLabel>Start date</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                      <Field name="start_dt">
                        {({ input: Input }) => {
                          return (
                            <DateControl
                              timeFormat={true}
                              value={Input.value}
                              onChange={Input.onChange}
                              errorMessage={getErrorFieldMessage("start_dt")}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" style={{ marginTop: 20 }}>
                    <Grid item xs={12} sm={12} md={2}>
                      <FormLabel>End date</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                      <Field name="end_dt">
                        {({ input: Input }) => {
                          return (
                            <DateControl
                              timeFormat={true}
                              value={Input.value}
                              onChange={Input.onChange}
                              errorMessage={getErrorFieldMessage("end_dt")}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    style={{ marginTop: 50 }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Button
                        onClick={() => {
                          history.push(`/admin/a/${application.slug}/campaign`);
                        }}
                        color="rose"
                        className={classes.marginRight}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleSubmit}
                        color="primary"
                        className={classes.marginRight}
                      >
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(extendedTablesStyle)(CampaignCreate);
