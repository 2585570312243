import React, { Component } from "react";
import "../../assets/css/dashboardReport.css";
import { MultilineChartOutlined } from "@material-ui/icons";

class DashboardTilesComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, count, color } = this.props;

    return (
      <div>
        <div className="featuredTitle">{title}</div>
        <div className="featuredMonyContainer">{count}</div>
        <div className="faturedRectangle">
          <span className="faturedIcon">
            <MultilineChartOutlined style={{ color: color, float: "right" }} />
          </span>
          <hr style={{ color: color, background: color }}></hr>
        </div>
      </div>
    );
  }
}

export default DashboardTilesComponent;
