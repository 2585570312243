import React, { Component } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "../../assets/css/dashboardReport.css";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { CSVLink } from "react-csv";
import Session from "../../services/Session";
import { Checkbox, TextField } from "@material-ui/core";
import api from "../../services/api";
import { fetchCsvColumns } from "helpers/utils";

class DashboardReportFiltersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      networks: [],
      startDate: null,
      endDate: null,
      filteredData: "",
      showExportOptions: false,
      application_uuid: Session.getApplication().uuid,
      range: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
        "Last Year": [
          moment().subtract(1, "year").startOf("year"),
          moment().subtract(1, "year").endOf("year"),
        ],
      },
      adNetwork: [],
    };
  }

  componentDidMount() {
    api
      .getAdNetworks(this.state.application_uuid, process.env.REACT_APP_API_KEY)
      .then((result) => {
        this.setState({
          networks: result,
        });

        const temp = [];
        for (var i = 0; i < result.length; i++) {
          temp.push({
            value: result[i].uuid,
            label: result[i].description,
          });
        }
        temp.sort(
          (a, b) =>
            isFinite(a.label[0]) - isFinite(b.label[0]) ||
            a.label.localeCompare(b.label)
        );
        this.setState({
          adNetwork: temp,
        });
      });

    const startDate = localStorage.getItem("startDate");
    const endDate = localStorage.getItem("endDate");

    this.setState({
      ...this.state,
      startDate: startDate ? moment(startDate) : moment().subtract(6, "days"),
      endDate: endDate ? moment(endDate) : moment(),
    });
  }

  handleAdNetworkChange = (event, selectedAdNetworkOption) => {
    const uuidExtracted = selectedAdNetworkOption.map(
      (network) => network.value
    );
    this.props.onSelectedNetworks(uuidExtracted);
  };

  handleExportOptions = () => {
    this.setState({ showExportOptions: !this.state.showExportOptions });
  };

  handleEvent = (event, picker) => {
    if (event.type === "apply") {
      this.props.onDateRange(
        moment(picker.startDate._d).format("YYYY-MM-DD"),
        moment(picker.endDate._d).format("YYYY-MM-DD")
      );

      this.setState({
        startDate: picker.startDate._d,
        endDate: picker.endDate._d,
      });

      localStorage.setItem("startDate", picker.startDate._d);
      localStorage.setItem("endDate", picker.endDate._d);
    }
  };

  renderDate() {
    if (
      moment(this.state.startDate).format("YYYY-MM-DD") !==
      moment(this.state.endDate).format("YYYY-MM-DD")
    )
      return (
        moment(this.state.startDate).format("ll") +
        " - " +
        moment(this.state.endDate).format("ll")
      );
    return moment(this.state.startDate).format("ll");
  }

  render() {
    const {
      showExportOptions,
      adNetwork,
      startDate,
      endDate,
      range,
    } = this.state;
    const { apiRequest, grouping } = this.props;

    const csvHeaders = fetchCsvColumns(grouping);

    return (
      <div className="filtersRow">
        <div className="dateRange">
          <span className="label">Date:</span>

          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            ranges={range}
            alwaysShowCalendars={true}
            onEvent={this.handleEvent}
          >
            <Button className="btnDateRange">
              {this.renderDate()}
              <KeyboardArrowDown className="iconDateRangeBtn" />
            </Button>
          </DateRangePicker>
        </div>

        <div className="groupSelect">
          <span className="label">Ad Network:</span>
          <Autocomplete
            multiple
            limitTags={2}
            size="small"
            options={adNetwork}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.label}
              </React.Fragment>
            )}
            onChange={this.handleAdNetworkChange}
            style={{ width: 450 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Search Ad Networks.."
              />
            )}
          />
        </div>

        <div className="exportButton">
          <Button
            onClick={this.handleExportOptions}
            disabled={apiRequest.length > 0 ? false : true}
          >
            <span className="buttonTxt">Export as </span>
            <AddIcon className="iconBtn" />
          </Button>
          {showExportOptions ? (
            <div className="exportOptions">
              {/* <p className="exportExcel">
                <span>
                  <Workbook
                    filename="requestData.xlsx"
                    element={<a>XLSX document</a>}
                  >
                    <Workbook.Sheet data={apiRequest} name="dashboard results">
                      <Workbook.Column
                        label="Network Name"
                        value="networkName"
                      />
                      <Workbook.Column label="Requests" value="requests" />
                      <Workbook.Column
                        label="Impressions"
                        value="impressions"
                      />
                      <Workbook.Column label="Clicks" value="clicks" />
                      <Workbook.Column label="Ctr" value="ctr" />
                      <Workbook.Column label="Fill Rate" value="fillRate" />
                    </Workbook.Sheet>
                  </Workbook>
                </span>
              </p> */}
              <p className="exportCsv">
                <span>
                  <CSVLink
                    data={apiRequest}
                    headers={csvHeaders}
                    filename={"requestData.csv"}
                  >
                    CSV document
                  </CSVLink>
                </span>
              </p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
export default DashboardReportFiltersComponent;
