import { takeLatest, put, call } from "redux-saga/effects";
import AuthActions, { AuthTypes } from "../modules/auth";
import ApplicationActions from "../modules/application";
import history from "../../services/history";
import Session from "../../services/Session";
import moment from "moment";

export function* login(api, action) {
  const { username, password } = action;
  try {
    const response = yield call(api.exchangeAccessToken, username, password);
    Session.set(Session.ACCESS_TOKEN_KEY, response.data.access_token);
    Session.set(Session.IDENTITY_UUID_KEY, response.data.identity);
    Session.set(Session.LAST_LOGIN_KEY, moment().toISOString());
    yield put(AuthActions.loginSuccess(response.data.access_token));

    const responseApps = yield call(api.getApplications, { with: "ad_mix" });
    yield put(
      ApplicationActions.getApplicationsSuccess(responseApps.data.applications)
    );

    Session.set(Session.APPLICATIONS, responseApps.data.applications);

    if (Session.get(Session.SELECTED_APPLICATION_KEY) === null) {
      Session.set(
        Session.SELECTED_APPLICATION_KEY,
        responseApps.data.applications[0]
      );
      yield put(
        ApplicationActions.selectApplicationSuccess(
          responseApps.data.applications[0]
        )
      );
    }

    history.push("/admin/campaign");
  } catch (e) {
    yield put(AuthActions.loginFailure(e));
  }
}

export function* logout() {
  try {
    Session.destroy();
    yield put(AuthActions.logoutSuccess());
    history.push("/auth/login");
  } catch (e) {
    yield put(AuthActions.logoutFailure(e));
  }
}

export function* checkAuth(api) {
  try {
    const accessToken = Session.get(Session.ACCESS_TOKEN_KEY);
    if (!accessToken) {
      throw new Error("Invalid access token");
    }
    yield put(AuthActions.checkAuthSuccess(accessToken));

    const applications = Session.get(Session.APPLICATIONS);

    if (applications === null) {
      const responseApps = yield call(api.getApplications, { with: "ad_mix" });
      yield put(
        ApplicationActions.getApplicationsSuccess(
          responseApps.data.applications
        )
      );

      Session.set(Session.APPLICATIONS, responseApps.data.applications);

      if (Session.get(Session.SELECTED_APPLICATION_KEY) === null) {
        Session.set(
          Session.SELECTED_APPLICATION_KEY,
          responseApps.data.applications[0]
        );
        yield put(
          ApplicationActions.selectApplicationSuccess(
            responseApps.data.applications[0]
          )
        );
      }
    } else {
      yield put(ApplicationActions.getApplicationsSuccess(applications));
    }
  } catch (e) {
    localStorage.clear();
    history.push("/auth/login");
  }
}

export function* watchAuth(api) {
  yield takeLatest(AuthTypes.LOGIN, login, api);
  yield takeLatest(AuthTypes.CHECK_AUTH, checkAuth, api);
  yield takeLatest(AuthTypes.LOGOUT, logout, api);
}
