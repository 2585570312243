import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  login: ["username", "password"],
  loginSuccess: ["auth"],
  loginFailure: ["error"],

  checkAuth: null,
  checkAuthSuccess: ["token"],
  checkAuthFailure: ["error"],

  logout: null,
  logoutSuccess: null,
  logoutFailure: ["error"],
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  auth: null,
  authSuccess: false,
  logoutSuccess: null,
  tokenValidated: null,
});

export const reducer = createReducer(INITIAL_STATE, {
  // Create an Auth
  [Types.LOGIN]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      auth: null,
      authSuccess: false,
      logoutSuccess: null,
      tokenValidated: null,
    };
  },

  [Types.LOGIN_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      auth: action.auth,
      error: null,
      authSuccess: true,
      logoutSuccess: null,
      tokenValidated: null,
    };
  },

  [Types.LOGIN_FAILURE]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      auth: null,
      authSuccess: false,
      logoutSuccess: null,
      tokenValidated: null,
    };
  },

  // Fetch a single auth
  [Types.CHECK_AUTH]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      auth: null,
      tokenValidated: null,
      logoutSuccess: null,
    };
  },

  [Types.CHECK_AUTH_SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
      auth: null,
      logoutSuccess: null,
      tokenValidated: true,
      token: action.token,
    };
  },

  [Types.CHECK_AUTH_FAILURE]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      auth: null,
      tokenValidated: false,
      logoutSuccess: null,
    };
  },

  // Delete an existing token
  [Types.LOGOUT]: (state) => {
    return {
      ...state,
      loading: true,
      logoutSuccess: null,
      tokenValidated: null,
    };
  },

  [Types.LOGOUT_SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
      logoutSuccess: true,
      tokenValidated: null,
    };
  },

  [Types.LOGOUT_FAILURE]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.error,
      logoutSuccess: null,
      tokenValidated: null,
    };
  },
});
