import axios from "../axios";
import axiosMS from "../axios_ms";

const Api = {
  refreshAccesToken(token) {
    return axios.patch(`/access-token/${token}`);
  },
  exchangeAccessToken(username, password) {
    return axios.post("/access-token", { username, password });
  },
  getApplications(query = {}) {
    return axios.get(`/application`, { params: query });
  },

  //Admixes endpoints
  getGroupAdMixes(groupUuid) {
    return axios.get(`/ad-mix-group/${groupUuid}/ad-mix`);
  },
  // Obsolete
  // updateAdMix(adMixUuid, adMixData) {
  //   return axios.patch(`/ad-mix/${adMixUuid}`, adMixData);
  // },
  updateAdMix(adMixUuid, adMixData) {
    return axiosMS.patch(`/admin/ad-mix/${adMixUuid}`, adMixData);
  },
  createAdMix(groupUuid, adMixData) {
    return axios.post(`/ad-mix-group/${groupUuid}/ad-mix`, adMixData);
  },
  //Obsolete
  // getAdMix(adMixUuid) {
  //   return axios.get(`/ad-mix/${adMixUuid}`);
  // },
  getAdMix(adMixUuid) {
    return axiosMS.get(`/admin/ad-mix/${adMixUuid}`);
  },
  //Obsolete
  // deleteAdMix(adMixUuid) {
  //   return axios.delete(`/ad-mix/${adMixUuid}`);
  // },
  deleteAdMix(applicationUuid, placement, adMixUuid) {
    return axiosMS.delete(
      `/admin/ad-mix-placements/${applicationUuid}/${placement}?admixUuid=${adMixUuid}`
    );
  },
  //Obsolete
  // cloneAdMix(groupUuid, adMixUuid) {
  //   return axios.post(`/ad-mix-group/${groupUuid}/ad-mix?source=${adMixUuid}`);
  // },
  cloneAdMix(adMixUuid) {
    return axiosMS.put(`/admin/ad-mix/${adMixUuid}`);
  },
  //Obsolete
  // getAdMixNetwork(ad_mix_network_uuid) {
  //   return axios.get(`/ad-mix-network/${ad_mix_network_uuid}`);
  // },
  getAdMixNetwork(ad_mix_network_uuid) {
    return axiosMS.get(`/admin/ad-network/${ad_mix_network_uuid}`);
  },
  //Obsolete
  // patchAdMixNetwork(ad_mix_network_uuid, data) {
  //   return axios.patch(`/ad-mix-network/${ad_mix_network_uuid}`, { data });
  // },
  patchAdMixNetwork(ad_mix_network_uuid, data) {
    return axiosMS.patch(`/admin/ad-network/${ad_mix_network_uuid}`, data);
  },
  //Obsolete
  // createAdMixNetwork(ad_mix_uuid, network_uuid, name) {
  //   return axios.post(`/ad-mix/${ad_mix_uuid}/network/${network_uuid}`, {
  //     name,
  //   });
  // },
  createAdMixNetwork(ad_mix_uuid, data) {
    return axiosMS.put(`/admin/ad-network?admix_uuid=${ad_mix_uuid}`, data);
  },
  //Obsolete
  // deleteAdMixNetwork(ad_mix_uuid, network_uuid) {
  //   return axios.delete(`/ad-mix/${ad_mix_uuid}/network/${network_uuid}`);
  // },
  deleteAdMixNetwork(network_uuid) {
    return axiosMS.delete(`/admin/ad-network?network_uuid=${network_uuid}`);
  },
  getAdMixGroups(query = {}) {
    return axios.get(`/ad-mix-group`, { params: query });
  },

  getAdMixPlacements(application_uuid) {
    return axiosMS.get(`/admin/ad-mix-placements/${application_uuid}`);
  },
  deleteAdMixPlacement(application_uuid, placement_name) {
    return axiosMS.delete(
      `/admin/ad-mix-placements/${application_uuid}/${placement_name}`
    );
  },
  changeAdMixPlacement(application_uuid, prev_placement, new_placement) {
    return axiosMS.post(`/admin/ad-mix-placements/${application_uuid}`, {
      prev: prev_placement,
      new: new_placement,
    });
  },
  cloneAdMixPlacement(ad_mix_placement_id, new_placement_name) {
    return axiosMS.put(
      `/admin/ad-mix-placements/${ad_mix_placement_id}/${new_placement_name}`
    );
  },
  getPlacementGroup(application_uuid, placement) {
    return axiosMS.get(
      `/admin/placement-groups/${application_uuid}/${placement}`
    );
  },
  //Inserts placement group that has min/max/priority properties
  insertPlacementGroup(application_uuid, placement, data) {
    return axiosMS.post(
      `/admin/placement-groups/${application_uuid}/${placement}`,
      data
    );
  },
  deletePlacementGroup(application_uuid, placement, group) {
    return axiosMS.delete(
      `/admin/placement-groups/${application_uuid}/${placement}?group=${group}`
    );
  },
  //Inserts Admix into a placement group
  insertGroupAdmix(application_uuid, placement, group, data) {
    return axiosMS.post(
      `/admin/ad-mix-group/${application_uuid}/${placement}/${group}`,
      data
    );
  },

  getAdNetworks(application_uuid, api_key) {
    return axiosMS.get(
      `/admin/ads?application_uuid=${application_uuid}&apikey=${api_key}`
    );
  },
  /** AB Tests */
  getAbTests() {
    return axios.get(`/ab-test`);
  },
  getNetworks(application_uuid) {
    return axiosMS.get(`/admin/network-settings/${application_uuid}`, {
      params: {
        getEnabledNetworks: true,
        includeNames: true,
      },
    });
  },
  //TODO: temporary solution
  getNetworkParameters(name) {
    return axiosMS.get(`admin/network/${name}`);
  },
  getSettingProfileValues(settingProfileUuid) {
    return axios.get(`/setting-profile/${settingProfileUuid}`);
  },
  updateSettingProfileValues(settingProfileUuid, settings) {
    return axios.patch(`/setting-profile/${settingProfileUuid}`, {
      settings,
    });
  },

  /** Campaigns */
  getCampaigns(applicationUuid) {
    return axios.get(`/application/${applicationUuid}/campaign`);
  },
  createCampaign(applicationUuid, data) {
    return axios.post(`/application/${applicationUuid}/campaign`, data);
  },
  updateCampaign(uuid, data) {
    return axios.patch(`/campaign/${uuid}`, data);
  },
  getCampaign(uuid) {
    return axios.get(`/campaign/${uuid}`);
  },
  deleteCampaign(uuid) {
    return axios.delete(`/campaign/${uuid}`);
  },

  getSignedUrl() {
    return axios.post(`/upload-url`);
  },
  uploadFileToS3(url, file) {
    return axios.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
      baseURL: "",
    });
  },
  /** Ads */
  getAds(campaignUuid) {
    return axios.get(`/campaign/${campaignUuid}/ad`);
  },
  createAd(campaignUuid, data) {
    return axios.post(`/campaign/${campaignUuid}/ad`, data);
  },
  updateAd(uuid, data) {
    return axios.patch(`/ad/${uuid}`, data);
  },
  getAd(uuid) {
    return axios.get(`/ad/${uuid}`);
  },
  deleteAd(uuid) {
    return axios.delete(`/ad/${uuid}`);
  },

  updateApplication(uuid, data) {
    return axios.patch(`/application/${uuid}`, data);
  },

  /** Messages */
  createMessage(settingUuid, data) {
    return axios.post(`/setting/${settingUuid}/message`, data);
  },

  getMessage(settingUuid) {
    return axios.get(`/setting/${settingUuid}/message`);
  },

  updateMessage(settingUuid, data) {
    return axios.patch(`/setting/${settingUuid}/message`, data);
  },

  deleteMessage(settingUuid) {
    return axios.delete(`/setting/${settingUuid}/message`);
  },

  /** Networks */
  getSdkNetworks() {
    return axiosMS.get(`/admin/network`);
  },
  createSdkNetworks(payload) {
    return axiosMS.post(`/admin/network`, payload);
  },
  updateSdkNetworks(uuid, payload) {
    return axiosMS.put(`/admin/network/${uuid}`, payload);
  },

  /** Network settings */
  getNetworkSettings(applicationId) {
    return axiosMS.get(`/admin/network-settings/${applicationId}`);
  },
  createNetworkSettings(applicationId, payload) {
    return axiosMS.post(`/admin/network-settings/${applicationId}`, payload);
  },
  updateNetworkSettings(applicationId, id, payload) {
    return axiosMS.patch(
      `/admin/network-settings/${applicationId}/${id}`,
      payload
    );
  },

  /** Settings (App - Global) */
  fetchSettings(uuid) {
    return axiosMS.get(`/admin/settings/?application_uuid=${uuid}`);
  },
  updateSettings(uuid, data) {
    return axiosMS.patch(`/admin/settings/?application_uuid=${uuid}`, data);
  },
};

export default Api;
