import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  fetchAppSettings: ["application_uuid"],
  fetchAppSettingsSuccess: ["data"],
  fetchAppSettingsFailure: ["error"],

  fetchGlobalSettings: ["application_uuid"],
  fetchGlobalSettingsSuccess: ["data"],
  fetchGlobalSettingsFailure: ["error"],

  updateAppSettings: ["application_uuid", "data"],
  updateAppSettingsSuccess: ["data"],
  updateAppSettingsFailure: ["error"],

  updateGlobalSettings: ["data"],
  updateGlobalSettingsSuccess: ["data"],
  updateGlobalSettingsFailure: ["error"],
});

export const SettingsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  appFetched: null,
  globalFetched: null,
  appUpdated: null,
  globalUpdated: null,
  appSettings: {},
  globalSettings: {},
});

export const reducer = createReducer(INITIAL_STATE, {
  // Get application settings
  [Types.FETCH_APP_SETTINGS]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      appSettings: {},
    };
  },
  [Types.FETCH_APP_SETTINGS_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      loading: false,
      appFetched: true,
      appSettings: data,
    };
  },
  [Types.FETCH_APP_SETTINGS_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error: error,
      appSettings: {},
    };
  },

  [Types.FETCH_GLOBAL_SETTINGS]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      globalSettings: {},
    };
  },
  [Types.FETCH_GLOBAL_SETTINGS_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      loading: false,
      globalFetched: true,
      globalSettings: data,
    };
  },
  [Types.FETCH_GLOBAL_SETTINGS_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error: error,
      globalSettings: {},
    };
  },

  // Update application settings
  [Types.UPDATE_APP_SETTINGS]: (state) => {
    return {
      ...state,
      loading: true,
      appUpdated: false,
      error: null,
    };
  },
  [Types.UPDATE_APP_SETTINGS_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      loading: false,
      appUpdated: true,
      appSettings: data.value,
    };
  },
  [Types.UPDATE_APP_SETTINGS_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error: error,
    };
  },

  // Update application settings
  [Types.UPDATE_GLOBAL_SETTINGS]: (state) => {
    return {
      ...state,
      loading: true,
      globalUpdated: false,
      error: null,
    };
  },
  [Types.UPDATE_GLOBAL_SETTINGS_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      loading: false,
      globalUpdated: true,
      globalSettings: data.value,
    };
  },
  [Types.UPDATE_GLOBAL_SETTINGS_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error: error,
    };
  },
});
