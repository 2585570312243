import { takeLatest, put, call } from "redux-saga/effects";
import GroupActions, { GroupTypes } from "../modules/admixgroup";
import Session from "../../services/Session";

export function* getGroups(api) {
  try {
    const response = yield call(api.getAdMixGroups, {
      app_uuid: Session.getApplication().uuid,
    });
    yield put(GroupActions.getGroupsSuccess(response.data.ad_mix_groups));
  } catch (e) {
    yield put(GroupActions.getGroupsFailure(e));
  }
}

export function* selectGroup({ uuid }) {
  try {
    Session.set(Session.SELECTED_GROUP_UUID, uuid);
    yield put(GroupActions.selectGroupSuccess(uuid));
  } catch (e) {
    yield put(GroupActions.selectGroupFailure(e));
  }
}

export function* watchAdMixGroups(api) {
  yield takeLatest(GroupTypes.GET_GROUPS, getGroups, api);
  yield takeLatest(GroupTypes.SELECT_GROUP, selectGroup);
}
