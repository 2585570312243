import { takeLatest, put, call } from "redux-saga/effects";
import SettingActions, { SettingTypes } from "../modules/setting";

export function* fetch(api, { setting_profile_uuid }) {
  try {
    const response = yield call(
      api.getSettingProfileValues,
      setting_profile_uuid
    );
    yield put(SettingActions.fetchSuccess(response.data.setting_profile.value));
  } catch (e) {
    yield put(SettingActions.fetchFailure(e));
  }
}

export function* update(api, { setting_profile_uuid, items }) {
  try {
    const response = yield call(
      api.updateSettingProfileValues,
      setting_profile_uuid,
      items
    );
    yield put(
      SettingActions.updateSuccess(response.data.setting_profile.value)
    );
  } catch (e) {
    yield put(SettingActions.updateFailure(e));
  }
}

export function* watchSetting(api) {
  yield takeLatest(SettingTypes.FETCH, fetch, api);
  yield takeLatest(SettingTypes.UPDATE, update, api);
}
