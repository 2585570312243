import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getAds: ["campaign_uuid"],
  getAdsSuccess: ["items"],
  getAdsFailure: ["error"],

  updateAd: ["ad_uuid", "data"],
  updateAdSuccess: ["updated"],
  updateAdFailure: ["error"],

  createAd: ["campaign_uuid", "data", "redirect"],
  createAdSuccess: ["created"],
  createAdFailure: ["error"],

  getAd: ["ad_uuid"],
  getAdSuccess: ["fetched"],
  getAdFailure: ["error"],

  deleteAd: ["ad_uuid"],
  deleteAdSuccess: ["ad_uuid"],
  deleteAdFailure: ["error"],
});

export const AdTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  items: [],
  updated: null,
  created: null,
  fetched: null,
  deleted: null,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ADS]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      items: [],
    };
  },
  [Types.GET_ADS_SUCCESS]: (state, { items }) => {
    return {
      ...state,
      loading: false,
      items,
      error: null,
    };
  },
  [Types.GET_ADS_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      items: [],
    };
  },

  // Update ad mix
  [Types.UPDATE_AD]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      updated: null,
    };
  },
  [Types.UPDATE_AD_SUCCESS]: (state, { updated }) => {
    return {
      ...state,
      loading: false,
      updated,
      error: null,
    };
  },
  [Types.UPDATE_AD_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      updated: null,
    };
  },

  // Create an ad mix
  [Types.CREATE_AD]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      created: null,
    };
  },
  [Types.CREATE_AD_SUCCESS]: (state, { created }) => {
    return {
      ...state,
      loading: false,
      created,
      error: null,
    };
  },
  [Types.CREATE_AD_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      created: null,
    };
  },

  // Fetch ad mix
  [Types.GET_AD]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      fetched: null,
    };
  },
  [Types.GET_AD_SUCCESS]: (state, { fetched }) => {
    return {
      ...state,
      loading: false,
      fetched,
      error: null,
    };
  },
  [Types.GET_AD_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      fetched: null,
    };
  },

  // Delete ad mix
  [Types.DELETE_AD]: (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      deleted: null,
    };
  },
  [Types.DELETE_AD_SUCCESS]: (state, { ad_uuid }) => {
    return {
      ...state,
      loading: false,
      deleted: ad_uuid,
      error: null,
    };
  },
  [Types.DELETE_AD_FAILURE]: (state, { error }) => {
    return {
      ...state,
      loading: false,
      error,
      deleted: null,
    };
  },
});
