import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CampaignActions from "../../redux/modules/campaign";
import ApplicationActions from "../../redux/modules/application";
import CampaignCreate from "../../components/campaign/CampaignCreate";
import Session from "../../services/Session";
import validateUrl from "../ValidateApplicationUrl";

const CampaignCreateContainer = ({
  match: {
    params: { slug },
  },
  actions,
  campaign,
}) => {
  const application = Session.getApplication();

  useEffect(() => {
    validateUrl(slug, actions.application.selectApplication);
  }, []);

  return (
    <CampaignCreate
      application={application}
      onCreate={actions.campaign.createCampaign}
      error={campaign.error}
    />
  );
};

const mapStateToProps = ({ campaign }) => ({
  campaign,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    campaign: bindActionCreators(CampaignActions, dispatch),
    application: bindActionCreators(ApplicationActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignCreateContainer);
