import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import Modal from "@material-ui/core/Modal";
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

// core components
import Button from "components/core/CustomButtons/Button.js";
import Card from "components/core/Card/Card.js";
import CardBody from "components/core/Card/CardBody.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardHeader from "components/core/Card/CardHeader.js";
import CustomDropdown from "components/core/CustomDropdown/CustomDropdown.js";
import Dropzone from "react-dropzone";
import StringControl from "../settings/StringControl";

const ALLOWED_FILES = ["png", "jpg", "jpge", "jpeg", "png"];

class AdForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewTitle: "New Ad",
      title: "",
      url: null,
      content: "",
      description: "",
      type: "Image",
      imageName: "",
      imageUrl: null,
      created: null,
      assetKey: null,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { fetched, created, updated } = this.props;

    if ((!prevProps.created && created) || (!prevProps.updated && updated)) {
      this.onClose();
    }
    if (!prevProps.fetched && fetched) {
      this.setState({
        viewTitle: "Update Ad",
        title: fetched.title,
        url: fetched.url,
        content: fetched.content,
        description: fetched.description,
        type: fetched.type,
        imageName: fetched.type === "image" ? fetched.assets[0].name : "",
        imageUrl: fetched.type === "image" ? fetched.assets[0].url : "",
        assetKey: null,
      });
    }
  };

  changeValue = (value, field) => {
    this.setState({ [field]: value });
  };

  cleanForm = (next) => {
    this.setState(
      {
        title: "",
        url: "",
        content: "",
        description: "",
        imageUrl: null,
        created: null,
      },
      next
    );
  };

  onDrop = (files) => {
    const { onUploadFile } = this.props;
    var file = files[0];
    const type = file.type.split("/");
    const reader = new FileReader();
    if (ALLOWED_FILES.indexOf(type[1]) < 0) {
      this.setState({ isInvalidFile: true });
      return;
    } else {
      reader.onload = (upload) => {
        this.setState(
          {
            imageUrl: upload.target.result,
          },
          () => {
            onUploadFile(file);
          }
        );
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.readAsDataURL(file);
    }
  };

  onSubmit = () => {
    const { assetKey, fetched, onCreateAd, onUpdateAd } = this.props;
    let ad = {
      content: this.state.content,
      description: this.state.description,
      type: this.state.type.toLowerCase(),
      title: this.state.title,
    };

    if (this.state.url) {
      ad.url = this.state.url;
    }

    if (ad.type === "image" && assetKey) {
      ad.assets = [{ key: assetKey, name: this.state.imageName }];
    }

    if (!fetched) {
      console.log("onCreateAd");
      onCreateAd(ad);
    } else {
      onUpdateAd(fetched.uuid, ad);
    }
  };

  handleSelectedAdType = (type) => {
    this.setState({
      type,
      disabledSubmit: type.toLowerCase() === "image",
    });
  };

  getErrorFieldMessage = (field) => {
    const { error } = this.props;
    let errorMessage = null;
    if (error && error.hasError(field)) {
      errorMessage = error.getErrorField(field).message;
    }
    return errorMessage;
  };

  onClose = () => {
    this.cleanForm(this.props.onClose);
  };

  render() {
    const { classes, fetched, visible, onClose } = this.props;

    const saveText = !fetched ? "SAVE NEW AD" : "UPDATE AD";
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={visible}
        onClose={onClose}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={8}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {this.state.viewTitle}
                </h4>
              </CardHeader>
              <CardBody>
                <Grid
                  style={{ marginLeft: 20 }}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={3}>
                    <FormLabel>Ad type</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <br />
                    <CustomDropdown
                      hoverColor="primary"
                      buttonText={this.state.type}
                      buttonProps={{
                        round: true,
                        style: { marginBottom: "0" },
                        color: "primary",
                        disabled: fetched !== null,
                      }}
                      dropdownList={["Image", "Html"]}
                      onClick={this.handleSelectedAdType}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <FormLabel>Title</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <StringControl
                      value={this.state.title}
                      onChange={(value) => this.changeValue(value, "title")}
                      errorMessage={this.getErrorFieldMessage("title")}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <FormLabel>Description</FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <StringControl
                      value={this.state.description}
                      onChange={(value) =>
                        this.changeValue(value, "description")
                      }
                      errorMessage={this.getErrorFieldMessage("description")}
                    />
                  </Grid>
                  {this.state.type === "Image" ||
                  this.state.type === "image" ? (
                    <React.Fragment>
                      <Grid item xs={12} sm={12} md={3}>
                        <FormLabel>Image</FormLabel>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8}>
                        <div style={{ float: "left" }}>
                          {this.getErrorFieldMessage("asset") ? (
                            <FormLabel
                              style={{ color: "red" }}
                              className={classes.labelHorizontal}
                            >
                              {this.getErrorFieldMessage("asset")}
                            </FormLabel>
                          ) : null}
                        </div>
                        <br />
                        <br />
                        <br />
                        <div className="dropzone">
                          {!this.state.imageUrl ? (
                            <Dropzone
                              style={{
                                width: "100%",
                                border: "1px solid #d5d9e0",
                              }}
                              onDrop={this.onDrop}
                              onFileDialogCancel={() =>
                                this.setState({ files: [] })
                              }
                            >
                              <div style={{ padding: 20, textAlign: "center" }}>
                                <small>Drag file to here</small>
                                <br />
                                <small>or</small>
                                <br />
                                <Button
                                  color="primary"
                                  round
                                  className={classes.marginRight}
                                >
                                  Upload
                                </Button>
                              </div>
                            </Dropzone>
                          ) : (
                            <React.Fragment>
                              <img width={200} src={this.state.imageUrl} />
                              <Button
                                onClick={() => {
                                  this.setState({ imageUrl: null });
                                }}
                                justIcon
                                round
                                color="danger"
                                className={classes.marginRight}
                              >
                                <Close />
                              </Button>
                            </React.Fragment>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <FormLabel>Click through URL</FormLabel>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8}>
                        <StringControl
                          value={this.state.url}
                          onChange={(value) => this.changeValue(value, "url")}
                          errorMessage={this.getErrorFieldMessage("url")}
                          required={true}
                        />
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid item xs={12} sm={12} md={3}>
                        <FormLabel>Creative content</FormLabel>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8}>
                        <StringControl
                          value={this.state.content}
                          onChange={(value) =>
                            this.changeValue(value, "content")
                          }
                          errorMessage={this.getErrorFieldMessage("content")}
                          multiline={true}
                          rows={8}
                          required={true}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                  <Grid container direction="column" alignItems="flex-end">
                    <Grid item xs={12} sm={12} md={12}>
                      <Button
                        onClick={this.onClose}
                        color="rose"
                        className={classes.marginRight}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={this.onSubmit}
                        color="primary"
                        className={classes.marginRight}
                      >
                        {saveText}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

export default withStyles(extendedTablesStyle)(AdForm);
