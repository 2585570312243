import React, { Component } from "react";
import "../../assets/css/dashboardReport.css";
import Tiles from "./DashboardTilesComponent";
import { divideTwoNumbers, sumArrayOfObjectsByKey } from "../../helpers/utils";

class DashboardFaturedInfoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartRequest: this.props.chartRequestDisplay,
      chartImpression: this.props.chartImpressionDisplay,
      chartClick: this.props.chartClickDisplay,
      chartCtr: this.props.chartCtrDisplay,
      chartFillRate: this.props.chartFillRateDisplay,
      displayInfo: this.props.displayInfo,
    };
  }

  handleChartRequests = () => {
    this.setState({ chartRequest: !this.state.chartRequest });
    this.props.onClickedTileRequest(!this.state.chartRequest);

    if (
      !this.state.chartImpression &&
      !this.state.chartClick &&
      !this.state.chartCtr &&
      !this.state.chartFillRate
    ) {
      this.setState({ displayInfo: !this.state.displayInfo });
      this.props.onSelectedMetric(!this.state.displayInfo);
    }
  };

  handleChartImpressions = () => {
    this.setState({ chartImpression: !this.state.chartImpression });
    this.props.onClickedTileImpression(!this.state.chartImpression);

    if (
      !this.state.chartRequest &&
      !this.state.chartClick &&
      !this.state.chartCtr &&
      !this.state.chartFillRate
    ) {
      this.setState({ displayInfo: !this.state.displayInfo });
      this.props.onSelectedMetric(!this.state.displayInfo);
    }
  };

  handleChartClick = () => {
    this.setState({ chartClick: !this.state.chartClick });
    this.props.onClickedTileClick(!this.state.chartClick);

    if (
      !this.state.chartRequest &&
      !this.state.chartImpression &&
      !this.state.chartCtr &&
      !this.state.chartFillRate
    ) {
      this.setState({ displayInfo: !this.state.displayInfo });
      this.props.onSelectedMetric(!this.state.displayInfo);
    }
  };

  handleChartCtr = () => {
    this.setState({ chartCtr: !this.state.chartCtr });
    this.props.onClickedTileCtr(!this.state.chartCtr);

    if (
      !this.state.chartRequest &&
      !this.state.chartImpression &&
      !this.state.chartClick &&
      !this.state.chartFillRate
    ) {
      this.setState({ displayInfo: !this.state.displayInfo });
      this.props.onSelectedMetric(!this.state.displayInfo);
    }
  };

  handleChartFillRate = () => {
    this.setState({ chartFillRate: !this.state.chartFillRate });
    this.props.onClickedTileFillRate(!this.state.chartFillRate);

    if (
      !this.state.chartRequest &&
      !this.state.chartImpression &&
      !this.state.chartClick &&
      !this.state.chartCtr
    ) {
      this.setState({ displayInfo: !this.state.displayInfo });
      this.props.onSelectedMetric(!this.state.displayInfo);
    }
  };

  render() {
    const { apiRequest } = this.props;

    let totalRequests = sumArrayOfObjectsByKey(apiRequest, "requests");
    let totalImpressions = sumArrayOfObjectsByKey(apiRequest, "impressions");
    let totalClicks = sumArrayOfObjectsByKey(apiRequest, "clicks");
    let totalCtr = divideTwoNumbers(totalClicks, totalImpressions) * 100;
    let totalFillRate = divideTwoNumbers(totalImpressions, totalRequests) * 100;

    return (
      <div>
        {apiRequest.length > 0 ? (
          <div className="featuredContainer">
            <button
              onClick={this.handleChartRequests}
              className={
                !this.state.chartRequest
                  ? "featuredInfo"
                  : "featuredRequestInfo"
              }
            >
              <Tiles
                title="Ad Request"
                count={totalRequests !== 0 ? totalRequests : "N/A"}
                color="#AB54DB"
              />
            </button>

            <button
              onClick={this.handleChartImpressions}
              className={
                !this.state.chartImpression
                  ? "featuredInfo"
                  : "featuredImpressionInfo"
              }
            >
              <Tiles
                title="Impression"
                count={totalImpressions !== 0 ? totalImpressions : 0}
                color="#27AE60"
              />
            </button>

            <button
              onClick={this.handleChartClick}
              className={
                !this.state.chartClick ? "featuredInfo" : "featuredClickInfo"
              }
            >
              <Tiles
                title="Clicks"
                count={totalClicks !== 0 ? totalClicks : 0}
                color="#F2994A"
              />
            </button>

            <button
              onClick={this.handleChartCtr}
              className={
                !this.state.chartCtr ? "featuredInfo" : "featuredCtrInfo"
              }
            >
              <Tiles
                title="CTR"
                count={totalCtr !== 0 ? totalCtr.toFixed(2) + "%" : 0 + "%"}
                color="#2F80ED"
              />
            </button>

            <button
              onClick={this.handleChartFillRate}
              className={
                !this.state.chartFillRate
                  ? "featuredInfo"
                  : "featuredFillRateInfo"
              }
            >
              <Tiles
                title="Fill Rate "
                count={
                  totalFillRate !== 0 ? totalFillRate.toFixed(2) + "%" : "N/A"
                }
                color="#EB5757"
              />
            </button>
          </div>
        ) : (
          <div className="noData">
            <span>No Data Available to Display</span>
          </div>
        )}
      </div>
    );
  }
}

export default DashboardFaturedInfoComponent;
