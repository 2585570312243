import React, { Component, Fragment } from "react";
import DashboardReportFiltersComponent from "../../components/dashboard/DashboardReportFiltersComponent";
import DashboardFeaturedInfoComponent from "../../components/dashboard/DashboardFeaturedInfoComponent";
import DashboardReportChartComponent from "../../components/dashboard/DashboardReportChartComponent";
import DashboardTableListComponent from "../../components/dashboard/DashboardTableListComponent";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Session from "../../services/Session";
import DashboardNavigationComponent from "components/dashboard/DashboardNavigationComponent";

class DashboardReportsListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: moment().subtract(6, "days").format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      adRequestData: [],
      chartData: [],
      isPlot: false,
      tableLoading: false,
      chartRequestDisplay: true,
      chartImpressionDisplay: false,
      chartClickDisplay: false,
      chartCtrDisplay: false,
      chartFillRateDisplay: false,
      infoDisplayed: true,
      networks: [],
      applicationUuid: Session.getApplication().uuid,
      networkUuid: [],
      grouping: "placement",
    };
  }

  componentDidMount() {
    const localStorageStartDate = localStorage.getItem("startDate");
    const localStorageEndDate = localStorage.getItem("endDate");

    const startDate = localStorageStartDate
      ? moment(localStorageStartDate).format("YYYY-MM-DD")
      : moment().subtract(6, "days").format("YYYY-MM-DD");
    const endDate = localStorageEndDate
      ? moment(localStorageEndDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");

    this.setState({
      ...this.state,
      fromDate: startDate,
      toDate: endDate,
    });

    const requestOptions = {
      startDt: startDate,
      endDt: endDate,
      applicationUuid: this.state.applicationUuid,
      networkUuid: this.state.networkUuid,
      groupBy: this.state.grouping,
    };

    this.fetchAdRequest(requestOptions);
    this.fetchGraphData({
      ...requestOptions,
      includeDate: true,
    });
  }

  componentDidUpdate(_, prevState) {
    if (prevState.grouping !== this.state.grouping) {
      const requestOptions = {
        startDt: this.state.fromDate,
        endDt: this.state.toDate,
        applicationUuid: this.state.applicationUuid,
        networkUuid: this.state.networkUuid,
        groupBy: this.state.grouping,
      };
      this.fetchAdRequest(requestOptions);
      this.fetchGraphData({
        ...requestOptions,
        includeDate: true,
      });
      this.setState({ isPlot: false });
    }
  }

  fetchAdRequest = async (requestOptions) => {
    this.setState({ tableLoading: true });
    await axios
      .post(
        process.env.REACT_APP_ANALYTICS_URL + "api/pull-ad-data",
        requestOptions
      )
      .then((response) => {
        this.setState({ adRequestData: response.data });
      })
      .catch((error) => {
        // TODO manage errors
        //this.setState({ errorMessage: error.toString() });
        console.error("There was an error!", error);
      });
    this.setState({ tableLoading: false });
  };

  fetchGraphData = (requestOptions) => {
    axios
      .post(
        process.env.REACT_APP_ANALYTICS_URL + "api/pull-ad-data",
        requestOptions
      )
      .then((response) => this.setState({ chartData: response.data }))
      .catch((error) => {
        // TODO manage errors
        //this.setState({ errorMessage: error.toString() });
        console.error("There was an error!", error);
      });
  };

  handleFromDate = (dateValue) => {
    this.setState({ fromDate: dateValue });
  };

  handleToDate = (dateValue) => {
    this.setState({ toDate: dateValue });
  };

  handleDateRange = (dateValueFrom, dateValueTo) => {
    this.setState({ fromDate: dateValueFrom });
    this.setState({ toDate: dateValueTo });

    const requestOptions = {
      startDt: dateValueFrom,
      endDt: dateValueTo,
      applicationUuid: this.state.applicationUuid,
      networkUuid: this.state.networkUuid,
      groupBy: this.state.grouping,
    };

    this.fetchAdRequest(requestOptions);
    this.fetchGraphData({
      ...requestOptions,
      includeDate: true,
    });
  };

  handleDisplayChartRequest = (request) => {
    this.setState({ chartRequestDisplay: request });
  };

  handleDisplayChartImpression = (impression) => {
    this.setState({ chartImpressionDisplay: impression });
  };

  handleDisplayChartClick = (click) => {
    this.setState({ chartClickDisplay: click });
  };

  handleDisplayChartCtr = (ctr) => {
    this.setState({ chartCtrDisplay: ctr });
  };

  handleDisplayChartFillRate = (fillRate) => {
    this.setState({ chartFillRateDisplay: fillRate });
  };

  handleDataGrouping = (val) => {
    this.setState({ grouping: val });
  };

  handleInfo = () => {
    if (this.state.networks.length === 0) {
      this.setState({ infoDisplayed: !this.state.infoDisplayed });

      // toast.info(
      //   "No metrics are selected. Please select a metric to plot the graph."
      // );
    }
  };

  handlePlotRows = (selectedItems) => {
    const requestOptions = {
      startDt: this.state.fromDate,
      endDt: this.state.toDate,
      applicationUuid: this.state.applicationUuid,
      filterIds: selectedItems,
      groupBy: this.state.grouping,
      includeDate: true,
    };
    this.fetchGraphData(requestOptions);
    this.setState({ isPlot: true });
  };

  handleNetworks = (selectedNetworks) => {
    if (selectedNetworks.length > 0) {
      this.setState({ infoDisplayed: false });
    } else {
      this.setState({ infoDisplayed: true });
      toast.info(
        "No metrics are selected. Please select a metric to plot the graph."
      );
    }

    this.setState({ networks: selectedNetworks });

    this.setState({
      networkUuid: selectedNetworks,
    });

    const requestOptions = {
      startDt: this.state.fromDate,
      endDt: this.state.toDate,
      applicationUuid: this.state.applicationUuid,
      networkUuid: selectedNetworks,
      groupBy: this.state.grouping,
    };

    this.fetchAdRequest(requestOptions);
    this.fetchGraphData({
      ...requestOptions,
      includeDate: true,
    });
  };

  render() {
    const {
      fromDate,
      toDate,
      adRequestData,
      tableLoading,
      chartData,
      chartRequestDisplay,
      chartImpressionDisplay,
      chartClickDisplay,
      chartCtrDisplay,
      chartFillRateDisplay,
      infoDisplayed,
      grouping,
      isPlot,
    } = this.state;

    return (
      <Fragment>
        {infoDisplayed ? <ToastContainer draggable={false} limit={1} /> : null}

        <DashboardNavigationComponent
          grouping={this.state.grouping}
          changeGrouping={this.handleDataGrouping}
        />
        <DashboardReportFiltersComponent
          onDateRange={this.handleDateRange}
          onSelectedNetworks={this.handleNetworks}
          apiRequest={chartData}
          infoDisplayed={infoDisplayed}
          grouping={this.state.grouping}
        />

        <DashboardFeaturedInfoComponent
          apiRequest={chartData}
          onClickedTileRequest={this.handleDisplayChartRequest}
          onClickedTileImpression={this.handleDisplayChartImpression}
          onClickedTileClick={this.handleDisplayChartClick}
          onClickedTileCtr={this.handleDisplayChartCtr}
          onClickedTileFillRate={this.handleDisplayChartFillRate}
          chartRequestDisplay={chartRequestDisplay}
          chartImpressionDisplay={chartImpressionDisplay}
          chartClickDisplay={chartClickDisplay}
          chartCtrDisplay={chartCtrDisplay}
          chartFillRateDisplay={chartFillRateDisplay}
          onSelectedMetric={this.handleInfo}
          infoDisplayed={infoDisplayed}
        />
        {(chartRequestDisplay ||
          chartImpressionDisplay ||
          chartClickDisplay ||
          chartCtrDisplay ||
          chartFillRateDisplay) &&
        chartData.length > 0 ? (
          <DashboardReportChartComponent
            dateFromParent={fromDate}
            isPlot={isPlot}
            dateToParent={toDate}
            apiRequest={chartData}
            chartRequestDisplay={chartRequestDisplay}
            chartImpressionDisplay={chartImpressionDisplay}
            chartClickDisplay={chartClickDisplay}
            chartCtrDisplay={chartCtrDisplay}
            chartFillRateDisplay={chartFillRateDisplay}
            grouping={this.state.grouping}
          />
        ) : null}

        {adRequestData.length > 0 ? (
          <DashboardTableListComponent
            dateFromParent={fromDate}
            dateToParent={toDate}
            apiRequest={adRequestData}
            grouping={grouping}
            loading={tableLoading}
            onPlotRows={this.handlePlotRows}
          />
        ) : null}
      </Fragment>
    );
  }
}

export default DashboardReportsListContainer;
