const Session = {
  ACCESS_TOKEN_KEY: "access_token",
  LAST_LOGIN_KEY: "last_login",
  IDENTITY_UUID_KEY: "identity_uuid",
  SELECTED_APPLICATION_KEY: "selected_application",
  AD_MIXES_PER_APPLICATION: "ad_mixes_per_application",
  APPLICATIONS: "applications",
  SELECTED_GROUP_UUID: "selected_group_uuid",
  SELECTED_PLACEMENT_NAME: "selected_placement_name",
  set: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  get: (key) => {
    return JSON.parse(localStorage.getItem(key));
  },
  destroy: () => {
    localStorage.clear();
  },
  getApplication: () => {
    return Session.get(Session.SELECTED_APPLICATION_KEY);
  },
  admix: {
    add: (applicationUuid, adMixUuid) => {
      const applications = Session.get(Session.APPLICATIONS).map(
        (application) => {
          if (application.uuid === applicationUuid) {
            application.ad_mix_uuids.push(adMixUuid);
          }
          return application;
        }
      );
      Session.set(Session.APPLICATIONS, applications);
    },
    delete: (adMixUuid) => {
      const applications = Session.get(Session.APPLICATIONS).map(
        (application) => {
          const index = application.ad_mix_uuids.indexOf(adMixUuid);
          if (index > -1) {
            application.ad_mix_uuids.splice(index, 1);
          }
          return application;
        }
      );

      Session.set(Session.APPLICATIONS, applications);
    },
    clone: (applicationUuid, adMixUuid) => {
      Session.admix.add(applicationUuid, adMixUuid);
    },
  },
};

export default Session;
