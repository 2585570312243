import React from "react";
import Datetime from "react-datetime";

export default class DatetimeControl extends React.Component {
  state = {
    value: this.props.value
      ? new Date(this.props.value)
      : this.props.placeholder
      ? this.props.placeholder
      : new Date(),
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value == null) return prevState;
    if (nextProps.value != prevState.value) {
      return {
        value: nextProps.value,
      };
    }
    return prevState;
  }

  handleDateChange = (value) => {
    const valueDate = new Date(value);
    this.setState({ value: valueDate }, () => {
      this.props.onChange(valueDate);
    });
  };
  render() {
    return (
      <Datetime
        onChange={this.handleDateChange}
        inputProps={{
          placeholder: this.props.placeholder
            ? this.props.placeholder
            : new Date(),
        }}
        value={
          this.state.value != null
            ? this.getDate(this.state.value)
            : this.state.value
        }
      />
    );
  }

  getDate = (value) => {
    let date = new Date(value);
    if (date == "Invalid Date") {
      return this.props.placeholder;
    }
    return date;
  };
}
