import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";

// core components
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import Card from "components/core/Card/Card.js";
import CardHeader from "components/core/Card/CardHeader.js";
import CardIcon from "components/core/Card/CardIcon.js";
import CardBody from "components/core/Card/CardBody.js";
import Button from "components/core/CustomButtons/Button.js";

import ControlFactory from "../settings/ControlFactory";
import StatusMessage from "../common/StatusMessage";

// style for this view
import validationFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const getMappedSettings = (props) => {
  return props.items.length > 0
    ? props.items.map((item) => {
        if (item.type === "json") {
          item.value = JSON.parse(item.value);
        }
        return item;
      })
    : [];
};
class ApplicationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items,
      displayStatus: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.items.length !== prevState.items.length) {
      return {
        items: getMappedSettings(nextProps),
      };
    }
    return prevState;
  }

  onSubmit = () => {
    this.props.onSave(
      this.state.items.map((setting) => {
        let item = { ...setting };
        if (item.type === "json" && typeof item.value === "object") {
          item.value = JSON.stringify(item.value);
        } else if (item.type === "period") {
          item.value = "P";
          item.value += setting.value.years + "Y";
          item.value += setting.value.months + "M";
          item.value += setting.value.days + "D";
          item.value += "T";
          item.value += setting.value.hours + "H";
          item.value += setting.value.minutes + "M";
          item.value += setting.value.seconds + "S";
        } else if (item.type === "boolean") {
          item.value = item.value ? 1 : 0;
        }
        return item;
      })
    );
    this.setState({ displayStatus: true });
  };

  onChangeSetting = (itemName, value) => {
    const items = [...this.state.items];
    let index = items.findIndex((item) => {
      return item.name === itemName;
    });
    items[index].value = value;
    this.setState({ items });
  };

  renderStatusMessage = () => {
    if (this.state.displayStatus) {
      let color = "success";
      let message = "Application settings were updated properly";
      if (this.props.error) {
        message = this.props.error.getErrorField("settings").message;
        color = "warning";
      }
      return <StatusMessage message={message} color={color} />;
    }
  };

  render() {
    const { classes } = this.props;
    const { items } = this.state;
    return (
      <GridContainer style={{ paddingBottom: 200 }}>
        {this.renderStatusMessage()}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <CardIcon>
                <div style={{ color: "white" }}>Application Settings</div>
              </CardIcon>
            </CardHeader>
            <CardBody>
              {items.map((item) => {
                const ControlComponent = ControlFactory.build(item.type);
                if (item.type === "json" && typeof item.value === "string") {
                  item.value = JSON.parse(item.value);
                }
                return (
                  <Grid
                    style={{ marginLeft: 20 }}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    key={item.name}
                  >
                    <Grid item xs={12} sm={12} md={2}>
                      <FormLabel>{item.name}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                      <ControlComponent
                        onChange={(value) => {
                          this.onChangeSetting(item.name, value);
                        }}
                        value={item.value ? item.value : ""}
                        uuid={item.uuid}
                      />
                    </Grid>
                  </Grid>
                );
              })}
              <Grid container direction="column" alignItems="flex-end">
                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    onClick={this.props.onBack}
                    color="rose"
                    className={classes.marginRight}
                  >
                    Cancel
                  </Button>
                  <Button color="primary" onClick={this.onSubmit}>
                    Save changes
                  </Button>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(validationFormsStyle)(ApplicationSettings);
