import React, { Component } from "react";
import "../../assets/css/dashboardReport.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import {
  divideTwoNumbers,
  groupByKey,
  sumArrayOfObjectsByKey,
} from "../../helpers/utils";

class DashboardReportChartComponent extends Component {
  constructor(props) {
    super(props);
  }

  CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0) {
      let labelFormatter = moment(label).format("lll");
      if (this.props.isPlot) {
        const dateData = payload[0].payload.value;
        const grouping = this.props.grouping;
        const idField =
          grouping === "network"
            ? "networkName"
            : grouping === "admix"
            ? "admixName"
            : "placement";
        return (
          <div className="custom-tooltip">
            <p className="label">{labelFormatter}</p>
            {dateData.map((data, id) => {
              return (
                <>
                  <span key={id} className="ml-3 font-weight-bold">
                    {"- " + data[idField]}
                  </span>
                  {payload.map((line, id) => (
                    <ul key={id}>
                      {line.name === "requests" ? (
                        <li style={{ color: line.color }}>
                          <span className="custom-tooltip-value">
                            {"Requests: "}
                            {data.requests !== 0 ? data.requests : "N/A"}
                          </span>
                        </li>
                      ) : null}
                      {line.name === "impressions" ? (
                        <li style={{ color: line.color }}>
                          <span className="custom-tooltip-value">
                            {"Impressions: "}
                            {data.impressions}
                          </span>
                        </li>
                      ) : null}
                      {line.name === "clicks" ? (
                        <li style={{ color: line.color }}>
                          <span className="custom-tooltip-value">
                            {"Clicks: "}
                            {data.clicks}
                          </span>
                        </li>
                      ) : null}
                      {line.name === "ctr" ? (
                        <li style={{ color: line.color }}>
                          <span className="custom-tooltip-value">
                            {"Ctr: "}
                            {data.ctr.toFixed(2)}
                            {"%"}
                          </span>
                        </li>
                      ) : null}
                      {line.name === "fillRate" ? (
                        <li style={{ color: line.color }}>
                          <span className="custom-tooltip-value">
                            {"Fill Rate: "}
                            {data.fillRate !== 0
                              ? data.fillRate.toFixed(2) + "%"
                              : "N/A"}
                          </span>
                        </li>
                      ) : null}
                    </ul>
                  ))}
                </>
              );
            })}
          </div>
        );
      }
      return (
        <div className="custom-tooltip">
          <p className="label">{labelFormatter}</p>

          {payload.map((data, idx) => (
            <ul key={idx}>
              {data.name === "requests" ? (
                <li style={{ color: data.color }}>
                  <span className="custom-tooltip-value">
                    {"Requests: "}
                    {data.payload.requests !== 0
                      ? data.payload.requests
                      : "N/A"}
                  </span>
                </li>
              ) : null}
              {data.name === "impressions" ? (
                <li style={{ color: data.color }}>
                  <span className="custom-tooltip-value">
                    {"Impressions: "}
                    {data.payload.impressions}
                  </span>
                </li>
              ) : null}
              {data.name === "clicks" ? (
                <li style={{ color: data.color }}>
                  <span className="custom-tooltip-value">
                    {"Clicks: "}
                    {data.payload.clicks}
                  </span>
                </li>
              ) : null}
              {data.name === "ctr" ? (
                <li style={{ color: data.color }}>
                  <span className="custom-tooltip-value">
                    {"Ctr: "}
                    {data.payload.ctr.toFixed(2)}
                    {"%"}
                  </span>
                </li>
              ) : null}
              {data.name === "fillRate" ? (
                <li style={{ color: data.color }}>
                  <span className="custom-tooltip-value">
                    {"Fill Rate: "}
                    {data.payload.fillRate !== 0
                      ? data.payload.fillRate.toFixed(2) + "%"
                      : "N/A"}
                  </span>
                </li>
              ) : null}
            </ul>
          ))}
        </div>
      );
    }

    return null;
  };

  handleDataManipulationForChart(chartData) {
    const groupedData = groupByKey(chartData, "date");
    const finalChartData = [];
    //Key is date and value is the array of all objects inside that network
    for (const [key, value] of Object.entries(groupedData)) {
      if (value.length > 0) {
        let totalRequests = sumArrayOfObjectsByKey(value, "requests");
        let totalImpressions = sumArrayOfObjectsByKey(value, "impressions");
        let totalClicks = sumArrayOfObjectsByKey(value, "clicks");
        finalChartData.push({
          value: this.props.isPlot ? value : [],
          networkName: value[0]["networkName"],
          networkUuid: value[0]["networkUuid"],
          date: key,
          requests: totalRequests,
          impressions: totalImpressions,
          clicks: totalClicks,
          ctr: divideTwoNumbers(totalClicks, totalImpressions) * 100,
          fillRate: divideTwoNumbers(totalImpressions, totalRequests) * 100,
        });
      }
    }

    //Sort the array by date
    return finalChartData.sort(function (x, y) {
      const firstDate = new Date(x.date),
        SecondDate = new Date(y.date);

      if (firstDate < SecondDate) return -1;
      if (firstDate > SecondDate) return 1;
      return 0;
    });
  }

  render() {
    const {
      chartRequestDisplay,
      chartImpressionDisplay,
      chartClickDisplay,
      chartCtrDisplay,
      chartFillRateDisplay,
      apiRequest,
    } = this.props;

    const percent = (number) => {
      return number.toFixed(2) + "%";
    };

    const chartData = this.handleDataManipulationForChart(apiRequest);

    return (
      <div className="chartContainer">
        <div className="chart">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={chartData}
              margin={{
                top: 10,
                right: 30,
                left: 20,
                bottom: 20,
              }}
            >
              <XAxis
                dataKey="date"
                tickFormatter={(dateStr) => moment(dateStr).format("ll")}
              />
              <YAxis yAxisId="left" />
              <YAxis
                yAxisId="right"
                orientation="right"
                tickFormatter={percent}
              />

              <Tooltip
                labelFormatter={(label) => moment(label).format("lll")}
                content={this.CustomTooltip}
              />
              <Legend verticalAlign="top" iconType="square" />

              {chartRequestDisplay ? (
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="requests"
                  stroke="#AB54DB"
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                />
              ) : null}

              {chartImpressionDisplay ? (
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="impressions"
                  stroke="#27AE60"
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                />
              ) : null}
              {chartClickDisplay ? (
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="clicks"
                  stroke="#F2994A"
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                />
              ) : null}
              {chartCtrDisplay ? (
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="ctr"
                  stroke="#2F80ED"
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                />
              ) : null}
              {chartFillRateDisplay ? (
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="fillRate"
                  stroke="#EB5757"
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                />
              ) : null}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default DashboardReportChartComponent;
